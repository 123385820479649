import { Box, Button, Container, Grid, Stack, Typography } from "@mui/material";
import { useAuth } from 'qreal-auth-provider/src';
import { useEffect, useMemo, useState } from 'react';
import EyeIconBlack from '../../assets/icons/Eye-Icon-Black.svg';
import EyeIconWhite from '../../assets/icons/Eye-Icon-White.svg';
import TrashIconBlack from '../../assets/icons/Trash-Icon-Black.svg';
import TrashIconWhite from '../../assets/icons/Trash-Icon-White.svg';
import QReviewActionButton from '../../components/QReviewButton/QReviewActionButton';
import CreateBrandModal from '../../components/modals/CreateBrandModal';
import DeleteConfirmationModal, { statusList } from '../../components/modals/DeleteConfirmationModal';
import EditBrandModal from '../../components/modals/EditBrandModal';
import StatusBadge from '../../components/shared/table-items/StatusBadge';
import QReviewDefaultTable from '../../components/tables/QReviewDefaultTable';
import { authKeys, checkAuth } from '../../enums/authKeys';
import { deleteAxiosWithToken, getAxiosWithToken } from '../../functions/axiosHelper';
import { useThemeContext } from '../../theme/ThemeContextProvider';


interface TableData {
    data: any
    totalRow: number
    page: number
    perPage: number
}

const BrandList = () => {
    const { mode } = useThemeContext();
    const { authList } = useAuth();
    const [loading, setLoading] = useState(true);
    const [selectedBrand, setSelectedBrand] = useState<any>(null);
    const [brandModal, setBrandModal] = useState(false);
    const [brandEditModal, setBrandEditModal] = useState(false);
    const [brandDeleteModal, setBrandDeleteModal] = useState(false);
    const [deleteStatus, setDeleteStatus] = useState(statusList.onProgress);
    const [tableData, setTableData] = useState<TableData>({
        data: [],
        totalRow: 0,
        page: 1,
        perPage: 10
    });

    const ActionButtons = ({ row }) => (
        <>
            <Stack direction="row" spacing={2}>
                {checkAuth(authList, authKeys.delete_brand) &&
                    <Box onClick={() => {
                        setSelectedBrand({
                            id: row._id,
                            name: row.brand_name
                        });
                        setBrandDeleteModal(true)
                    }}>
                        <QReviewActionButton icon={mode === "light" ? TrashIconBlack : TrashIconWhite} tooltip='Delete Brand' />
                    </Box>
                }

                {checkAuth(authList, authKeys.edit_brand) &&
                    <Box onClick={() => {
                        setSelectedBrand({
                            id: row._id,
                            name: row.brand_name
                        });
                        setBrandEditModal(true)
                    }}>
                        <QReviewActionButton icon={mode === "light" ? EyeIconBlack : EyeIconWhite} tooltip='Edit Brand' />
                    </Box>
                }
            </Stack>

        </>
    );

    const columns = [
        {
            name: 'Brand Name',
            sortable: true,
            grow: 2,
            style: { paddingLeft: '10px' },
            selector: row => row.brand_name,
        },
        {
            name: 'Brand Status',
            sortable: true,
            cell: row => <StatusBadge status={row.brand_status} />,
        },
        {
            name: 'Create Time',
            sortable: true,
            selector: row => (new Date(row.created_at).toLocaleString()),
        },
        {
            name: 'Actions',
            grow: 1,
            style: { minWidth: '180px' },
            cell: row => <ActionButtons row={row} />,
        }
    ];

    const deleteBrand = async (id: string) => {
        setDeleteStatus(statusList.onLoading);
        await deleteAxiosWithToken({
            url: `/brands/${id}`,
        });
        setDeleteStatus(statusList.onProgress);
        setBrandDeleteModal(false)
        fetchBrands(tableData.page, tableData.perPage);
    }

    const handlePerRowsChange = (newPerPage) => {
        setTableData({ ...tableData, perPage: newPerPage })
        fetchBrands(tableData.page, newPerPage);
    };

    const handlePageChange = (page) => {
        setTableData({ ...tableData, page: page })
        fetchBrands(page, tableData.perPage);
    };

    const fetchBrands = async (page, perPage) => {
        setLoading(true);

        const response = await getAxiosWithToken({
            url: `/brands?page=${page}&per_page=${perPage}`
        })

        setTableData({
            ...tableData,
            data: response.data.data,
            totalRow: response.data.total
        });

        setLoading(false);
        setSelectedBrand(null);
    }

    const fetchBrandsAndCloseModal = () => {
        fetchBrands(tableData.page, tableData.perPage);
        setBrandModal(false)
        setBrandEditModal(false)
        setBrandDeleteModal(false)
    }

    useEffect(() => {
        fetchBrands(tableData.page, tableData.perPage);
    }, []);

    return useMemo(() => (
        <>
            {selectedBrand && <DeleteConfirmationModal
                onClickFunction={() => deleteBrand(selectedBrand.id)}
                onCloseModal={() => { setBrandDeleteModal(false); setSelectedBrand(null) }}
                type="brand"
                isOpen={brandDeleteModal && selectedBrand}
                status={deleteStatus}
                name={selectedBrand.name}
            />}

            <Container maxWidth="xl">
                <Grid container mt={4} mb={2}>
                    <Grid item container xs={12} md={5} alignItems={"center"}>
                        <Typography variant="qrealSubtitle">BRAND LIST</Typography>
                    </Grid>
                    <Grid item xs={12} md={7} container justifyContent={"flex-end"}>
                        <Button variant='contained' onClick={() => setBrandModal(true)}>Create Brand</Button>
                    </Grid>
                </Grid>
                <CreateBrandModal
                    isOpen={brandModal}
                    onCloseModal={() => { setBrandModal(false) }}
                    onEnd={() => { fetchBrandsAndCloseModal() }}
                />
                {selectedBrand && <EditBrandModal
                    isOpen={brandEditModal && selectedBrand}
                    onCloseModal={() => { setBrandEditModal(false); setSelectedBrand(null) }}
                    onEnd={() => { fetchBrandsAndCloseModal() }}
                    name={selectedBrand.name}
                    id={selectedBrand.id}
                />}
                <QReviewDefaultTable
                    columns={columns}
                    data={tableData.data}
                    pagination={true}
                    progressPending={loading}
                />
            </Container>
        </>
    ), [tableData, loading, selectedBrand, brandModal])
}

export default BrandList;
