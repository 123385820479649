import { styled } from "@mui/material";
import { Link } from "react-router-dom";

export const QReviewUnstyledLink = styled(Link)(({theme}) => ({
    textDecoration: "none",
    color: theme.palette.text.primary,
    "&:focus, &:hover, &:visited, &:link, &:active": {
      textDecoration: "none",
      color: theme.palette.text.primary,
    }
}))