import { Box, Button, CircularProgress, Grid, Stack } from '@mui/material';
import { useCreateModel } from '../../providers/CreateModelProvider';

const ModelUploadStatusStep = () => {
  const {
    isModelCreatedSuccessfully,
    setIsModelCreatedSuccessfully,
    model,
    createModelPending,
    createProgress,
  } = useCreateModel()

  return (
    <>

      {createModelPending && (
        <>
          <Stack spacing={2}>
            <Stack direction={'row'} justifyContent={"center"}>
              {createProgress.message}
            </Stack>
            <Stack direction={'row'} justifyContent={"center"}>
              <CircularProgress />
            </Stack>
          </Stack>
        </>
      )}
      {!createModelPending && createProgress.error && (
        <>
          <Stack spacing={2}>
            <Stack direction={'row'} justifyContent={"center"}>
              {createProgress.errorList[0].msg + ' - \"' + createProgress.errorList[0].value + '\"'}
            </Stack>

            <Stack direction={'row'} spacing={2} justifyContent={"flex-end"}>
              <Box display={"flex"}>
                <Button href="/model/create" onClick={() => {
                  setIsModelCreatedSuccessfully(false)
                }}>
                  Create New Model
                </Button>
              </Box>
            </Stack>
          </Stack>

        </>
      )}

      {isModelCreatedSuccessfully && (
        <>
          <Grid container spacing={2} direction={'row'}>
            <Grid item xs={12} justifyContent={"center"}>
              <Stack direction={'row'} justifyContent={"center"}>
                Model Created Successfully
              </Stack>
            </Grid>
            <Grid item xs={12}>
              <Stack direction={'row'} spacing={2} justifyContent={"flex-end"}>
                <Box display={"flex"}>
                  <Button href="/model/create" onClick={() => {
                    setIsModelCreatedSuccessfully(false)
                  }}>
                    Create New Model
                  </Button>
                </Box>
                <Box display={"flex"}>
                  <Button href={`/${model._id}`}>Go to Model</Button>
                </Box>
              </Stack>
            </Grid>
          </Grid >
        </>
      )}

    </>
  )
}

export default ModelUploadStatusStep