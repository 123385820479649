import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, TextField } from "@mui/material"
import { useEffect } from 'react';
import axios from "axios"
import React from "react"
import { postAxiosWithToken } from "../../functions/axiosHelper"
import CustomDropzone from "../CustomDropzone"
import InfoBox from "../InfoBox"
import QReviewBtn from "../QReviewButton/QReviewBtn";

type Props = {
    isOpen: boolean
    onEnd: () => any
    onCloseModal: () => any
}

const CreateEnvironmentModal: React.FC<Props> = ({ onEnd, isOpen, onCloseModal }) => {
    const [file, setFile] = React.useState<any>([]);
    const [environmentName, setEnvironmentName] = React.useState<string>('');
    const [message, setMessage] = React.useState<string>('');
    const [processStatus, setProcessStatus] = React.useState<'info' | 'success'>('info');

    const onClickFunction = async () => {
        setProcessStatus('info');
        setMessage('Environment loading...');

        if (file.length === 0 || !environmentName) {
            setMessage('Please select file and enter environment name');
            return;
        }

        const createResponse = await postAxiosWithToken({
            url: '/environments',
            body: {
                environment_name: environmentName,
                environment_status: 'ACTIVE'
            }
        })

        setMessage('HDR Uploading...');
        const uploadUrl = createResponse.data?.data[0].hdr_file[0].pre_signed_url;

        if (uploadUrl) {
            const uploadResponse = await axios({
                method: "put",
                url: uploadUrl,
                headers: {
                    "Content-Type": "application/octet-stream",
                },
                data: file[0],
            }).catch((err) => {
                setMessage('Something went wrong');
            })

            if (uploadResponse) {
                setMessage('Environment created');
                setProcessStatus('success');
                onEnd();
            }
        }
    }

    useEffect(() => {
        if (!isOpen) {
            setMessage('');
        }
    }, [isOpen])


    return (
        <Dialog
            open={isOpen}
            onClose={onCloseModal}
            fullWidth={true}
            maxWidth={'sm'}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
                {"Create New HDR"}
            </DialogTitle>
            <DialogContent>
                <Grid container py={2}>
                    <TextField fullWidth onChange={(e) => {
                        setEnvironmentName(e.target.value);
                    }} placeholder="HDR Name" />
                </Grid>
                <CustomDropzone
                    setFile={setFile}
                    fileName={"HDR"}
                    isMultiple={false}
                    accept={{ 'application/octet-stream': ['.hdr'] }}
                    maxFiles={1}
                />
            </DialogContent>
            <DialogActions sx={{ margin: '16px' }}>
                <QReviewBtn type="submit" onClick={onClickFunction}>
                    Create
                </QReviewBtn>
                <QReviewBtn type="button" onClick={onCloseModal}>
                    Close
                </QReviewBtn>
            </DialogActions>
            {message &&
                <div className="container pb-3">
                    <InfoBox status={processStatus}>
                        {message}
                    </InfoBox>
                </div>
            }
        </Dialog>
    )
}

export default CreateEnvironmentModal

