import { Box, Button, Chip, Divider, Stack } from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { Mention, MentionsInput } from 'react-mentions';
import { MinuteOptions } from "../enums/time";
import { getAxiosWithToken, getServiceToken } from "../functions/axiosHelper";
import { getCookie, setCookieWithMinutes } from "../functions/cookieHelper";
import { createModelComment } from "../functions/createModelComment";
import { User } from "../types/user";
import defaultStyle from './comment/defaultStyle';
import UploadImageButton from "./elements/UploadImageButton";
import imageCompression from 'browser-image-compression';
import { BlockPageLeave, UnBlockPageLeave } from "../functions/helpers/window";

type Props = {
    modelId: string
}

const SplitScreenComments: React.FC<Props> = ({ modelId }) => {

    const [comment, setComment] = useState<string>("")
    const [buttonText, setButtonText] = useState<string>("Post Comment")
    const [users, setAllUsers] = useState<User[]>([]);
    const [files, setFiles] = useState<File[]>([]);
    const [inputRef, setInputRef] = useState<HTMLInputElement | null>(null);

    const fetchUsers = (token) => {
        getAxiosWithToken({
            url: '/users?list_all=true',
            authRequest: true,
            optionalToken: token
        }).then(response => {
            setAllUsers(response.data.data.map((user: User) => {
                return {
                    id: (user.first_name + ' ' + user.last_name + ' ' + user.email),
                    display: (user.email)
                }
            }));
        }).catch(err => {
            console.error('err', err);
        })
    }
    useEffect(() => {
        if (users.length >= 0) {
            const getUsers = async () => {
                const localToken = getCookie("serviceToken");
                if (localToken) {
                    fetchUsers(localToken);
                } else {
                    getServiceToken(process.env.REACT_APP_AUTH_SERVICE_NAME).then(async tokenResponse => {
                        if (tokenResponse && tokenResponse.authorization_token) {
                            setCookieWithMinutes("serviceToken", tokenResponse.authorization_token, MinuteOptions.ONE_HOUR);
                            fetchUsers(tokenResponse.authorization_token);
                        }
                    }).catch(err => {
                        console.error('err', err);
                    })
                }
            }

            getUsers();
        }
    }, [])

    const emailRequest = (email: string) => {
        return axios.post(process.env.REACT_APP_EMAIL_SERVICE + '/notification/emails',
            {
                type: "MAIL_NOTIFICATION",
                consumer: "MailNotificationConsumer",
                data: {
                    template: "COMMENT_NOTIFICATION",
                    receiver: email,
                    subject: "QReview Comment Notification",
                    mail_data: {
                        url: window.location.href
                    }
                }
            })
    }

    const sendEmailToMentions = async (text: string, emails) => {
        await Promise.all(emails.map(email => emailRequest(email))).then(res => {
            return true;
        }).catch(err => {
            console.error('err', err);
            return false;
        })
    }

    const uploadFiles = async (files: File[], putUrls: any[]) => {
        const options = {
            maxSizeMB: 1,
            maxWidthOrHeight: 1920,
            useWebWorker: true,
        }

        return Promise.all(files.map(async (file, index) => {
            const compressedFile = await imageCompression(file, options);
            return axios.put(putUrls[index].file, compressedFile, {
                headers: {
                    'Content-Type': file.type
                }
            })
        })).then(res => {
            return res.map((item, index) => {
                return item.config.url
            })
        }).catch(err => {
            console.error('err', err);
            return []
        })
    }

    const saveComment = async () => {
        BlockPageLeave('Comment upload in progress... Do you want to leave?');

        if (buttonText !== "Post Comment") { return }

        // Remove mentions email from comment
        const commentSplitRegex = /@\[[\w.-]+@[\w.-]+\]\(([\w\s]+)\)/g;
        let commentSplit = comment.replace(commentSplitRegex, "$1");
        const emails: string[] = comment.match(/([a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9_-]+)/gi) || [];

        emails.map(email => {
            commentSplit = commentSplit.replace(`@[${email}]`, "")
        })

        if (!emails.includes(process.env.REACT_APP_URGENT_NOTIFIER || "")) {
            emails.push(process.env.REACT_APP_URGENT_NOTIFIER || "")
        }


        setButtonText("Saving...")
        createModelComment(modelId, commentSplit).then(async res => {
            if (res.error == null) {
                const uploadUrls = res.data.data[0]?.pre_signed_urls;
                if (uploadUrls && uploadUrls.length > 0) {
                    setButtonText("Uploading Files...")
                    await uploadFiles(files, uploadUrls)
                }
                sendEmailToMentions(comment, emails.filter((item, index) => {
                    return emails.indexOf(item) === index;
                })).then(res => {
                    UnBlockPageLeave();
                    setButtonText("Saved")
                    window.location.reload()
                }).catch(err => {
                    setButtonText("Error")
                })
            } else {
                setButtonText("Error")
            }
        })
    }

    const onFileUpload = (e) => {
        const file = e.clipboardData.files[0];
        addFileToComment(file)
    }

    const addFileToComment = (file: File) => {
        if (!file || files.some(item => item.name === file.name)) {
            return;
        }
        setFiles([...files, file])
        const fileName = file.name.replace(/\.(?=.*\.)/g, "-");
        setComment(comment + `![image-${fileName}]!`);
        if (inputRef) { inputRef.blur() }
    }

    return (
        <>
            <Stack spacing={2}>
                <Stack direction={"row"} spacing={2}>
                    {files && files.length > 0 && files.map((file, index) => {
                        return (
                            <Chip label={<>
                                <img src={URL.createObjectURL(file)} style={{ height: '25px' }} />
                            </>} onDelete={() => {
                                setFiles(files.filter((item, i) => i !== index))
                                setComment(comment.replace(`![image-${file.name.replace(/\.(?=.*\.)/g, "-")}]!`, ""))
                            }}

                            />
                        )
                    })}
                </Stack>
                <Stack sx={{ borderRadius: '20%', position: 'relative' }}>
                    <MentionsInput value={comment} onChange={(e) => { setComment(e.target.value) }}
                        style={defaultStyle}
                        onPasteCapture={onFileUpload}
                        inputRef={(ref) => { setInputRef(ref) }}
                    >
                        <Mention
                            trigger="@"
                            data={users}
                            singleLine={false}
                            appendSpaceOnAdd={true}
                            displayTransform={(id, display) => {
                                return id;
                            }}
                        />
                    </MentionsInput>
                    <UploadImageButton style={{
                        position: 'absolute',
                        bottom: '5px',
                        right: '5px',
                        zIndex: 1000
                    }} onLoadCallback={(val) => {
                        if (val && val.name) {
                            addFileToComment(val)
                        }
                    }} />
                </Stack>

                <Box display={"flex"} flexDirection={"row"} justifyContent={"flex-end"}>
                    <Button onClick={saveComment}>{buttonText}</Button>
                </Box>
                <Divider />
            </Stack>
        </>
    )
}

export default SplitScreenComments
