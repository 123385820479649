import DataTable, { createTheme } from "react-data-table-component"
import { useThemeContext } from "../../theme/ThemeContextProvider"
import { CircularProgress, useTheme, Typography } from "@mui/material"

interface TableProps {
    columns: any
    data: any
    totalRows?: number
    defaultPage?: number
    defaultPerPage?: number
    pagination: boolean
    rowsPerPageOptions?: number[]
    progressPending: boolean
    isDataFromModel?: boolean
    handlePerRowsChange?: (e: any) => any
    handlePageChange?: (e: any) => any
    onRowClicked?: (e: any) => any
}

const QReviewDefaultTable = ({ defaultPage, defaultPerPage, columns, data, totalRows, pagination, rowsPerPageOptions, progressPending, isDataFromModel, onRowClicked, handlePerRowsChange, handlePageChange }: TableProps) => {
    const { mode } = useThemeContext();
    const theme = useTheme()
    return (
        <DataTable
            fixedHeader
            columns={columns}
            data={data}
            progressPending={progressPending}
            progressComponent={<CircularProgress />}
            noDataComponent={isDataFromModel?<Typography variant="qrealSubtitle">No Model available for your search term</Typography>:<Typography variant="qrealSubtitle">There are no records to display</Typography>}
            pagination={pagination}
            paginationServer
            paginationTotalRows={totalRows}
            paginationRowsPerPageOptions={rowsPerPageOptions}
            onChangeRowsPerPage={handlePerRowsChange}
            onChangePage={handlePageChange}
            onRowClicked={onRowClicked}
            paginationDefaultPage={defaultPage}
            paginationPerPage={defaultPerPage}
            theme={mode}
            customStyles={{
                table: {
                    style: {
                        border: "none",
                        background: theme.palette.background.default
                    }
                },
                rows: {
                    style: {
                        cursor: 'pointer',
                        background: theme.palette.background.paper,
                        border: "none",
                        borderRadius: "10px",
                        marginBottom: "8px",
                        height: "56px"
                    }
                },
                cells: {
                    style: {
                        marginRight: "20px",
                        padding: 0,
                        fontSize: "14px",
                        fontFamily: "Crystal",
                    }
                },
                pagination: {
                    style: {
                        border: 'none',
                        boxShadow: "none",
                        background: theme.palette.background.default
                    }
                },
                headRow: {
                    style: {
                        border: 'none',
                        background: theme.palette.background.default,
                        fontSize: "14px",
                        fontWeight: 500,
                        fontFamily: "Crystal",
                    }
                },
                headCells: {
                    style: {
                        marginRight: "20px",
                        padding: 0
                    }
                },
                progress: {
                    style: {
                        background: theme.palette.background.default,
                        height: 500,
                    }
                },
                noData: {
                    style: {
                        background: theme.palette.background.default,
                        height: 500,
                    }
                }
            }}
        />
    )

}

export default QReviewDefaultTable