import React, { useEffect, useState } from "react"
import {
    Card,
    CardContent,
    CardActions,
    Autocomplete,
    TextField,
    Button,
    Grid,
    CardHeader,
    Typography,
    MenuItem,
    Select,
    Alert
} from "@mui/material"

import { copyFile, createVariant, getByQReviewId, listBaseModels, listEnvironments, updateVariant } from "../../../../services/QMMService"
import { Option } from "../../../../types/option"
import { useParams } from "react-router-dom"
import { ArrowDownIconBlack } from "../../../../assets/icons"
import { Model } from "../../../../providers/AppProvider"
import AddNewBaseModelCard from "./addNewBaseModelCard"
import AddNewEnvironmentCard from "./addNewEnvironmentCard"
import QmmCardLoading from "./qmmCardLoading"
import SyncFilesCard from "./syncFilesCard"
import CreateNewVariantModel from "./createNewVariantModel"
import { QMMVariantModel } from "../../../../types/QMMTypes"
import { cdnToS3BucketNameChanger } from "../../../../functions/cdnToS3URLChanger"

const QMMModelStatusList = {
    ACTIVE: "Active",
    PASSIVE: "Passive"
}

type ServiceSharingQMMCardProps = {
    currentModel: Model
}

const QMMCard = (props: ServiceSharingQMMCardProps) => {
    const { id } = useParams()
    const [baseModels, setBaseModels] = useState<Array<Option>>([])
    const [environments, setEnvironments] = useState<Array<Option>>([])
    const [isModelExist, setIsModelExist] = useState<boolean>(false)
    const [isLoading, setIsLoading] = useState<boolean>(true)
    const [qmmVariantModel, setQmmVariantModel] = useState<QMMVariantModel>(
        {
            id: "",
            name: "",
            wanna_id: "",
            external_id: "",
            model_id: "",
            environment_id: "",
            status: "",
            glb_file: {
                path: "",
                url: "",
                name: "",
                size: "",
                file_type: "",
                _id: ""
            },
            usdz_file: {
                path: "",
                url: "",
                name: "",
                size: "",
                file_type: "",
                _id: ""
            },
            thumbnail_file: {
                path: "",
                url: "",
                name: "",
                size: "",
                file_type: "",
                _id: ""
            },
            updated_at: ""
        }
    )

    const fetchBaseModels = () => {
        listBaseModels().then((response) => {
            const tempArray: Option[] = []

            for (let [index, model] of response.data.entries()) {
                tempArray[index] = { value: model._id, label: model.name }
            }
            console.log('ghhh', tempArray)
            setBaseModels(tempArray)
        })
    }

    const fetchEnvironments = () => {
        listEnvironments().then((response) => {
            const tempArray: Option[] = []

            for (let [index, environment] of response.data.entries()) {
                tempArray[index] = { value: environment._id, label: environment.name }
            }
            setEnvironments(tempArray)
        })
    }

    const fetchQMMModel = () => {
        if (!id) return

        getByQReviewId(id).then((response) => {
            setIsLoading(false)

            if (response.data?._id) {
                setQmmVariantModel({
                    id: response.data._id,
                    name: response.data.name,
                    wanna_id: response.data.wanna_id,
                    external_id: response.data.external_id,
                    status: response.data.status,
                    updated_at: response.data.updated_at,
                    model_id: response.data.model_id,
                    environment_id: response.data.environment_id,
                    glb_file: response.data.glb_file,
                    usdz_file: response.data.usdz_file,
                    thumbnail_file: response.data.thumbnail_file
                })

                setIsModelExist(true)
            }
        })
    }

    const handleUpdate = () => {
        setIsLoading(true)
        if (qmmVariantModel.id === "") {
            //Create api
            console.log('create', { ...qmmVariantModel, mopu_model_id: id });
            console.log('create', props.currentModel);
            createVariant({ ...qmmVariantModel, mopu_model_id: id }).then((response) => {
                console.log('res', response)
                //Then file copies
                if (response.is_complete) {
                    Promise.all([
                        copyFile(cdnToS3BucketNameChanger(props.currentModel.glb_file[0]), response.data.glb_file.path),
                        copyFile(cdnToS3BucketNameChanger(props.currentModel.usdz_file[0]), response.data.usdz_file.path),
                        copyFile(cdnToS3BucketNameChanger(props.currentModel.poster_file[0]), response.data.thumbnail_file.path),
                        copyFile(cdnToS3BucketNameChanger(props.currentModel.thumbnail_file[0]), response.data.glb_file.path)
                    ]).then((responses) => {
                        window.location.reload()
                        setIsLoading(false)
                    }).catch((error) => {
                        console.error(error);
                    });
                }

            })
        } else {
            qmmVariantModel.updated_at = Date.now().toString()
            updateVariant(qmmVariantModel.id, qmmVariantModel).then((response) => {
                console.log(response)
                setIsLoading(false)
            })
        }


    }

    useEffect(() => {
        if (baseModels.length === 0) {
            fetchBaseModels()
        }

        if (environments.length === 0) {
            fetchEnvironments()
        }

        if (!qmmVariantModel.id && id) {
            fetchQMMModel()
        }
    }, [])

    if (isLoading) {
        return (
            <QmmCardLoading />
        )
    } else {
        return (
            <>
                <Grid container spacing={2} pt={3}>
                    <Grid item>
                        <Typography variant="h6">
                            {
                                !isModelExist ?
                                    <>
                                        <Alert severity="warning">
                                            This model does not exist in QMM. Please create a new variant model.
                                        </Alert>
                                    </>
                                    :
                                    <>
                                        <Alert severity="info">
                                            Last Update {new Date(qmmVariantModel.updated_at)?.toLocaleString()}
                                        </Alert>
                                    </>
                            }

                        </Typography>
                    </Grid>
                    {isModelExist && <Grid item xs={'auto'} alignSelf={'center'}>
                        <Button variant="contained">
                            <a target={"_blank"}
                                rel={"noreferrer"}
                                href={`${process.env.REACT_APP_QMM_DOMAIN}/variants/${qmmVariantModel.id}`}
                                style={{ textDecoration: "none", color: "black" }}>
                                Show in QMM Admin
                            </a>
                        </Button>
                    </Grid>}
                </Grid>
                <Grid container spacing={2} pt={3}>
                    <Grid item xs={3}>
                        <TextField
                            size="medium"
                            id="name"
                            name="name"
                            label="QMM Model Name"
                            value={qmmVariantModel.name}
                            onChange={(e: React.ChangeEvent<any>) => {
                                setQmmVariantModel({ ...qmmVariantModel, name: e.target.value })
                            }}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <TextField
                            size="medium"
                            id="wanna_id"
                            name="wanna_id"
                            label="QMM Wanna ID"
                            value={qmmVariantModel.wanna_id}
                            onChange={(e: React.ChangeEvent<any>) => {
                                setQmmVariantModel({ ...qmmVariantModel, wanna_id: e.target.value })
                            }}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <TextField
                            size="medium"
                            id="external_id"
                            name="external_id"
                            label="QMM External ID"
                            value={qmmVariantModel.external_id}
                            onChange={(e: React.ChangeEvent<any>) => {
                                setQmmVariantModel({ ...qmmVariantModel, external_id: e.target.value })
                            }}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <Select
                            size="medium"
                            IconComponent={ArrowDownIconBlack}
                            onChange={(e) => {
                                setQmmVariantModel({ ...qmmVariantModel, status: e.target.value })
                            }}
                            value={qmmVariantModel.status}
                            fullWidth
                        >
                            <MenuItem value="" disabled>Status</MenuItem>
                            {Object.entries(QMMModelStatusList).map((status, index) => (
                                <MenuItem key={"status-" + index} value={status[0]}>{status[1]}</MenuItem>
                            ))
                            }
                        </Select>
                    </Grid>
                </Grid>
                <Grid container spacing={2} pt={3}>
                    <Grid item xs={4}>
                        <Card>
                            <CardHeader title={"Set Base Model"} />
                            <CardContent>
                                {
                                    (baseModels.length > 0) && (
                                        <Autocomplete
                                            id="qmm-base-model-combo-box"
                                            options={baseModels}
                                            sx={{ width: 300 }}
                                            disableClearable
                                            value={baseModels.find((model) => model.value === qmmVariantModel.model_id)}
                                            onChange={(e, selected) => {
                                                setQmmVariantModel({ ...qmmVariantModel, model_id: selected.value })
                                            }}
                                            renderInput={(params) => <TextField {...params} label="Base Model" />}
                                        />
                                    )
                                }

                            </CardContent>
                            <CardActions>
                                <AddNewBaseModelCard
                                    callback={(newBaseModel: any) => {
                                        setBaseModels([...baseModels, {
                                            value: newBaseModel._id,
                                            label: newBaseModel.name
                                        }])
                                        setQmmVariantModel({ ...qmmVariantModel, model_id: newBaseModel._id })
                                    }}
                                />
                            </CardActions>
                        </Card>
                    </Grid>
                    <Grid item xs={4}>
                        <Card>
                            <CardHeader title={"Set Environment"} />
                            <CardContent>
                                {
                                    (environments.length > 0) && (
                                        <Autocomplete
                                            id="qmm-environments-combo-box"
                                            options={environments}
                                            sx={{ width: 300 }}
                                            disableClearable
                                            value={environments.find((environment) => environment.value === qmmVariantModel.environment_id)}
                                            onChange={(e, selected) => {
                                                setQmmVariantModel({ ...qmmVariantModel, environment_id: selected.value })
                                            }}
                                            renderInput={(params) => <TextField {...params} label="Environment" />}
                                        />
                                    )
                                }
                            </CardContent>
                            <CardActions>
                                <AddNewEnvironmentCard
                                    callback={(newEnvironment: any) => {
                                        setEnvironments([...environments, {
                                            value: newEnvironment._id,
                                            label: newEnvironment.name
                                        }])
                                        setQmmVariantModel({ ...qmmVariantModel, environment_id: newEnvironment._id })
                                    }}
                                />
                            </CardActions>

                        </Card>
                    </Grid>
                    <Grid item xs={4}>
                        {
                            (isModelExist) && (
                                <SyncFilesCard
                                    thumbnail_file_source={props.currentModel.poster_file[0]}
                                    thumbnail_file_target={qmmVariantModel.thumbnail_file?.path}
                                    glb_file_source={props.currentModel.glb_file[0]}
                                    glb_file_target={qmmVariantModel.glb_file?.path}
                                    usdz_file_source={props.currentModel.usdz_file[0]}
                                    usdz_file_target={qmmVariantModel.usdz_file?.path}
                                />
                            )
                        }
                    </Grid>
                </Grid>
                <Grid container spacing={2} pt={3}>
                    <Grid item xs={12}>
                        <Button type="submit" variant="contained" onClick={handleUpdate}>{isModelExist ? 'Update' : 'Create'}</Button>
                    </Grid>
                </Grid>
            </>
        )
    }
}

export default QMMCard