import { useCallback, useState } from 'react';
import _ from 'lodash';

const DebouncedInput = ({ data, handleInputChange }) => {
  const [string, setString] = useState(data);

  const debounceFunc = useCallback(
    _.debounce(e => handleInputChange(e.target.value), 1500),
    []
  );

  const handleChange = e => {
    setString(e.target.value);
    debounceFunc(e);
  };
  return (
    <>
      <div className="input-group input-group-sm">
        <input type="text" className="form-control" onChange={handleChange} value={string} />
      </div>
    </>
  )
};

export default DebouncedInput;