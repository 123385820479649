import React from "react"
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material"
import { deleteAxiosWithToken } from "../../functions/axiosHelper";
import QReviewBtn from "../QReviewButton/QReviewBtn";

type Props = {
    isOpen: boolean
    closeModal: () => any
    selectedModelId: string
}

export const statusList = {
    onProgress: "Delete",
    onLoading: "Deleting",
    success: "Deleted",
    error: "Error"
}
const DeleteReferrerConfirmationModal: React.FC<Props> = ({ isOpen, closeModal, selectedModelId }) => {
    const [isModelDeletedSuccessfully, setIsModelDeletedSuccessfully] = React.useState(false);
    const [deleteModelPending, setDeleteModelPending] = React.useState(false);

    return (
        <Dialog
            open={isOpen}
            onClose={closeModal}
            fullWidth={true}
            maxWidth={'sm'}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
                {'Delete Referrer Confirmation'}
            </DialogTitle>
            <DialogContent>
                Are you sure you want to delete referrer?
            </DialogContent>
            <DialogActions sx={{ margin: '16px' }}>
                <QReviewBtn type="submit" onClick={
                    isModelDeletedSuccessfully
                        ? () => { }
                        : () => {
                            deleteAxiosWithToken({
                                url: `/referrers/${selectedModelId}`
                            }).then((res: any) => {
                                if (res.data.data && res.data.is_complete) {
                                    setIsModelDeletedSuccessfully(true)
                                    closeModal();
                                } else {
                                    setIsModelDeletedSuccessfully(false)
                                }
                            })
                        }
                }>
                    {isModelDeletedSuccessfully ? (
                        <div>
                            <i className="bi-check2-square"></i>
                            <span className="ps-1">Deleted</span>
                        </div>
                    ) : deleteModelPending ? (
                        <div>
                            <span className="spinner-border spinner-border-sm"></span>
                            <span className="ps-1">Delete</span>
                        </div>
                    ) : (
                        "Delete"
                    )}
                </QReviewBtn>
                <QReviewBtn type='button' onClick={closeModal}>
                    Close
                </QReviewBtn>
            </DialogActions>

        </Dialog>
    )
}

export default DeleteReferrerConfirmationModal
