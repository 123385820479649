import { createSvgIcon } from '@mui/material/utils';

export const DownloadIcon = createSvgIcon(
    <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26" fill="none">
        <path d="M11.9166 4.3335H14.0833V13.0002H16.2499V15.1668H14.0833V17.3335H11.9166V15.1668H9.74992V13.0002H11.9166V4.3335ZM9.74992 13.0002H7.58325V10.8335H9.74992V13.0002ZM16.2499 13.0002V10.8335H18.4166V13.0002H16.2499ZM4.33325 19.5002H21.6666V21.6668H4.33325V19.5002Z" fill="#272527"/>
    </svg>
  ,'Download'
)

export const FilterIcon = createSvgIcon(
    <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26" fill="none">
        <path d="M10.5 21.5H15.5V19H10.5V21.5ZM1.75 6.5V9H24.25V6.5H1.75ZM5.5 15.25H20.5V12.75H5.5V15.25Z" fill="#272527"/>
    </svg>
,'Filter'
)

export const EyeIcon = createSvgIcon(
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect x="7.5625" y="7.75" width="1.875" height="2.5" fill="white"/>
<rect x="7.5625" y="7.75" width="1.875" height="2.5" fill="white"/>
<rect x="5.6875" y="4" width="5.625" height="1.25" fill="white"/>
<rect x="5.6875" y="4" width="5.625" height="1.25" fill="white"/>
<rect x="5.6875" y="12.75" width="5.625" height="1.25" fill="white"/>
<rect x="5.6875" y="12.75" width="5.625" height="1.25" fill="white"/>
<rect x="11.3125" y="5.25003" width="1.875" height="1.25" fill="white"/>
<rect x="11.3125" y="5.25003" width="1.875" height="1.25" fill="white"/>
<rect x="13.1875" y="6.5" width="1.875" height="1.25" fill="white"/>
<rect x="13.1875" y="6.5" width="1.875" height="1.25" fill="white"/>
<rect x="15.0625" y="7.75" width="0.9375" height="1.25" fill="white"/>
<rect x="15.0625" y="7.75" width="0.9375" height="1.25" fill="white"/>
<rect x="15.0625" y="9" width="0.9375" height="1.25" fill="white"/>
<rect x="15.0625" y="9" width="0.9375" height="1.25" fill="white"/>
<rect width="1.875" height="1.25" transform="matrix(1 0 0 -1 11.3125 12.75)" fill="white"/>
<rect width="1.875" height="1.25" transform="matrix(1 0 0 -1 11.3125 12.75)" fill="white"/>
<rect width="1.875" height="1.25" transform="matrix(1 0 0 -1 13.1875 11.5)" fill="white"/>
<rect width="1.875" height="1.25" transform="matrix(1 0 0 -1 13.1875 11.5)" fill="white"/>
<rect width="0.9375" height="1.25" transform="matrix(1 0 0 -1 15.0625 10.25)" fill="white"/>
<rect width="0.9375" height="1.25" transform="matrix(1 0 0 -1 15.0625 10.25)" fill="white"/>
<rect width="0.9375" height="1.25" transform="matrix(1 0 0 -1 15.0625 9)" fill="white"/>
<rect width="0.9375" height="1.25" transform="matrix(1 0 0 -1 15.0625 9)" fill="white"/>
<rect x="5.6875" y="12.75" width="1.875" height="1.25" transform="rotate(-180 5.6875 12.75)" fill="white"/>
<rect x="5.6875" y="12.75" width="1.875" height="1.25" transform="rotate(-180 5.6875 12.75)" fill="white"/>
<rect x="3.8125" y="11.5" width="1.875" height="1.25" transform="rotate(-180 3.8125 11.5)" fill="white"/>
<rect x="3.8125" y="11.5" width="1.875" height="1.25" transform="rotate(-180 3.8125 11.5)" fill="white"/>
<rect x="1.9375" y="10.25" width="0.9375" height="1.25" transform="rotate(-180 1.9375 10.25)" fill="white"/>
<rect x="1.9375" y="10.25" width="0.9375" height="1.25" transform="rotate(-180 1.9375 10.25)" fill="white"/>
<rect x="1.9375" y="9" width="0.9375" height="1.25" transform="rotate(-180 1.9375 9)" fill="white"/>
<rect x="1.9375" y="9" width="0.9375" height="1.25" transform="rotate(-180 1.9375 9)" fill="white"/>
<rect width="1.875" height="1.25" transform="matrix(-1 -1.16564e-07 -6.55671e-08 1 5.6875 5.25)" fill="white"/>
<rect width="1.875" height="1.25" transform="matrix(-1 -1.16564e-07 -6.55671e-08 1 5.6875 5.25)" fill="white"/>
<rect width="1.875" height="1.25" transform="matrix(-1 -1.16564e-07 -6.55671e-08 1 3.8125 6.5)" fill="white"/>
<rect width="1.875" height="1.25" transform="matrix(-1 -1.16564e-07 -6.55671e-08 1 3.8125 6.5)" fill="white"/>
<rect width="0.9375" height="1.25" transform="matrix(-1 -1.16564e-07 -6.55671e-08 1 1.9375 7.75)" fill="white"/>
<rect width="0.9375" height="1.25" transform="matrix(-1 -1.16564e-07 -6.55671e-08 1 1.9375 7.75)" fill="white"/>
<rect width="0.9375" height="1.25" transform="matrix(-1 -1.16564e-07 -6.55671e-08 1 1.9375 9)" fill="white"/>
<rect width="0.9375" height="1.25" transform="matrix(-1 -1.16564e-07 -6.55671e-08 1 1.9375 9)" fill="white"/>
</svg>
,'EyeIcon')

export const ArrowDownIconBlack = createSvgIcon(
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M10.8614 13.2L13.1387 13.2L13.1387 10.8L15.4161 10.8L15.4161 13.2L13.1387 13.2L13.1387 15.6L10.8614 15.6L10.8614 13.2L8.584 13.2L8.584 10.8L10.8614 10.8L10.8614 13.2ZM8.584 10.8L6.30664 10.8L6.30664 8.40002L8.584 8.40002L8.584 10.8ZM15.4161 10.8L15.4161 8.40002L17.6934 8.40002L17.6934 10.8L15.4161 10.8Z" fill="#272527"/>
    </svg>, 
    "ArrowDownIcon"
)

export const ArrowDownIconWhite = createSvgIcon(
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M10.8614 13.2L13.1387 13.2L13.1387 10.8L15.4161 10.8L15.4161 13.2L13.1387 13.2L13.1387 15.6L10.8614 15.6L10.8614 13.2L8.584 13.2L8.584 10.8L10.8614 10.8L10.8614 13.2ZM8.584 10.8L6.30664 10.8L6.30664 8.40002L8.584 8.40002L8.584 10.8ZM15.4161 10.8L15.4161 8.40002L17.6934 8.40002L17.6934 10.8L15.4161 10.8Z" fill="white"/>
    </svg>, 
    "ArrowDownIcon"
)