import * as React from 'react';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { useAuth } from 'qreal-auth-provider/src';
import { useNavigate } from 'react-router-dom';
import { CircularProgress, Avatar, CssBaseline, TextField, Alert, Link, Grid, Box, Typography, Container } from '@mui/material';
import { getCookie, removeCookie } from '../functions/cookieHelper';
import QReviewBtn from '../components/QReviewButton/QReviewBtn';
import { useMixpanel } from 'react-mixpanel-browser';

function Copyright(props: any) {
    return (
        <Typography variant="body2" color="text.secondary" align="center" {...props}>
            {'Copyright © '}
            <Link color="inherit" href="https://www.qreal.io/">
                QReal
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}

export default function Login() {
    const { token, onLogin } = useAuth();
    const [error, setError] = React.useState<string | null>(null);
    const [loading, setLoading] = React.useState(false);
    const mixpanel = useMixpanel();

    let navigate = useNavigate();

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        setError(null);
        setLoading(true);
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        removeCookie("serviceToken");
        onLogin({ email: data.get('email'), password: data.get('password') }).then((response) => {
            localStorage.setItem("expireToken", response.expire_date);
            setLoading(false);
            if (response.success) {
                mixpanel.identify(data.get('email'));
                navigate(getCookie('redirectUrl') ? getCookie('redirectUrl') as string : '../', { replace: true });
                navigate(0)
            } else {
                setError(response.message);
            }
        });
    };

    return (
            <Container component="main" maxWidth="xs">
                <CssBaseline />
                <Box
                    sx={{
                        marginTop: 8,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <Avatar sx={{ m: 1, bgcolor:"primary.main"}} >
                        <LockOutlinedIcon sx={{color:"black"}} />
                    </Avatar>
                    <Typography component="h1" variant="h5" className='white-text'>
                        QReview Sign In
                    </Typography>
                    <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            id="email"
                            label="Email"
                            name="email"
                            autoComplete="email"
                            type={'email'}
                            autoFocus
                        />
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            name="password"
                            label="Password"
                            type="password"
                            id="password"
                            autoComplete="current-password"
                        />
                        {loading ?
                            <Grid container justifyContent={'center'} py={2}>
                                <Grid item>
                                    <CircularProgress />
                                </Grid>
                            </Grid> :
                            <QReviewBtn
                                style={{ width: '100%', margin: '24px 0px 16px' }}
                                type="submit"
                            >
                                Sign In
                            </QReviewBtn>
                        }
                        {error && <Alert severity="error">Invalid username or password. Please try again.</Alert>}

                        <Typography component="p" variant="overline" className='white-text text-center'>
                            <a style={{ textDecoration: 'none' }} href={`${process.env.REACT_APP_IDENTITY_URL}user/forgot-password`}>Forgot Password</a>
                        </Typography>
                    </Box>
                </Box>
                <Copyright sx={{ mt: 8, mb: 4 }} />
            </Container>
    );
}