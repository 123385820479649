import { Box, Button, Grid } from "@mui/material";
import { useEffect, useState } from "react";
import { getAxiosWithToken } from "../functions/axiosHelper";
import { updateModel } from "../functions/updateModel";
import QReviewDefaultTable from "./tables/QReviewDefaultTable";
import { useAuth } from "qreal-auth-provider/src";
import { authKeys, checkAuth } from "../enums/authKeys";
import { Organization } from "../types/organization";
import TrashIconBlack from '../assets/icons/Trash-Icon-Black.svg'
import TrashIconWhite from '../assets/icons/Trash-Icon-White.svg'
import QReviewActionButton from "./QReviewButton/QReviewActionButton";
import { useThemeContext } from "../theme/ThemeContextProvider";
import SingleAutoCompleteInput from "./elements/AutoCompleteInput";

const OrganizationTable = ({ selectedList, modelId, refetch }) => {
    const { mode } = useThemeContext();
    const [selectedOrganization, setSelectedOrganization] = useState<Organization | null>(null);
    const [organizations, setOrganizations] = useState<Organization[]>([]);
    const [columns, setColumns] = useState<any>([]);
    const [loading, setLoading] = useState(false);
    const { authList } = useAuth();

    useEffect(() => {
        if (organizations.length === 0) {
            getAxiosWithToken({
                url: '/organizations?list_all=true',
                authRequest: true
            }).then((response) => {
                setOrganizations(response.data.data);
            });
        }
        setColumns(getColumns());
    }, []);

    useEffect(() => {
        setColumns(getColumns());
    }, [authList]);

    const getModelOrganizations = () => {
        return organizations.filter((organization) => {
            return selectedList.some((selected) => selected === organization._id)
        })
    }

    const addCallBack = (organization: Organization) => {
        if (!organization) {
            document.getElementById('exampleDataList')?.focus();
            return;
        }
        setLoading(true);
        updateModel({
            authorized_organizations: [...getModelOrganizations(), selectedOrganization]
        }, modelId).then((res) => {
            setLoading(false);
            if (res.data && res.data.is_complete) { refetch(); }
        })

    }

    const removeCallBack = (organization: Organization) => {
        setLoading(true);
        updateModel({
            authorized_organizations: selectedList.filter((id) => id !== organization._id)
        }, modelId).then((res) => {
            setLoading(false);
            if (res.data && res.data.is_complete) { refetch(); }
        })
    }

    const getColumns = () => {
        const tableData: {
            name: string,
            sortable?: boolean,
            grow?: number,
            selector?: (row: Organization) => string,
            cell?: (row: Organization) => JSX.Element
        }[] = [
                {
                    name: 'Organization Name',
                    sortable: true,
                    grow: 2,
                    selector: (row: Organization) => row.organization_name,
                },
                {
                    name: 'Description',
                    sortable: true,
                    grow: 2,
                    selector: (row: Organization) => row.organization_name,
                }
            ]

        if (checkAuth(authList, authKeys.update_organization)) {
            tableData.push(
                {
                    name: 'Actions',
                    cell: (row: Organization) =>
                        <Box onClick={() => {
                            removeCallBack(row)
                        }}>
                            <QReviewActionButton icon={mode === "light" ? TrashIconBlack : TrashIconWhite} tooltip='Delete Organization' />
                        </Box>

                }
            )
        }

        return tableData;
    }
    return (
        <Grid container spacing={2} pt={3}>
            <Grid item xs={12} md={8}>
                <QReviewDefaultTable
                    columns={columns}
                    data={getModelOrganizations()}
                    progressPending={loading}
                    pagination={false}
                />
            </Grid>
            <Grid item xs={12} md={4} justifyContent={'center'}>
                {checkAuth(authList, authKeys.update_organization) && <Grid container spacing={1}>
                    <Grid item xs={8}>
                        <SingleAutoCompleteInput
                            setSelectedInput={setSelectedOrganization}
                            options={organizations.filter((organization) => {
                                return !selectedList.some((selected) => selected === organization._id)
                            })}
                            optionLabelKey='organization_name'
                            inputLabel='Select Organization'
                        />
                    </Grid>

                    <Grid item xs={4}>
                        <Button onClick={() => {
                            addCallBack(selectedOrganization as Organization);
                            setSelectedOrganization(null);
                        }} variant='contained' color='success'>Add</Button>
                    </Grid>
                </Grid>}

            </Grid>
        </Grid>
    );
};

export default OrganizationTable;