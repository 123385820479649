import { Grid, Button, TextField, Autocomplete, Select, MenuItem, Typography } from "@mui/material"
import AddIcon from "@mui/icons-material/Add"
import React, { useEffect, useState } from "react"
import { Option } from "../../../../types/option"
import { BaseModel } from "../../../../types/QMMTypes"
import { availableCategories, availableStatuses } from "../../../../enums/QMMConstants"
import { listBrands } from "../../../../services/QMMService"
import CustomDropzone from "../../../CustomDropzone"
import { ArrowDownIconBlack } from "../../../../assets/icons"
import { createBaseModel, uploadFile } from "../../../../services/QMMService"
import { getOrganizations } from '../../../../functions/gerOrganizations';

const AddNewBaseModelCard = ({ callback }) => {
    const [saveButtonState, setSaveButtonState] = useState<boolean>(false)
    const [addNewButtonState, setAddNewButtonState] = useState<boolean>(false)
    const [brands, setBrands] = useState<Array<Option>>([])
    const [thumbnailFile, setThumbnailFile] = useState<File | null>(null)
    const [organizationList, setOrganizationList] = useState<Array<Option>>([])
    const [newBaseModel, setNewBaseModel] = useState<BaseModel>({
        name: "",
        brand_id: "",
        organization_id: "",
        category: "SHOES",
        status: "ACTIVE",
        thumbnail_file : {
            name : "",
            type : "image/webp",
            size : ""
        }
    })

    const fetchBrands = () => {
        listBrands().then((response) => {
            const tempArray: Option[] = []

            for (let [index, brand] of response.data.entries()) {
                tempArray[index] = { value: brand._id, label: brand.name }
            }
            setBrands(tempArray)
        })
    }

    const fetchOrganizations = () => {
        const tempArray: Option[] = []
        getOrganizations().then((response) => {
            response.data.data.forEach((org,index) => {
                tempArray[index] = {value: org._id, label: org.organization_name}
            })
            setOrganizationList(tempArray)
        })
    }

    const handleSave = () => {
        if (!thumbnailFile) {
            return
        }

        // Disable the save button
        setSaveButtonState(true)

        // Set the thumbnail file name and size
        newBaseModel.thumbnail_file.name = thumbnailFile[0].name
        newBaseModel.thumbnail_file.size = thumbnailFile[0].size.toString()

        // Send the request to create the base model
        createBaseModel(newBaseModel).then((response) => {
            uploadFile(thumbnailFile[0], response.data.thumbnail_file.pre_signed_url, "image/webp").then((res) => {
                callback(response.data);
                setAddNewButtonState(false)
                setSaveButtonState(false)
            }).catch((err) => {
                console.log(err)
            })
        })
    }

    useEffect(() => {
        if (brands.length === 0) {
            fetchBrands()
        }

        if (organizationList.length === 0) {
            fetchOrganizations()
        }

    }, [])

    return (
        <>
            {addNewButtonState ?
                <>
                    <Grid container pt={3} spacing={2}>
                        <Grid item xs={12}>
                            <Typography variant={"h5"}>
                                Add New Base Model
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                label="Base Model Name"
                                value={newBaseModel.name}
                                fullWidth={true}
                                onChange={(e) => setNewBaseModel({ ...newBaseModel, name: e.target.value })}
                            />
                        </Grid>
                        <Grid item container spacing={1}>
                            <Grid item xs={6}>
                                <Autocomplete
                                    id="qmm-brands-combo-box"
                                    options={brands}
                                    disableClearable
                                    value={brands.find((brand) => brand.value === newBaseModel.brand_id)}
                                    onChange={(e, selected) => {
                                        setNewBaseModel({ ...newBaseModel, brand_id: selected.value })
                                    }}
                                    renderInput={(params) => <TextField {...params} label="Brand" />}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <Autocomplete
                                    loading={organizationList.length > 0}
                                    id='qmm-organizations-combo-box'
                                    options={organizationList}
                                    disableClearable
                                    value={organizationList.find((organization) => organization.value === newBaseModel.organization_id)}
                                    onChange={(e, selected) => {
                                        setNewBaseModel({ ...newBaseModel, organization_id: selected.value })
                                    }}
                                    renderInput={(params) => <TextField {...params} label="Organization" />}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <Select
                                    size="medium"
                                    fullWidth
                                    id="qmm-category-select"
                                    label="Category"
                                    value={newBaseModel.category}
                                    onChange={(e) => setNewBaseModel({ ...newBaseModel, category: e.target.value })}
                                    IconComponent={ArrowDownIconBlack}
                                >
                                    <MenuItem value="" disabled>Category</MenuItem>
                                    {availableCategories.map((category) => (
                                        <MenuItem key={"qmm_category" + category.value}
                                                  value={category.value}
                                        >
                                            {category.label}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </Grid>
                            <Grid item xs={6}>
                                <Select
                                    size="medium"
                                    fullWidth
                                    id="qmm-base-model-status-select"
                                    label="Status"
                                    value={newBaseModel.status}
                                    onChange={(e) => setNewBaseModel({ ...newBaseModel, status: e.target.value })}
                                    IconComponent={ArrowDownIconBlack}
                                >
                                    <MenuItem value="" disabled>Status</MenuItem>
                                    {availableStatuses.map((status) => (
                                        <MenuItem key={"qmm_base_model_status" + status.value}
                                                  value={status.value}>{status.label}</MenuItem>
                                    ))}
                                </Select>
                            </Grid>
                        </Grid>

                        <Grid item xs={12}>
                            <CustomDropzone
                                setFile={setThumbnailFile}
                                fileName="Base Model Thumbnail"
                                isMultiple={false}
                                accept={{ "image/webp": [".webp"] }}
                                maxFiles={1}
                            />
                        </Grid>
                        <Grid item container justifyContent="end">
                            <Grid item xs={2} mr={1}>
                                <Button
                                    variant="contained"
                                    onClick={() => setAddNewButtonState(false)}
                                    style={{ backgroundColor: "#FF5876" }}
                                    color={"info"}
                                    disabled={saveButtonState}
                                >
                                    Cancel
                                </Button>
                            </Grid>
                            <Grid item xs={2} ml={2}>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={handleSave}
                                    disabled={saveButtonState}
                                >
                                    Save
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>

                </>
                : <>
                    <Button variant="outlined"
                            onClick={() => setAddNewButtonState(true)}
                    >
                        <AddIcon color={"warning"} /> Add New
                    </Button>
                </>
            }
        </>
    )
}

export default AddNewBaseModelCard