import { Box, Stack, Typography } from '@mui/material'
import { QReviewUnstyledLink } from '../shared/QReviewUnstyledLink'
import QreviewLogoGreen from '../../assets/icons/Qreview-Logo-Green.svg'

interface QReviewTableCellProps {
  id: string,
  children: JSX.Element | string,
}

interface QReviewTableThumbnailCellProps {
  row: any
}

interface QReviewTableFileSizeCellProps {
  showFileSize: boolean,
  glbSize: string,
  usdzSize: string,
}

export const QReviewTableCell = ({id, children}: QReviewTableCellProps) => {
  return (
    <Box component={QReviewUnstyledLink} sx={{width:"100%", height:"100%", display:"flex", alignItems:"center"}} to={id} key={id}>
      {children}
    </Box>
  )
}

export const QReviewTableThumbnailCell = ({row}: QReviewTableThumbnailCellProps) => {
  return (
    <Box component={QReviewUnstyledLink} sx={{width:"100%", height:"100%", display:"flex", justifyContent:"center"}} to={row._id} key={row._id}>
      <object height={"56px"} width={"auto"} data={row.thumbnail_file ? row.thumbnail_file[0] : QreviewLogoGreen}>
        <img height={"48px"} width={"auto"} src={QreviewLogoGreen} alt='default-qreal-logo'/>
      </object>
    </Box>
  )
}

export const QReviewTableFileSizeCell = ({showFileSize, glbSize, usdzSize}: QReviewTableFileSizeCellProps) => {
  return (
    <Box>
      {showFileSize ? 
      <Stack>
        <Typography variant='qrealBodyMedium'>GLB: {glbSize}</Typography>
        <Typography variant='qrealBodyMedium'>USDZ: {usdzSize}</Typography>
      </Stack>
      :
      <Typography variant='qrealBodyMedium'>Deactivated</Typography>
      }
    </Box>
  )
}