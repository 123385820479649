import { Grid, Button, TextField, Select, MenuItem, Typography } from "@mui/material"
import AddIcon from "@mui/icons-material/Add"
import React, { useState } from "react"
import CustomDropzone from "../../../CustomDropzone"
import { ArrowDownIconBlack } from "../../../../assets/icons"
import { createEnvironment, uploadFile } from "../../../../services/QMMService"
import { Environment } from "../../../../types/QMMTypes"
import { availableStatuses } from "../../../../enums/QMMConstants"

const AddNewEnvironmentCard = ({ callback }) => {
    const [saveButtonState, setSaveButtonState] = useState<boolean>(false)
    const [addNewButtonState, setAddNewButtonState] = useState<boolean>(false)
    const [hdrFile, setHdrFile] = useState<File | null>(null)
    const [newEnvironment, setNewEnvironment] = useState<Environment>({
        name: "",
        status: "ACTIVE",
        hdr_file: {
            name: "",
            type: "application/octet-stream",
            size: ""
        }
    })
    const handleSave = () => {
        if (!hdrFile) {
            return
        }

        setSaveButtonState(true)

        newEnvironment.hdr_file.name = hdrFile[0].name
        newEnvironment.hdr_file.size = hdrFile[0].size.toString()

        createEnvironment(newEnvironment).then((response) => {
            uploadFile(hdrFile[0], response.data.hdr_file.pre_signed_url, "application/octet-stream").then((res) => {
                callback(response.data)
                setAddNewButtonState(false)
                setSaveButtonState(false)
            }).catch((err) => {
                console.log(err)
            })
        }).catch((err) => {
            console.log(err)
        })
    }

    return (
        <>
            {addNewButtonState ?
                <>
                    <Grid container pt={3} spacing={2}>
                        <Grid item xs={12}>
                            <Typography variant={"h5"}>
                                Add New Environment
                            </Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                label="Environment Name"
                                value={newEnvironment.name}
                                fullWidth={true}
                                onChange={(e) => setNewEnvironment({ ...newEnvironment, name: e.target.value })}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <Select
                                size="medium"
                                fullWidth
                                id="qmm-environment-model-status-select"
                                label="Status"
                                value={newEnvironment.status}
                                onChange={(e) => setNewEnvironment({ ...newEnvironment, status: e.target.value })}
                                IconComponent={ArrowDownIconBlack}
                            >
                                <MenuItem value="" disabled>Status</MenuItem>
                                {availableStatuses.map((status) => (
                                    <MenuItem key={"qmm_environment_status" + status.value}
                                              value={status.value}>{status.label}</MenuItem>
                                ))}
                            </Select>
                        </Grid>

                        <Grid item xs={12}>
                            <CustomDropzone
                                setFile={setHdrFile}
                                fileName="Environment HDR"
                                isMultiple={false}
                                accept={{ "application/octet-stream": [".hdr"] }}
                                maxFiles={1}
                            />
                        </Grid>
                        <Grid item container justifyContent="end">
                            <Grid item xs={2} mr={1}>
                                <Button
                                    variant="contained"
                                    onClick={() => setAddNewButtonState(false)}
                                    style={{ backgroundColor: "#FF5876" }}
                                    color={"info"}
                                    disabled={saveButtonState}
                                >
                                    Cancel
                                </Button>
                            </Grid>
                            <Grid item xs={2} ml={2}>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={handleSave}
                                    disabled={saveButtonState}
                                >
                                    Save
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>

                </>
                : <>
                    <Button variant="outlined"
                            onClick={() => setAddNewButtonState(true)}
                    >
                        <AddIcon color={"warning"} /> Add New
                    </Button>
                </>
            }
        </>
    )
}

export default AddNewEnvironmentCard