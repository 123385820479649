import "@google/model-viewer/dist/model-viewer"
import { useEffect } from 'react'

const CustomModelViewer = ({
                               glbUrl,
                               usdzUrl,
                               hdrUrl,
                               shadowIntensity,
                               shadowSoftness,
                               exposure,
                               autoRotate,
                               autoRotateDelay,
                               directAR,
                               mvRef,
                               mvContentRef,
                               cameraOrbit,
                               minCameraOrbit,
                               minFOV,
                               posterUrl,
                               interactionPrompt,
                               interactionPromptThreshold,
                               disablePan
                           }) => {

        useEffect(() => {
            const modelViewer = document.querySelector('model-viewer');
            const progress = document.querySelector('#progress');
            const bar = progress.querySelector('.bar');
            modelViewer.addEventListener('progress', (event) => {
                const { totalProgress } = event.detail;
                progress.classList.toggle('show', totalProgress < 1);
                bar.style.transform = `scaleX(${totalProgress})`;
                if (modelViewer.modelIsVisible) {
                modelViewer.dismissPoster();
                } else {
                modelViewer.showPoster();
                }
            });
            }, [])
    return (
        <div ref={mvContentRef} style={{width:"100%",height:"100%"}}>
            <model-viewer 
                tone-mapping="commerce"
                ref={mvRef}
                style={{width:"100%",height:"100%", background:"#f5f5f5"}}
                src={glbUrl}
                ios-src={usdzUrl}
                poster={posterUrl}
                autoplay
                //${directAR ? 'ar ar-modes="webxr scene-viewer quick-look"' : ""} 
                camera-controls environment-image={hdrUrl}
                shadow-intensity={shadowIntensity} 
                shadow-softness={shadowSoftness} 
                exposure={exposure}
                auto-rotate={autoRotate ? true : undefined}
                auto-rotate-delay={autoRotate ? autoRotateDelay : undefined}
                camera-orbit={cameraOrbit}
                min-camera-orbit={minCameraOrbit}
                min-field-of-view={minFOV + "deg"}
                interaction-prompt={"none"}
                // interaction-prompt-threshold = {interactionPrompt ? interactionPromptThreshold : undefined}
                disable-pan = {disablePan ? true : undefined}
            >
                <div id="progress" slot="progress-bar">
                    <div className="bar"></div>
                </div>
            </model-viewer>
        </div>
    )
}

export default CustomModelViewer
