export const availableCategories = [
    { value: "SHOES", label: "Shoes" },
    { value: "HATS", label: "Hats" },
    { value: "WATCHES", label: "Watches" },
    { value: "EYEWEAR", label: "Eye wear" },
    { value: "OTHER", label: "Other" }
]

export const availableStatuses = [
    { value: "ACTIVE", label: "Active" },
    { value: "PASSIVE", label: "Passive" }
]