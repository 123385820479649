import { Box, Container, Stack, Typography } from '@mui/material';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Stepper from '@mui/material/Stepper';
import { useEffect, useState } from 'react';
import { getOrganizations } from '../../functions/gerOrganizations';
import { getBrands } from '../../functions/getBrands';
import { getHdrList } from '../../functions/getEnvironments';
import { Option } from '../../types/option';
import ModelFileUploadStep from './ModelFileUploadStep';
import ModelInfoStep from './ModelInfoStep';
import ModelUploadStatusStep from './ModelUploadStatusStep';

const steps = ["Model Info", "Model Files", "Upload Status"]

const ModelCreationContainer = () => {
  const [activeStep, setActiveStep] = useState(0);
  const [brandList, setBrandList] = useState<Array<any>>([]);
  const [organizationList, setOrganizationList] = useState<Array<any>>([]);
  const [environmentList, setEnvironmentList] = useState<Array<any>>([]);

  useEffect(() => {
        if (brandList.length === 0) {
            const tempArray: Option[] = []
            getBrands().then((response) => {
                response.data.data.forEach((brand,index) => {
                    tempArray[index] = {value: brand._id, label: brand.brand_name}
                })
                setBrandList(tempArray)
            })
        }

        if (organizationList.length === 0) {
            const tempArray: Option[] = []
            getOrganizations().then((response) => {
                response.data.data.forEach((org,index) => {
                    tempArray[index] = {value: org._id, label: org.organization_name}
                })
                setOrganizationList(tempArray)
            })
        }
        if (environmentList.length === 0) {
            const tempArray: Option[] = []
            getHdrList().then((response) => {
                response.data.data.forEach((env,index) => {
                    tempArray[index] = {value: env._id, label: env.environment_name}
                })
                setEnvironmentList(tempArray)
            })
        }
    }, [])

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  return (
    <Container maxWidth="md">
        <Box m={3}>
            <Stack spacing={3}>
                <Box>
                    <Typography align='center' variant='qrealH3'>Model Creation Wizard</Typography>
                </Box>
                <Stepper activeStep={activeStep}>
                    {steps.map((label, index) => (
                        <Step key={label}>
                            <StepLabel>{label}</StepLabel>
                        </Step>
                    ))}
                </Stepper>
                <Box>
                {activeStep === 0 && (
                    <ModelInfoStep brands={brandList} handleNext={handleNext} organizations={organizationList}/>
                )}
                {activeStep === 1 && (
                    <ModelFileUploadStep handleBack={handleBack} handleNext={handleNext} environments={environmentList}/>
                )}
                {activeStep === 2 && (
                    <ModelUploadStatusStep/>
                )}
                </Box>
            </Stack>
        </Box>
    </Container>
  )
}

export default ModelCreationContainer