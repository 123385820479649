import React from 'react'
import moment from "moment";
import { Tooltip, Typography } from '@mui/material';

interface DateDisplayerProps{
    date: string,
}

const DateDisplayer = (props: DateDisplayerProps) => {
  return (
    <Tooltip title={moment(props.date).format('MMMM Do YYYY, h:mm:ss a')} placement='top-end'>
        <Typography variant='qrealBodyMedium'>{moment(props.date).fromNow()}</Typography>
    </Tooltip>
  )
}

export default DateDisplayer