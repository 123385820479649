import { ProfileButtonProps } from './ProfileButtonProps.config'
import { useState } from 'react';
import { useNavigate } from 'react-router-dom'
import { UserRoles } from '../../types/user';
import { useAuth } from 'qreal-auth-provider/src';
import { Box, Button,Menu, MenuItem, Stack } from '@mui/material';
import { useThemeContext } from "../../theme/ThemeContextProvider";
import DownIcon from "../../assets/icons/Down-Icon.svg"

const ProfileButton = (props: ProfileButtonProps) => {
  const { mode, toggleColorMode } = useThemeContext();
  const navigate = useNavigate();
  const { user } = useAuth();
  const [contentRef, setContentRef] = useState<any>(null);
  const onProfileClick = () => {
    if (document) {
      const msg = { "token": localStorage.getItem('authToken'), "type": 'mopu' };
      var win = contentRef.contentWindow;
      win.postMessage(msg, `${process.env.REACT_APP_IDENTITY_URL}sign-in`);
      setTimeout(() => {
        window.open(`${process.env.REACT_APP_IDENTITY_URL}user/profile-update`, "_blank")
      }, 250)
    }
  }

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const onLogOutClick = () => {
    props.onLogout();
  }
  return (
    <>
      <Button 
        variant='contained' 
        id="profile-button"
        aria-controls={open ? 'profile-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}>
        <Stack direction={"row"} spacing={0.5}>
          <Box>{props.user.first_name}</Box>
          <Box>
            <img src={DownIcon} alt='expand'></img>
          </Box>
        </Stack>
      </Button>
      <Menu
        id="profile-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <MenuItem onClick={onProfileClick}>Profile</MenuItem>
        {user.role !== UserRoles.CUSTOMER && <MenuItem onClick={() => {
            navigate('/subscriptions')
          }}>Report Subscriptions</MenuItem>}
        <MenuItem onClick={toggleColorMode}>{mode[0].toUpperCase() + mode.slice(1)} mode</MenuItem>
        <MenuItem onClick={onLogOutClick}>Logout</MenuItem>
      </Menu>
      <iframe ref={setContentRef} style={{ display: 'none' }} src={`${process.env.REACT_APP_IDENTITY_URL}sign-in`} id="ifr"></iframe>
    </>
  )
}

export default ProfileButton