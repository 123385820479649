import { useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import { checkAuth } from '../enums/authKeys';
import { useAuth } from 'qreal-auth-provider/src';
import { setCookie } from '../functions/cookieHelper';

export const ProtectedRoute = ({ children, pagePermissionKey = '' }) => {
    const { token, isPermissionLoaded, authList } = useAuth();

    if (!token) {
        setCookie('redirectUrl', window.location.pathname, 0.2);
        return <Navigate to="/sign-in" replace />;
    }

    else if (!isPermissionLoaded) return null;

    // Check if the page has permission key and if the user has that permission
    else if (pagePermissionKey && !checkAuth(authList, pagePermissionKey)) {
        return <Navigate to="/" replace />;
    }

    return children;
};