import React from "react"
import DateDisplayer from "./shared/DateDisplayer"
import { Avatar, Box, Card, CardContent, CardHeader, Stack, Typography } from "@mui/material"
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import ScheduleIcon from '@mui/icons-material/Schedule';

function stringToColor(string) {
    let hash = 0;
    let i;

    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
        hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = '#';

    for (i = 0; i < 3; i += 1) {
        const value = (hash >> (i * 8)) & 0xff;
        color += `00${value.toString(16)}`.slice(-2);
    }
    /* eslint-enable no-bitwise */

    return color;
}

function stringAvatar(name) {
    return {
        sx: {
            bgcolor: stringToColor(name),
        },
        children: `${name.split(' ')[0][0]}${name.split(' ')[1][0].toUpperCase()}`,
    };
}

type Props = {
    comments: Array<Comment>
}

type Comment = {
    user_name: String
    comment: String
    model_id: String
    created_at: Date
}

const SplitScreenComments: React.FC<Props> = ({ comments }) => {
    if (comments.length == 0) {
        return (
            <Typography component="h6">
                There are no comments right now!
            </Typography>
        )
    } else {
        return (
            <Stack spacing={3}>
                {
                    comments.map((comment, i) => {
                        // Return the element. Also pass key
                        return (
                            <Stack spacing={1}>
                                <Stack direction={"row"} alignItems={"center"} spacing={2}>
                                    <Box display={"flex"} gap={1} alignItems={"center"}>
                                        <Avatar {...stringAvatar(comment.user_name)}></Avatar>
                                        <Typography variant="qrealBody">{comment.user_name}</Typography>
                                    </Box>
                                    <Box display={"flex"} gap={1} alignItems={"center"}>
                                        <FiberManualRecordIcon sx={{ fontSize: 6 }} />
                                        <DateDisplayer date={comment.created_at.toString()} />
                                    </Box>
                                </Stack>
                                <div style={{ display: 'grid', marginLeft:5 }} dangerouslySetInnerHTML={
                                    { __html: comment.comment.toString() }
                                }></div>
                            </Stack>
                        )
                    })
                }
            </Stack>
        )
    }

}

export default SplitScreenComments
