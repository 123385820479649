import * as React from "react"
import Skeleton from "@mui/material/Skeleton"
import Stack from "@mui/material/Stack"


export default function QmmCardLoading() {
    return (
        <Stack>
            <Skeleton variant="text" height={300} />
        </Stack>
    )
}