import { PaletteMode, createTheme } from "@mui/material";

declare module '@mui/material/styles' {
    interface TypographyVariants {
        qrealH1: React.CSSProperties;
        qrealH2: React.CSSProperties;
        qrealH3: React.CSSProperties;
        qrealSubtitle: React.CSSProperties;
        qrealSubtitleCondensed: React.CSSProperties;
        qrealCaptionText: React.CSSProperties;
        qrealTitle: React.CSSProperties;
        qrealBody: React.CSSProperties;
        qrealBodyBold: React.CSSProperties;
        qrealBodyMedium: React.CSSProperties;
        qrealBodyCondRegular: React.CSSProperties;
        qrealInfoPill: React.CSSProperties;
    }
    interface TypographyVariantsOptions {
        qrealH1?: React.CSSProperties;
        qrealH2?: React.CSSProperties;
        qrealH3?: React.CSSProperties;
        qrealSubtitle?: React.CSSProperties;
        qrealSubtitleCondensed?: React.CSSProperties;
        qrealCaptionText?: React.CSSProperties;
        qrealTitle?: React.CSSProperties;
        qrealBody?: React.CSSProperties;
        qrealBodyBold?: React.CSSProperties;
        qrealBodyMedium?: React.CSSProperties;
        qrealBodyCondRegular?: React.CSSProperties;
        qrealInfoPill?: React.CSSProperties;
    }
}

declare module '@mui/material/Typography' {
    interface TypographyPropsVariantOverrides {
        qrealH1: true;
        qrealH2: true;
        qrealH3: true;
        qrealSubtitle: true;
        qrealSubtitleCondensed: true;
        qrealCaptionText: true;
        qrealTitle: true;
        qrealBody: true;
        qrealBodyBold: true;
        qrealBodyMedium: true,
        qrealBodyCondRegular: true,
        qrealInfoPill: true;
    }
}

const theme = createTheme({
    components: {
        MuiTypography: {
            defaultProps: {
                variantMapping: {
                    qrealH1: "h1",
                    qrealH2: "h2",
                    qrealH3: "h3",
                    qrealSubtitle: "h4",
                    qrealSubtitleCondensed: "h4",
                    qrealCaptionText: "p",
                    qrealTitle: "h6",
                    qrealBody: "p",
                    qrealBodyBold: "p",
                    qrealBodyMedium: "p",
                    qrealBodyCondRegular: "p",
                    qrealInfoPill: "p",
                }
            }
        },
    },
    typography: {
        button: {
            textTransform: "none",
            fontFamily: "Crystal"
        },
        qrealH1: {
            fontFamily: 'Crystal-Regular',
            fontSize: "64px",
        },
        qrealH2: {
            fontFamily: 'Crystal-Regular',
            fontSize: "40px",
        },
        qrealH3: {
            fontFamily: 'Crystal-Regular',
            fontSize: "24px",
        },
        qrealSubtitle: {
            fontFamily: 'Crystal-Condensed-Regular',
            fontSize: "24px",
        },
        qrealSubtitleCondensed: {
            fontFamily: 'Crystal-Regular',
            fontSize: "24px",
        },
        qrealCaptionText: {
            fontFamily: 'Crystal-Regular',
            fontSize: "20px",
        },
        qrealTitle: {
            fontFamily: 'Crystal-Condensed-Regular',
            fontSize: "18px",
        },
        qrealBody: {
            fontFamily: 'Crystal-Regular',
            fontSize: "16px",
        },
        qrealBodyBold: {
            fontFamily: 'Crystal-Regular',
            fontSize: "16px",
        },
        qrealBodyMedium: {
            fontFamily: 'Crystal-Regular',
            fontSize: "14px",
        },
        qrealBodyCondRegular: {
            fontFamily: 'Crystal-Regular',
            fontSize: "14px",
        },
        qrealInfoPill: {
            fontFamily: 'Crystal-Regular',
            fontSize: "12px",
        }

    },
    shape: {
        borderRadius: 24,
    }
})

export const getDesignTokens = (mode: PaletteMode) => ({
  palette: {
    mode,
    ...(mode === "light"
      ? {
          // palette values for light mode
          primary: {
            main: "#5EEEA9"
          },
          divider: "#000000",
          text: {
            primary: "#272527",
            secondary: "#585860",
          },
          background: {
            default: "#ECECED",
            paper: "#C9C9CB",
          },
          error: {
            main: "#FF5876"
          },
          success: {
            main: "#5EEEA9"
          },
          warning: {
            main: "#FFB545"
          },
          info: {
            main: "#78C6FF"
          }
        }
      : {
          // palette values for dark mode
          primary: {
            main: "#5EEEA9"
          },
          divider: "#FFFFFF",
          background: {
            default: "#3A373C",
            paper: "#585860",
          },
          text: {
            primary: "#ffffff",
            secondary: "#C9C9CB",
          },
          error: {
            main: "#FF5876"
          },
          success: {
            main: "#5EEEA9"
          },
          warning: {
            main: "#FFB545"
          },
          info: {
            main: "#78C6FF"
          }
        }),
  },
  components: {
    MuiTypography: {
        defaultProps: {
            variantMapping: {
                qrealH1: "h1",
                qrealH2: "h2",
                qrealH3: "h3",
                qrealSubtitle: "h4",
                qrealSubtitleCondensed: "h4",
                qrealCaptionText: "p",
                qrealTitle: "h6",
                qrealBody: "p",
                qrealBodyBold: "p",
                qrealBodyMedium: "p",
                qrealBodyCondRegular: "p",
                qrealInfoPill: "p",
            }
        }
    },
    MuiButton: {
        styleOverrides: {
            root: {
                borderRadius: "24px",
                boxShadow: "none",
                paddingTop: 8,
                paddingBottom: 8,
                paddingRight: 20,
                paddingLeft: 20,
                fontSize: 16,
                height: 42,
                color: "black",
                backgroundColor: "#5EEEA9",
                "&:focus, &:hover, &:visited, &:link, &:active": {
                    backgroundColor: "#5EEEA9"
                },
                "&:disabled":{
                    backgroundColor: mode === "light" ? "#C9C9CB" : "#585860",
                    color: mode === "light" ? "black" : "white"
                }
            },
        },
    },
    MuiOutlinedInput: {
        styleOverrides: {
            root: {
                backgroundColor: mode === "light" ? "#C9C9CB" : "#585860"
            }
        }
    },
    MuiTableCell: {
        styleOverrides: {
            root: {
                borderBottom: "none"
            }
        }
    },
    MuiTableRow: {
        styleOverrides: {
            root: {
                backgroundColor: mode === "light" ? "#C9C9CB" : "#585860"
            }
        }
    },
    MuiAvatar: {
        styleOverrides: {
            root: {
                color: "#ECECED"
            }
        }
    }
  },
  typography: {
    button: {
        textTransform: "none" as const,
        fontFamily: 'Crystal',
        lineHeight: "normal"
    },
    qrealH1: {
        fontFamily: 'Crystal',
        fontSize: "64px",
    },
    qrealH2: {
        fontFamily: 'Crystal',
        fontSize: "40px",
    },
    qrealH3: {
        fontFamily: 'Crystal',
        fontSize: "24px",
    },
    qrealSubtitle: {
        fontFamily: 'Crystal-Condensed-Regular',
        fontSize: "24px",
        fontWeight: 400,
    },
    qrealSubtitleCondensed: {
        fontFamily: 'Crystal',
        fontSize: "24px",
    },
    qrealCaptionText: {
        fontFamily: 'Crystal',
        fontSize: "20px",
    },
    qrealTitle: {
        fontFamily: 'Crystal-Condensed-Regular',
        fontSize: "18px",
    },
    qrealBody: {
        fontFamily: 'Crystal',
        fontSize: "16px",
    },
    qrealBodyBold: {
        fontFamily: 'Crystal',
        fontSize: "16px",
    },
    qrealBodyMedium: {
        fontFamily: 'Crystal',
        fontSize: "14px",
    },
    qrealBodyCondRegular: {
        fontFamily: 'Crystal-Condensed-Regular',
        fontSize: "14px",
    },
    qrealInfoPill: {
        fontFamily: 'Crystal',
        fontSize: "12px",
    }
  },
  shape: {
      borderRadius: 24,
  }
});

export default theme;