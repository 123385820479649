import { Autocomplete, Box, Button, FormControl, FormHelperText, InputLabel, MenuItem, Select, SelectChangeEvent, Stack, TextField } from '@mui/material';
import { useFormik } from 'formik';
import React, { useEffect } from 'react';
import { object, string } from 'yup';
import { defaultTRYOCameraOrbit } from "../../enums/constants";
import { ModelCategories } from '../../enums/modelCategories';
import { useCreateModel } from '../../providers/CreateModelProvider';
import { Option } from '../../types/option';
import { useCreateReferrer } from '../../providers/CreateReferrerProvider';

const validationSchema = object({
    model_name: string().required('Model Name is required'),
    reference_link: string().required('Reference Link is required'),
    category: string().required('Please Choose a Category'),
    brand: string().required('Please Choose a Brand'),
    authorized_organizations: string().required('Please Choose Authorized Organizations'),
});

interface ModelInfoStepProps {
    handleNext: () => void
    brands: Option[]
    organizations: Option[]
}

const ModelInfoStep = ({ handleNext, brands, organizations }: ModelInfoStepProps) => {
    //const [value, setValue] = React.useState<string | null>(brands[0]);
    const [inputValue, setInputValue] = React.useState('');
    const {
        modelName,
        setModelName,
        modelReferenceLink,
        setModelReferenceLink,
        modelIframeLink,
        setModelIframeLink,
        category,
        setCategory,
        brand,
        setBrand,
        authorizedOrganizations,
        setAuthorizedOrganizations,
        setCameraOrbit,
        referrerExternalId,
        setReferrerExternalId,
    } = useCreateModel()

    const formik = useFormik({
        initialValues: {
            model_name: modelName,
            reference_link: modelReferenceLink,
            iframe_link: modelIframeLink,
            category: category,
            brand: brand,
            authorized_organizations: authorizedOrganizations,
            external_id: ''
        },
        validationSchema: validationSchema,
        onSubmit: (values) => {
            handleNext()
        },
    });

    useEffect(() => {

        if (category === ModelCategories.EYEWEAR || category === ModelCategories.HATS || category === ModelCategories.SHOES) {
            setCameraOrbit(defaultTRYOCameraOrbit)
        } else {
            setCameraOrbit("auto auto auto")
        }
    }, [category])

    const handleExternalId = (value:string) => {
        value.toLowerCase().includes('flo_')?setReferrerExternalId(value.toLowerCase().split('flo_')[1]):setReferrerExternalId('');
    }

    return (
        <form onSubmit={formik.handleSubmit}>
            <Stack spacing={2}>

                <TextField
                    size="small"
                    fullWidth
                    id="model_name"
                    name='model_name'
                    label="Model Name"
                    value={formik.values.model_name}
                    onChange={(e: React.ChangeEvent<any>) => { formik.handleChange(e); setModelName(e.target.value); handleExternalId(e.target.value) }}
                    onBlur={formik.handleBlur}
                    error={formik.touched.model_name && Boolean(formik.errors.model_name)}
                    helperText={formik.touched.model_name && formik.errors.model_name}
                />

                <TextField
                    size="small"
                    id="reference_link"
                    name='reference_link'
                    label="Reference Link"
                    value={formik.values.reference_link}
                    onChange={(e: React.ChangeEvent<any>) => { formik.handleChange(e); setModelReferenceLink(e.target.value) }}
                    onBlur={formik.handleBlur}
                    error={formik.touched.reference_link && Boolean(formik.errors.reference_link)}
                    helperText={formik.touched.reference_link && formik.errors.reference_link}
                />

                <TextField
                    size="small"
                    id="iframe_link"
                    name='iframe_link'
                    label="IFrame Link"
                    value={formik.values.iframe_link}
                    onChange={(e: React.ChangeEvent<any>) => { formik.handleChange(e); setModelIframeLink(e.target.value) }}
                    onBlur={formik.handleBlur}
                    error={formik.touched.iframe_link && Boolean(formik.errors.iframe_link)}
                    helperText={formik.touched.iframe_link && formik.errors.iframe_link}
                />

                <FormControl error={formik.touched.category && Boolean(formik.errors.category)}>
                    <InputLabel id="category-select-label">Category</InputLabel>
                    <Select
                        size='small'
                        id='category'
                        labelId='category-select-label'
                        name='category'
                        value={formik.values.category}
                        onChange={(e: SelectChangeEvent<string>) => { formik.handleChange(e); setCategory(e.target.value) }}
                        onBlur={formik.handleBlur}
                        displayEmpty
                    >
                        <MenuItem value="" disabled></MenuItem>
                        {Object.entries(ModelCategories).map((category, index) => (
                            <MenuItem key={'category-' + index} value={category[1]}>{category[0]}</MenuItem>
                        ))
                        }
                    </Select>
                    {(formik.touched.category && formik.errors.category) && <FormHelperText>{formik.errors.category}</FormHelperText>}
                </FormControl>

                <FormControl error={formik.touched.brand && Boolean(formik.errors.brand)}>
                    <Autocomplete
                        loading={brands.length > 0}
                        size='small'
                        id='brand'
                        options={brands}
                        disableClearable
                        value={brands.find((brand) => brand.value === formik.values.brand)}
                        onChange={(e, selected) => { formik.setFieldValue("brand", selected.value); setBrand(selected.value) }}
                        onBlur={formik.handleBlur}
                        renderInput={(params) => <TextField {...params} label="Brand" error={formik.touched.brand && Boolean(formik.errors.brand)} />}
                    />
                    {(formik.touched.brand && formik.errors.brand) && <FormHelperText>{formik.errors.brand}</FormHelperText>}
                </FormControl>

                <FormControl error={formik.touched.authorized_organizations && Boolean(formik.errors.authorized_organizations)}>
                    <Autocomplete
                        loading={organizations.length > 0}
                        size='small'
                        id='organization'
                        options={organizations}
                        disableClearable
                        value={organizations.find((organization) => organization.value === formik.values.authorized_organizations)}
                        onChange={(e, selected) => { formik.setFieldValue("authorized_organizations", selected.value); setAuthorizedOrganizations(selected.value) }}
                        onBlur={formik.handleBlur}
                        renderInput={(params) => <TextField {...params} label="Organization" error={formik.touched.authorized_organizations && Boolean(formik.errors.authorized_organizations)} />}
                    />
                    {(formik.touched.authorized_organizations && formik.errors.authorized_organizations) && <FormHelperText>{formik.errors.authorized_organizations}</FormHelperText>}
                </FormControl>
                <TextField
                    size="small"
                    fullWidth
                    id="external_id"
                    name='external_id'
                    label="External Id"
                    value={referrerExternalId}
                    onChange={(e: React.ChangeEvent<any>) => { formik.handleChange(e); setReferrerExternalId(e.target.value)}}
                    onBlur={formik.handleBlur}
                    error={formik.touched.external_id && Boolean(formik.errors.external_id)}
                    helperText={formik.touched.external_id && formik.errors.external_id}
                />

                <Box display={"flex"} flexDirection={"row"} justifyContent={"flex-end"}>
                    <Button type="submit">Next</Button>
                </Box>
            </Stack>
        </form>
    )
}

export default ModelInfoStep