import React from 'react'
import {Stack, Box} from '@mui/material'
import { QReviewUnstyledLink } from '../shared/QReviewUnstyledLink'
import QReviewActionButton from '../QReviewButton/QReviewActionButton'
import { useAuth } from 'qreal-auth-provider/src'
import { useThemeContext } from '../../theme/ThemeContextProvider'
import { authKeys, checkAuth } from '../../enums/authKeys';
import EyeIconWhite from '../../assets/icons/Eye-Icon-White.svg'
import TrashIconWhite from '../../assets/icons/Trash-Icon-White.svg'
import ShareIconWhite from '../../assets/icons/Share-Icon-White.svg'
import EyeIconBlack from '../../assets/icons/Eye-Icon-Black.svg'
import TrashIconBlack from '../../assets/icons/Trash-Icon-Black.svg'
import ShareIconBlack from '../../assets/icons/Share-Icon-Black.svg'
import { useApp } from '../../providers/AppProvider'

interface QReviewModelTableActionButtonsProps {
  model: any
}

const QReviewModelTableActionButtons = ({model}: QReviewModelTableActionButtonsProps) => {
  const {
    setIsDeleteModelConfirmationModalOpen,
    setSelectedModelId,
} = useApp()
  const { authList } = useAuth();
  const { mode } = useThemeContext();
  return (
    <Stack direction={"row"} spacing={2}>
        <Box component={QReviewUnstyledLink} to={model._id}>
            <QReviewActionButton icon={mode === "light" ? EyeIconBlack : EyeIconWhite} tooltip='View Model on Split Screen'/>
        </Box>
        {checkAuth(authList, authKeys.delete_model) &&
            <Box onClick={() => {
              setIsDeleteModelConfirmationModalOpen(true)
              setSelectedModelId(model._id)
            }}>
            <QReviewActionButton icon={mode === "light" ? TrashIconBlack : TrashIconWhite} tooltip='Delete Model'/>
            </Box>
        }
        <Box component={QReviewUnstyledLink} to={`/model/referrers/${model._id}`}>
            <QReviewActionButton icon={mode === "light" ? ShareIconBlack : ShareIconWhite} tooltip='Go to Referrer'/>
        </Box>
    </Stack>
  )
}

export default QReviewModelTableActionButtons