import { Box, Stack, Tab, Tabs, Typography } from "@mui/material"
import { useAuth } from "qreal-auth-provider/src"
import { useState } from "react"
import { authKeys, checkAuth } from "../../enums/authKeys"
import OrganizationTable from "../OrganizationTable"
import SplitScreenComments from "../SplitScreenComments"
import SplitScreenCreateComment from "../SplitScreenCreateComment"
import ServiceSharing from "./serviceSharing/serviceSharing"

const ModelBottomArea = ({ comments, modelId, authorizedOrganizations, refetchCallback, qaStatus, currentModel }) => {
    const { authList } = useAuth()
    const [tab, setTab] = useState("comments")

    const handleChange = (event, newValue) => {
        setTab(newValue)
    }

    return (
        <>
            <Box sx={{ borderBottom: 0.1, borderColor: "#585860" }}>
                <Tabs
                    TabIndicatorProps={{
                        sx: {
                            bgcolor: "#5EEEA9",
                            height: "1px"
                        }
                    }}
                    value={tab}
                    onChange={handleChange}
                >
                    <Tab value="comments" label="Comments" />
                    <Tab value="manage" label="Manage" />
                    {checkAuth(authList, authKeys.service_share) && <Tab value="share" label="Service Sharing" />}
                </Tabs>
            </Box>
            {

                {
                    "comments":
                        <>
                            {checkAuth(authList, authKeys.create_comment) &&
                                <>
                                    <Stack spacing={2} sx={{ marginTop: "12px" }}>
                                        <Typography variant="h5">Post a new comment</Typography>
                                        <SplitScreenCreateComment modelId={modelId} />
                                    </Stack>
                                </>
                            }
                            {checkAuth(authList, authKeys.list_comment) && <div className="row mt-2">
                                <div className="container">
                                    <SplitScreenComments comments={comments} />
                                </div>
                            </div>}
                        </>,
                    "manage":
                        <>
                            <OrganizationTable
                                modelId={modelId}
                                refetch={refetchCallback}
                                selectedList={authorizedOrganizations}
                            />
                        </>,
                    "share":
                        <>
                            <ServiceSharing
                                currentModel={currentModel}
                            />
                        </>
                }[tab]
            }
        </>
    )
}

export default ModelBottomArea