export const authKeys = {
    share_model: "MOPU_SHARE_MODEL",
    create_model: "MOPU_CREATE_MODEL",
    delete_model: "MOPU_DELETE_MODEL",
    edit_model: "MOPU_UPDATE_MODEL",
    list_model: "MOPU_LIST_MODEL",
    search_model: "MOPU_SEARCH_MODEL",
    create_brand: "MOPU_CREATE_BRAND",
    edit_brand: "MOPU_UPDATE_BRAND",
    delete_brand: "MOPU_DELETE_BRAND",
    list_brand: "MOPU_LIST_BRAND",
    create_comment: "MOPU_CREATE_COMMENT",
    list_comment: "MOPU_LIST_COMMENT",
    delete_comment: "MOPU_DELETE_COMMENT",
    create_environment: "MOPU_CREATE_ENVIRONMENT",
    list_environment: "MOPU_LIST_ENVIRONMENT",
    get_environment: "MOPU_GET_ENVIRONMENT",
    edit_environment: "MOPU_UPDATE_ENVIRONMENT",
    delete_environment: "MOPU_DELETE_ENVIRONMENT",
    delete_referrer: "MOPU_DELETE_REFERRER",
    create_referrer: "MOPU_CREATE_REFERRER",
    update_referrer: "MOPU_UPDATE_REFERRER",
    list_referrer: "MOPU_LIST_REFERRER",
    list_organization: "MOPU_LIST_ORGANIZATION",
    update_organization: "MOPU_ORGANIZATION_UPDATE",
    update_qa_status: "MOPU_UPDATE_QA_STATUS",
    service_share: "MOPU_SERVICE_SHARE",
    download_files: "MOPU_DOWNLOAD"
}

export const checkAuth = (authList: Array<string>, key: string) => {
    if (authList.length > 0 && authList.includes(key)) {
        return true;
    }
    return false;
}
