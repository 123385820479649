import React, { useEffect, useState, useRef } from 'react'
import { useParams } from 'react-router-dom'
import { Model } from "../providers/AppProvider"
import Loading from "../components/template/Loading";
import { getModelByReferrer } from "../functions/getModel";
import QRCode from 'react-qr-code';
import { checkUSDZExistence } from '../functions/axiosHelper';
import { useMixpanel } from 'react-mixpanel-browser';
import { cdnToS3URLChanger } from '../functions/cdnToS3URLChanger';

const DirectAR = () => {

    const { id } = useParams()
    const [isLoading, setIsLoading] = useState(true)
    const [currentModel, setCurrentModel] = useState()
    const [usdzExists, setUsdzExists] = useState(false)
    const iosButtonRef = useRef(null);
    const androidButtonRef = useRef(null);
    const mvRef = useRef(null);
    
    const mixpanel = useMixpanel();

    useEffect(() => {
        if (isLoading) {
            if (id) {
                const model = getModelByReferrer(id).then(res => {
                    let currentModel = res.data.data[0].model
                    checkUSDZExistence(cdnToS3URLChanger(currentModel.usdz_file[0]))
                    .then(data => {
                        setUsdzExists(true)
                        setIsLoading(false)
                    })
                    .catch(error => {
                        if (error.request) {
                            setIsLoading(false)
                        }else if(error){
                            setUsdzExists(true)
                            setIsLoading(false)
                            
                        }
                    });

                    setCurrentModel(currentModel)
                })
            }
        }
    }, [isLoading]) 

    let device = ""
    if (isLoading === false) {
        let userAgent = navigator.userAgent || navigator.vendor || window.opera;
        if (/android/i.test(userAgent)) {
            device = "android";
        } else if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
            device = 'ios';
        } else {
            device = 'desktop';
        }
    }

    useEffect(() => {
        if (device === "android" || device === "ios") {
            const modelViewer = document.querySelector('model-viewer');
            const progress = document.querySelector('#progress');
            const bar = progress.querySelector('.bar');
            modelViewer.addEventListener('progress', (event) => {
                const { totalProgress } = event.detail;
                progress.classList.toggle('show', totalProgress < 1);
                bar.style.transform = `scaleX(${totalProgress})`;
                if (modelViewer.modelIsVisible) {
                modelViewer.dismissPoster();
                } else {
                modelViewer.showPoster();
                }
            });

            setTimeout(() => {
                if (device === "ios") {
                    if(usdzExists){
                        iosButtonRef.current.click();
                    }else{
                        modelViewer.activateAR()
                    }
                } else if (device === 'android') {
                    androidButtonRef.current.click();
                }
            }, 500);

            
        }
    }, [device, usdzExists])

    return (
        <div className='full-page-container centered white-bg'>
            {
                (isLoading || !currentModel) 
                ? 
                <Loading dark={true} />
                :
                <div className='full-page-container centered-col'>
                    {device === "desktop" ?
                    <div className='centered-col desktop-qr-wrapper'>
                    <div className='qr-container'>
                        <QRCode value={`${window.location.href}`} size={128}/>
                    </div>
                    <p style={{marginTop: "1rem", marginBottom:0}}>Scan QR code for AR experience</p>
                    </div>
                    :
                    <>
                    <a
                        
                        ref={androidButtonRef}
                        style={{ "display": "none" }}
                        id="androidButton"
                        href={`intent://arvr.google.com/scene-viewer/1.0?file=${currentModel.glb_file}&mode=ar_only#Intent;scheme=https;package=com.google.ar.core;action=android.intent.action.VIEW;S.browser_fallback_url=${window.location.origin + "/ar/not-supported"};end;`}>
                    </a>
                    <a
                        ref={iosButtonRef}
                        style={{ "display": "none" }}
                        id="iosButton"
                        rel={'ar'}
                        href={currentModel.usdz_file}
                    >
                        <img alt={""} />
                    </a>
                    <model-viewer ref={mvRef}  class="mv-container" tone-mapping="commerce" src={currentModel.glb_file} ios-src={currentModel.usdz_file} camera-controls ar>
                        <div id="progress" slot="progress-bar">
                            <div className="bar"></div>
                        </div>
                    </model-viewer>
                    </>
                    }
                </div>
                
            }
        </div>
    )
}

export default DirectAR