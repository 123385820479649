export const ModelCategories = {
    EYEWEAR: "GLASSES",
    WATCHES: "WATCH",
    SHOES: "SHOE",
    HATS: "HAT",
    OTHER: "OTHER"
}

export const ModelStatusList = {
    PENDING: "Waiting", 
    REVIEW_REQUESTED: "Review Requested", 
    REJECTED: "Rejected", 
    APPROVED: "Approved"
}