import React, { useContext, useEffect, useState } from "react"
import { deleteModel } from "../functions/deleteModel"

interface HDR {
    environment_name: string
    hdr_file: {
        url: string
        pre_signed_url: string
    }
}

export interface Thumbnail {
    is_uploaded: boolean
    url: string
}

export interface Brand {
    brand_name: string
    brand_slug: string
    brand_status: string
    _id: string
    thumbnail_file: Thumbnail[]
}

export type Model = {
    authorized_organizations: string[]
    created_at: string
    updated_at: string
    glb_file: string[]
    glb_size?: string
    hdr_file: string[]
    thumbnail_file: string[]
    thumbnail_size?: string
    hdr_size?: string
    brand: Brand[]
    reference_file: Array<String>
    model_name: string
    reference_link?: string
    iframe_link?: string
    model_slug: string
    qa_status: "PENDING" | "REVIEW_REQUESTED" | "REJECTED" | "APPROVED"
    model_status: string
    model_viewer_config: {
        "auto-rotate": boolean
        "direct-ar": boolean
        exposure: string
        "shadow-intensity": string
        "shadow-softness": string
    }
    environment: HDR[]
    usdz_file: string[]
    usdz_size?: string
    _id: string
    view_count: number
    category: string
    poster_file: string[]
}

type AppContextType = {
    isDeleteModelConfirmationModalOpen: boolean
    setIsDeleteModelConfirmationModalOpen: (e: any) => any
    selectedModelId: string
    setSelectedModelId: (e: any) => any
    deleteModelAsyncMethod: (id: String) => any
    isModelDeletedSuccessfully: boolean
    setIsModelDeletedSuccessfully: (e: any) => any
    deleteModelPending: boolean
}

export const AppContext = React.createContext<AppContextType | null>(null)
export const useApp = () => useContext(AppContext) as AppContextType

type Props = {
    children: JSX.Element
}

const AppProvider: React.FC<Props> = ({ children }) => {
    const [deleteModelPending, setDeleteModelPending] = useState<boolean>(false)
    const [isDeleteModelConfirmationModalOpen, setIsDeleteModelConfirmationModalOpen] = useState<boolean>(false)
    const [selectedModelId, setSelectedModelId] = useState<string>("")
    const [isModelDeletedSuccessfully, setIsModelDeletedSuccessfully] = useState<boolean>(false)

    const deleteModelAsyncMethod = async (id: String) => {
        setDeleteModelPending(true)
        const response = await deleteModel(id)
        if (!response.error && response.data) setIsModelDeletedSuccessfully(true)
        setDeleteModelPending(false)
    }

    // UseEffects
    useEffect(() => {
    }, [isModelDeletedSuccessfully])


    // Binding
    const valueObject = {
        isDeleteModelConfirmationModalOpen,
        setIsDeleteModelConfirmationModalOpen,
        selectedModelId,
        setSelectedModelId,
        deleteModelAsyncMethod,
        isModelDeletedSuccessfully,
        setIsModelDeletedSuccessfully,
        deleteModelPending
    }

    // Render
    return <AppContext.Provider value={valueObject}>{children}</AppContext.Provider>
}

export default AppProvider
