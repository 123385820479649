import { Box, Stack, Typography } from "@mui/material";
import InputBase from '@mui/material/InputBase';
import { styled } from '@mui/material/styles';
import { useState } from "react";
import { useSearchParams } from "react-router-dom";
import SearchIconBlack from "../assets/icons/Search-Icon-Black.svg";
import SearchIconWhite from "../assets/icons/Search-Icon-White.svg";
import SearchIcon from "../assets/icons/Search-Icon.svg";
import { useThemeContext } from "../theme/ThemeContextProvider";
import QReviewIconButton from "./QReviewButton/QReviewIconButton";

const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: theme.palette.background.paper,
  height: '42px',
  width: 490,
}));

const SearchPlaceHolder = styled('div')(({ theme }) => ({
  paddingLeft: 24,
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));


const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  fontFamily: "Crystal",
  height: '100%',
  width: 'calc(100% - 42px)',
  '& .MuiInputBase-input': {
    paddingLeft: 24,
    transition: theme.transitions.create('width'),
    width: 'calc(100% - 36px)',
  },
}));

const SearchInput = ({ onSubmit, defaultValue = '' }: { onSubmit: Function, defaultValue?: string }) => {
  let [searchParams, setSearchParams] = useSearchParams();
  const [searchQuery, setSearchQuery] = useState(searchParams.get('search_query') || "");
  const { mode } = useThemeContext();
  const [isFocused, setIsFocused] = useState(false);

  return (
    <Box sx={{ width: "100%" }}>
      <Search>
        <SearchPlaceHolder sx={{ display: searchQuery ? "none" : "flex" }}>
          <Stack direction={"row"} spacing={1}>
            <img src={mode === "light" ? SearchIconBlack : SearchIconWhite} alt="search-placeholder"></img>
            <Typography color={"text.secondary"} variant="qrealBody">Search</Typography>
          </Stack>
        </SearchPlaceHolder>
        <StyledInputBase
          inputProps={{ 'aria-label': 'search' }}
          defaultValue={defaultValue}
          onChange={(e) => setSearchQuery(e.target.value)}
          onKeyDown={(e) => e.key === 'Enter' && onSubmit(searchQuery)}
          onFocus={() => setIsFocused(true)}
          onBlur={() => setIsFocused(false)}
        />
        <QReviewIconButton sx={{ display: searchQuery || isFocused ? "inline-flex" : "none" }} alt="search-icon" svg={SearchIcon} onClick={() => { onSubmit(searchQuery) }} />
      </Search>
    </Box>
  )
}

export default SearchInput;