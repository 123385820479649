import React, { useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import { Model, useApp } from "../providers/AppProvider"
import CustomModelViewer from "../components/CustomModelViewer"
import Loading from "../components/template/Loading";
import { increaseModelViewCount } from "../functions/updateModel";
import { getModelByReferrer } from "../functions/getModel";
import { Hdr } from "../types/hdr";
import CustomModelViewerReveal from "../components/CustomModelViewerReveal";

import { useMixpanel } from 'react-mixpanel-browser';

const ShareModel = () => {
    // States and Hooks
    const { id } = useParams()
    const [currentModel, setCurrentModel] = useState<Model>()
    const [cameraOrbit, setCameraOrbit] = useState("")
    const [minCameraOrbit, setMinCameraOrbit] = useState("")
    const [minFOV, setMinFOV] = useState("")
    const [environment, setEnvironment] = useState<Hdr>()
    const [exposure, setExposure] = useState(0)
    const [shadowIntensity, setShadowIntensity] = useState(0)
    const [shadowSoftness, setShadowSoftness] = useState(0)
    const [autoRotate, setAutoRotate] = useState(false)
    const [autoRotateDelay, setAutoRotateDelay] = useState(3000)
    const [interactionPrompt, setInteractionPrompt] = useState(true)
    const [interactionPromptThreshold, setInteractionPromptThreshold] = useState(3000)
    const [directAR, setDirectAR] = useState(false)
    const [isLoading, setIsLoading] = useState(true)
    const [disablePan, setDisablePan] = useState(false)

    const mixpanel = useMixpanel();

    useEffect(() => {
        if (isLoading) {
            if (id) {
                const model = getModelByReferrer(id).then(res => {
                    let currentModel = res.data.data[0].model

                    setEnvironment(currentModel.environment[0])
                    setExposure(Number(currentModel.model_viewer_config["exposure"]))
                    setShadowIntensity(Number(currentModel.model_viewer_config["shadow-intensity"]))
                    setShadowSoftness(Number(currentModel.model_viewer_config["shadow-softness"]))
                    setAutoRotate(currentModel.model_viewer_config["auto-rotate"])
                    setAutoRotateDelay(currentModel.model_viewer_config["auto-rotate-delay"] ? Number(currentModel.model_viewer_config["auto-rotate-delay"]) : 3000)
                    setInteractionPrompt(currentModel.model_viewer_config["interaction-prompt"])
                    setInteractionPromptThreshold(currentModel.model_viewer_config["interaction-prompt-threshold"] ? Number(currentModel.model_viewer_config["interaction-prompt-threshold"]) : 3000)
                    setDirectAR(currentModel.model_viewer_config["direct-ar"])
                    setCameraOrbit(currentModel.model_viewer_config["camera-orbit"])
                    setMinCameraOrbit(currentModel.model_viewer_config["min-camera-orbit"])
                    setMinFOV(currentModel.model_viewer_config["min-field-of-view"])
                    setDisablePan(currentModel.model_viewer_config["disable-pan"])
                    setCurrentModel(currentModel)
                    setIsLoading(false)
                })
            }
        }
    }, [isLoading])

    return isLoading || !currentModel ? (
        <div className="d-flex align-items-center justify-content-center flex-column" style={{background:"white", height:"100%"}}>
            <Loading dark={true} />
        </div>
    ) : (
        <div style={{ width: "100%", height: "100%" }}>
            <CustomModelViewerReveal
                glbUrl={`${currentModel?.glb_file[0]}`}
                usdzUrl={currentModel?.usdz_file[0]}
                hdrUrl={currentModel.environment[0] ? currentModel.environment[0].hdr_file[0].url : currentModel?.hdr_file[0]}
                posterUrl={(currentModel.poster_file && currentModel.poster_file[0]) ? currentModel.poster_file[0] : undefined}
                shadowIntensity={shadowIntensity}
                shadowSoftness={shadowSoftness}
                exposure={exposure}
                autoRotate={autoRotate}
                autoRotateDelay={autoRotateDelay}
                directAR={directAR}
                cameraOrbit={cameraOrbit}
                minCameraOrbit={minCameraOrbit}
                mvRef={undefined}
                minFOV={minFOV}
                mvContentRef={undefined}
                interactionPrompt={interactionPrompt}
                interactionPromptThreshold={interactionPromptThreshold}
                disablePan={disablePan} />
        </div>
    )
}

export default ShareModel
