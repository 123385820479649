
const IframeContainer = ({ iframeLink }) => {

    const checkIframeLinkMarkup = () => {
        if (iframeLink && iframeLink.includes('iframe')) {
            return <div className="iframe-container" dangerouslySetInnerHTML={{ __html: iframeLink }} />
        } else {
            return <div className="iframe-container" dangerouslySetInnerHTML={{ __html: `<iframe src="${iframeLink}" width="100%" height="100%" frameborder="0" allowfullscreen></iframe>` }} />
        }
    }

    return (
        checkIframeLinkMarkup()
    )
}

export default IframeContainer