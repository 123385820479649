import CopyIcon from '@mui/icons-material/ContentCopy';
import EditIcon from '@mui/icons-material/Edit';
import DownloadIcon from '@mui/icons-material/FileDownloadOutlined';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import ReplayIcon from '@mui/icons-material/Replay';
import SettingsIcon from '@mui/icons-material/Settings';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import { Autocomplete, Box, Checkbox, FormControlLabel, Grid, Input, Link, Slider, Tab, Tabs, TextField, Tooltip, Typography } from '@mui/material';
import { useRef, useState } from 'react';
import { SSType } from '../../enums/constants';
import { cameraOrbitHelper } from '../../functions/cameraOrbitHelper';
import { editModelViewerConfig } from '../../functions/editModel';
import { generatePoster } from '../../functions/generatePoster';
import { updateModel } from '../../functions/updateModel';
import { uploadPosterFile } from '../../functions/uploadFile';
import { Brand } from '../../providers/AppProvider';
import { useEditModel } from '../../providers/EditModelProvider';
import { Hdr } from '../../types/hdr';
import QReviewBtn from '../QReviewButton/QReviewBtn';
import EditModelConfirmationModal from '../modals/EditModelConfirmationModal';

const cameraOrbitTooltipText = "Sets the starting angle of the model"
const zoomLimitTooltipText = "Sets zoom limit"
const zoomLimitResetTooltipText = "Resets zoom limit"
const cameraOrbitResetTooltipText = "Resets camera orbit"

const ModelSettings = ({
    currentModel,
    mvRef,
    mvContentRef,
    cameraOrbit,
    minCameraOrbit,
    minFOV,
    shadowIntensity,
    shadowSoftness,
    exposure,
    directAR,
    autoRotate,
    setCameraOrbit,
    setMinCameraOrbit,
    setMinFOV,
    setShadowIntensity,
    setShadowSoftness,
    setExposure,
    setDirectAR,
    setAutoRotate,
    environment,
    setEnvironment,
    iframeLink,
    setIframeLink,
    hdrList,
    brandList,
    brand,
    setBrand,
    glbSize,
    glbUrl,
    usdzSize,
    usdzUrl,
    referenceFile,
    hdrSize,
    hdrUrl,
    modelReferenceLink,
    setModelReferenceLink,
    modelReferenceFileCount,
    setModelReferenceFileCount,
    modelName,
    setModelName,
    interactionPrompt,
    setInteractionPrompt,
    interactionPromptThreshold,
    setInteractionPromptThreshold,
    autoRotateDelay,
    setAutoRotateDelay,
    disablePan,
    setDisablePan,


}) => {
    const [tab, setTab] = useState('mvSettings');

    const [modalSaveButton, setModalSaveButton] = useState({
        text: "Save",
        type: "",
    })

    const setCameraOrbitClick = () => {
        if (mvRef.current) {
            setCameraOrbit(mvRef.current.getCameraOrbit().toString())
        }
    }

    const setZoomLimitClick = () => {
        if (mvRef.current) {
            setMinCameraOrbit(`auto auto ${mvRef.current.getCameraOrbit().radius}m`)
            setMinFOV(mvRef.current.getFieldOfView().toString())
        }
    }

    const resetZoomLimitClick = () => {
        if (mvRef.current) {
            setMinCameraOrbit("")
            setMinFOV("")
        }
    }

    const resetCameraOrbitClick = () => {
        if (mvRef.current) {
            setCameraOrbit(cameraOrbitHelper(currentModel.category))
        }
    }

    const uploadPoster = async (poster: File) => {
        let response = await updateModel({ poster_file: "UPDATE" }, currentModel._id)
        response = await uploadPosterFile(poster, response.data.data[0].poster_file)

        if (!response.error && response.data === "success") {
            console.info("poster uploaded")
        } else {
            console.error("error")
        }
    }

    const uploadThumbnail = async (poster: File) => {
        let response = await updateModel({ thumbnail_file: "UPDATE" }, currentModel._id)
        response = await uploadPosterFile(poster, response.data.data[0].thumbnail_file)

        if (!response.error && response.data === "success") {
            console.info("thumbnail uploaded")
        } else {
            console.error("error")
        }
    }

    const generatePosterButtonRef = useRef<any>(null)
    const generatePosterClick = async () => {
        if (mvRef.current) {
            const generatedThumbnail = await generatePoster(mvRef, SSType.Thumbnail, modelName, false)
            uploadThumbnail(generatedThumbnail!)
            const generatedPoster = await generatePoster(mvRef, SSType.Poster, modelName, false)
            uploadPoster(generatedPoster!)
        }
    }

    const onSaveChanges = async () => {
        setModalSaveButton({
            text: "Saving...",
            type: "warning",
        })
        await editModelViewerConfig({
            modelId: currentModel._id,
            exposure: exposure.toString(),
            shadowIntensity: shadowIntensity.toString(),
            shadowSoftness: shadowSoftness.toString(),
            autoRotate: autoRotate,
            directAR: directAR,
            cameraOrbit: cameraOrbit,
            minCameraOrbit: minCameraOrbit,
            minFOV: minFOV,
            environmentId: environment._id,
            autoRotateDelay: autoRotateDelay.toString(),
            interactionPrompt: interactionPrompt,
            interactionPromptThreshold: interactionPromptThreshold.toString(),
            disablePan: disablePan
        }).then((res) => {
            checkResponseSubmitModal(res);
        })
    }

    const [editType, setEditType] = useState("")
    const {
        isEditModelConfirmationModalOpen,
        setIsEditModelConfirmationModalOpen,
        setIsModelEditedSuccessfully,
    } = useEditModel()


    const renderTooltip = (props, text) => (
        <Tooltip id="button-tooltip" {...props}>
            {text}
        </Tooltip>
    );

    //model info code
    const onModelSaveChanges = () => {
        setModalSaveButton({
            text: "Saving...",
            type: "warning",
        })
        updateModel({ model_name: modelName, reference_link: modelReferenceLink, modelReferenceFileCount, brand_id: brand._id, iframe_link: iframeLink }, currentModel._id).then((res) => {
            checkResponseSubmitModal(res);
        })
    }

    const checkResponseSubmitModal = (res) => {
        if (res.error !== null) {
            setModalSaveButton({
                text: "Something went wrong!",
                type: "danger",
            })
        } else {
            setModalSaveButton({
                text: "Saved!",
                type: "success",
            })
            setTimeout(() => {
                setModalSaveButton({
                    text: "Saved!",
                    type: "",
                })
            }, 3000);
        }
    }

    const handleChange = (event, newValue) => {
        setTab(newValue);
    };

    return (
        <>
            <Box sx={{ width: '100%', typography: 'body1', borderBottom: 0.1, borderColor: '#585860' }}>
                <Tabs
                    variant="fullWidth"
                    TabIndicatorProps={{
                        sx: {
                            bgcolor: "#5EEEA9",
                            height: "1px"
                        }
                    }}
                    value={tab}
                    onChange={handleChange}
                >
                    <Tab value="mvSettings" color="000" label={<SettingsIcon />} />
                    <Tab value="files" label={<UploadFileIcon />} />
                    <Tab value="modelInfo" label={<EditIcon />} />
                </Tabs>
            </Box >

            {(tab === 'mvSettings') && (
                <>
                    <Grid container padding={2}>
                        <>
                            <Grid item xs={12} paddingBottom={1}>
                                <Typography mr={2}>Env Image:</Typography>
                                {hdrList ?
                                    <>
                                        <Autocomplete
                                            sx={{ width: '100%', marginTop: '10px', maxHeight: '36px', borderRadius: '10px' }}
                                            id="environment"
                                            defaultValue={environment ? hdrList.find(env => env._id === environment._id) : null}
                                            getOptionLabel={(option: Hdr) => option.environment_name}
                                            noOptionsText=""
                                            size="small"
                                            onChange={async (e, selected) => { setEnvironment(hdrList.find(env => env._id === selected?._id)) }}
                                            disableClearable={true}
                                            renderInput={(params) => <TextField label="" {...params} />}
                                            options={hdrList}
                                        />
                                    </> : null
                                }
                            </Grid>
                            <Grid item xs={12}>
                                <Typography mr={2}> Exposure: {exposure}</Typography>
                                <Slider
                                    id="exposure"
                                    size="small"
                                    aria-label="Volume"
                                    valueLabelDisplay="auto"
                                    color="secondary"
                                    max={2}
                                    min={0}
                                    step={0.1}
                                    value={exposure}
                                    onChange={(e, val) => { setExposure(val) }}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Typography mr={2}> Shadow Intensity: {shadowIntensity} </Typography>
                                <Slider
                                    size="small"
                                    aria-label="Default"
                                    valueLabelDisplay="auto"
                                    color="secondary"
                                    max={2}
                                    min={0}
                                    step={0.1}
                                    value={shadowIntensity}
                                    onChange={(e, val) => { setShadowIntensity(val) }}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Typography mr={2}> Shadow Softness: {shadowSoftness} </Typography>
                                <Slider
                                    size="small"
                                    aria-label="Default"
                                    valueLabelDisplay="auto"
                                    color="secondary"
                                    max={2}
                                    min={0}
                                    step={0.1}
                                    value={shadowSoftness}
                                    onChange={(e, val) => { setShadowSoftness(val) }}
                                />
                            </Grid>
                            <Grid item xs={8} paddingY={0.5}>
                                <Tooltip describeChild title={cameraOrbitTooltipText}>
                                    <Box>
                                        <QReviewBtn style={{ width: '100%' }} type='submit' onClick={setCameraOrbitClick}>Set Camera Orbit</QReviewBtn>
                                    </Box>
                                </Tooltip>
                            </Grid>
                            <Grid item xs={4} paddingY={0.5} paddingLeft={2}>
                                <Tooltip describeChild title={cameraOrbitResetTooltipText}>
                                    <Box>
                                        <QReviewBtn style={{ width: '100%' }} type='submit' onClick={resetCameraOrbitClick}>
                                            <ReplayIcon fontSize='small' />
                                        </QReviewBtn>
                                    </Box>
                                </Tooltip>
                            </Grid>
                            <Grid item xs={8} paddingY={0.5}>
                                <Tooltip describeChild title={zoomLimitTooltipText}>
                                    <Box>
                                        <QReviewBtn style={{ width: '100%' }} type='submit' onClick={setZoomLimitClick}>Set Zoom Limit</QReviewBtn>
                                    </Box>
                                </Tooltip>
                            </Grid>
                            <Grid item xs={4} paddingY={0.5} paddingLeft={2}>
                                <Tooltip describeChild title={zoomLimitResetTooltipText}>
                                    <Box>
                                        <QReviewBtn style={{ width: '100%' }} type='submit' onClick={resetZoomLimitClick}>
                                            <ReplayIcon fontSize='small' />
                                        </QReviewBtn>
                                    </Box>
                                </Tooltip>
                            </Grid>
                            <Grid item xs={12} paddingY={0.5}>
                                <QReviewBtn style={{ width: '100%' }} type='submit' ref={generatePosterButtonRef} onClick={generatePosterClick}>Generate Poster</QReviewBtn>
                            </Grid>
                            <Grid item xs={8}>
                                <FormControlLabel
                                    control={<Checkbox />}
                                    label="Auto Rotate"
                                    id="auto-rotate"
                                    checked={autoRotate}
                                    onChange={() => {
                                        setAutoRotate(!autoRotate)
                                    }}
                                    labelPlacement="end"
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <FormControlLabel
                                    style={autoRotate ? { display: 'flex', flexDirection: "row", alignItems: "center" } : { display: "none" }}
                                    control={<Input type='number' />}
                                    label="ms"
                                    id='auto-rotate-delay'
                                    value={autoRotateDelay}
                                    onChange={(e:any) => { setAutoRotateDelay(Number(e.target.value)) }}
                                    labelPlacement="end"
                                />
                            </Grid>
                            <Grid item xs={8}>
                                <FormControlLabel
                                    control={<Checkbox />}
                                    label="Interaction prompt"
                                    id="interaction-prompt"
                                    checked={interactionPrompt}
                                    onChange={() => {
                                        setInteractionPrompt(!interactionPrompt)
                                    }}
                                    labelPlacement="end"
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <FormControlLabel
                                    style={interactionPrompt ? { display: 'flex', flexDirection: "row", alignItems: "center" } : { display: "none" }}
                                    control={<Input type='number' />}
                                    label="ms"
                                    id='interaction-prompt-threshold'
                                    value={interactionPromptThreshold}
                                    onChange={(e:any) => { setInteractionPromptThreshold(Number(e.target.value)) }}
                                    labelPlacement="end"
                                />
                            </Grid>
                            <Grid item xs={8}>
                                <FormControlLabel
                                    control={<Checkbox />}
                                    label="Disable Pan"
                                    id="disable-pan"
                                    checked={disablePan}
                                    onChange={() => {
                                        setDisablePan(!disablePan)
                                    }}
                                    labelPlacement="end"
                                />
                            </Grid>
                            <Grid item xs={12} paddingY={0.5}>
                                <QReviewBtn style={{ width: '100%' }} type='submit' onClick={() => { onSaveChanges() }}>{modalSaveButton.text}</QReviewBtn>
                            </Grid>
                        </>

                    </Grid>
                </>
            )}
            {(tab === 'files') && (
                <>
                    {isEditModelConfirmationModalOpen && (
                        <EditModelConfirmationModal
                            isOpen={isEditModelConfirmationModalOpen}
                            closeModal={() => {
                                setIsModelEditedSuccessfully(false)
                                setIsEditModelConfirmationModalOpen(false)
                            }}
                            currentModel={currentModel}
                            editType={editType}
                        />
                    )}
                    <Grid container padding={2} spacing={1}>
                        <Grid item xs={4.5}>
                            <Typography variant="body2">GLB File</Typography>
                            <Typography variant="body2">{glbSize && `(${glbSize})`}</Typography>
                        </Grid>
                        <Grid item xs={2.5}>
                            <Tooltip describeChild title={'Download GLB File'}>
                                <Box>
                                    <Link href={glbUrl} variant="body2" target='_blank'>
                                        <QReviewBtn style={{ width: '100%', borderRadius: '10px' }} type='submit'>
                                            <DownloadIcon fontSize='small' />
                                        </QReviewBtn>
                                    </Link>
                                </Box>
                            </Tooltip>
                        </Grid>
                        <Grid item xs={2.5}>
                            <Tooltip describeChild title={'Copy GLB File URL'}>
                                <Box>
                                    <Link onClick={() => {
                                        navigator.clipboard.writeText(glbUrl)
                                    }}>
                                        <QReviewBtn style={{ width: '100%', borderRadius: '10px' }} type='submit'>
                                            <CopyIcon fontSize='small' />
                                        </QReviewBtn>
                                    </Link>
                                </Box>
                            </Tooltip>
                        </Grid>
                        <Grid item xs={2.5}>
                            <Tooltip describeChild title={'Update GLB File'}>
                                <Box>
                                    <Link onClick={() => {
                                        setIsEditModelConfirmationModalOpen(true)
                                        setEditType("GLB")
                                    }}>
                                        <QReviewBtn style={{ width: '100%', borderRadius: '10px' }} type='submit' >
                                            <EditIcon fontSize='small' />
                                        </QReviewBtn>
                                    </Link>
                                </Box>
                            </Tooltip>
                        </Grid>
                        <Grid item xs={4.5}>
                            <Typography variant="body2">USDZ File</Typography>
                            <Typography variant="body2">{usdzSize && `(${usdzSize})`}</Typography>
                        </Grid>
                        <Grid item xs={2.5}>
                            <Tooltip describeChild title={'Download USDZ File'}>
                                <Box>
                                    <Link href={usdzUrl} variant="body2" target='_blank'>
                                        <QReviewBtn style={{ width: '100%', borderRadius: '10px' }} type='submit'>
                                            <DownloadIcon fontSize='small' />
                                        </QReviewBtn>
                                    </Link>
                                </Box>
                            </Tooltip>
                        </Grid>
                        <Grid item xs={2.5}>
                            <Tooltip describeChild title={'Copy USDZ File URL'}>
                                <Box>
                                    <Link onClick={() => {
                                        navigator.clipboard.writeText(usdzUrl)
                                    }}>
                                        <QReviewBtn style={{ width: '100%', borderRadius: '10px' }} type='submit'>
                                            <CopyIcon fontSize='small' />
                                        </QReviewBtn>
                                    </Link>
                                </Box>
                            </Tooltip>
                        </Grid>
                        <Grid item xs={2.5}>
                            <Tooltip describeChild title={'Update USDZ File'}>
                                <Box>
                                    <Link onClick={() => {
                                        setIsEditModelConfirmationModalOpen(true)
                                        setEditType("USDZ")
                                    }}>
                                        <QReviewBtn style={{ width: '100%', borderRadius: '10px' }} type='submit'>
                                            <EditIcon fontSize='small' />
                                        </QReviewBtn>
                                    </Link>
                                </Box>
                            </Tooltip>
                        </Grid>
                        <Grid item xs={4.5}>
                            <Typography variant="body2">HDR File</Typography>
                            <Typography variant="body2">{hdrSize && `(${hdrSize})`}</Typography>
                        </Grid>
                        <Grid item xs={2.5}>
                            <Tooltip describeChild title={'Download HDR File'}>
                                <Box>
                                    <Link href={hdrUrl} variant="body2" target='_blank'>
                                        <QReviewBtn style={{ width: '100%', borderRadius: '10px' }} type='submit'>
                                            <DownloadIcon fontSize='small' />
                                        </QReviewBtn>
                                    </Link>
                                </Box>
                            </Tooltip>
                        </Grid>
                        <Grid item xs={2.5}>
                            <Tooltip describeChild title={'Copy HDR File URL'}>
                                <Box>
                                    <Link onClick={() => {
                                        navigator.clipboard.writeText(hdrUrl)
                                    }}>
                                        <QReviewBtn style={{ width: '100%', borderRadius: '10px' }} type='submit'>
                                            <CopyIcon fontSize='small' />
                                        </QReviewBtn>
                                    </Link>
                                </Box>
                            </Tooltip>
                        </Grid>
                        <Grid item xs={2.5}>
                            <Tooltip describeChild title={'Update HDR File'}>
                                <Box>
                                    <Link onClick={() => {
                                        setIsEditModelConfirmationModalOpen(true)
                                        setEditType("HDR")
                                    }}>
                                        <QReviewBtn style={{ width: '100%', borderRadius: '10px' }} type='submit'>
                                            <EditIcon fontSize='small' />
                                        </QReviewBtn>
                                    </Link>
                                </Box>
                            </Tooltip>
                        </Grid>
                        <Grid item xs={4.5}>
                            <Typography variant="body2">Reference File(s)</Typography>
                        </Grid>
                        <Grid item xs={2.5}>
                            <Tooltip describeChild title={'Download Reference File(s)'}>
                                <Box>
                                    <Link href={referenceFile} variant="body2" target='_blank'>
                                        <QReviewBtn style={{ width: '100%', borderRadius: '10px' }} type='submit'>
                                            <DownloadIcon fontSize='small' />
                                        </QReviewBtn>
                                    </Link>
                                </Box>
                            </Tooltip>
                        </Grid>
                        <Grid item xs={2.5}>
                            <Tooltip describeChild title={'Copy Reference File URL(s)'}>
                                <Box>
                                    <Link onClick={() => {
                                        navigator.clipboard.writeText(referenceFile)
                                        renderTooltip("tooltip", "Copied!")
                                    }}>
                                        <QReviewBtn style={{ width: '100%', borderRadius: '10px' }} type='submit'>
                                            <CopyIcon fontSize='small' />
                                        </QReviewBtn>
                                    </Link>
                                </Box>
                            </Tooltip>
                        </Grid>
                        <Grid item xs={2.5}>
                            <Tooltip describeChild title={'Update Reference File(s)'}>
                                <Box>
                                    <Link onClick={() => {
                                        setIsEditModelConfirmationModalOpen(true)
                                        setEditType("Reference")
                                    }}>
                                        <QReviewBtn style={{ width: '100%', borderRadius: '10px' }} type='submit'>
                                            <EditIcon fontSize='small' />
                                        </QReviewBtn>
                                    </Link>
                                </Box>
                            </Tooltip>
                        </Grid>
                        <Grid item xs={4.5}>
                            <Typography variant="body2">Poster File</Typography>
                        </Grid>
                        <Grid item xs={2.5}>
                            <Tooltip describeChild title={'Download Poster File'}>
                                <Box>
                                    <Link href={currentModel.poster_file ? currentModel.poster_file[0] : null} variant="body2" target='_blank'>
                                        <QReviewBtn style={{ width: '100%', borderRadius: '10px' }} type='submit'>
                                            <DownloadIcon fontSize='small' />
                                        </QReviewBtn>
                                    </Link>
                                </Box>
                            </Tooltip>
                        </Grid>
                        <Grid item xs={2.5}>
                            <Tooltip describeChild title={'Copy Poster File URL'}>
                                <Box>
                                    <Link onClick={() => {
                                        navigator.clipboard.writeText(currentModel.poster_file[0])
                                        renderTooltip("tooltip", "Copied!")
                                    }}>
                                        <QReviewBtn style={{ width: '100%', borderRadius: '10px' }} type='submit'>
                                            <CopyIcon fontSize='small' />
                                        </QReviewBtn>
                                    </Link>
                                </Box>
                            </Tooltip>
                        </Grid>
                        <Grid item xs={2.5}>
                            <Tooltip describeChild title={'Update Poster File URL'}>
                                <Box>
                                    <Link onClick={() => {
                                        setIsEditModelConfirmationModalOpen(true)
                                        setEditType("Poster")
                                    }}>
                                        <QReviewBtn style={{ width: '100%', borderRadius: '10px' }} type='submit'>
                                            <EditIcon fontSize='small' />
                                        </QReviewBtn>
                                    </Link>
                                </Box>
                            </Tooltip>
                        </Grid>
                    </Grid>

                </>
            )}
            {(tab === 'modelInfo') && (
                <>
                    <Grid container padding={2} spacing={2}>
                        <Grid item xs={12}>
                            <Typography mr={2}>Brand</Typography>
                            {brandList ?
                                <>
                                    <Autocomplete
                                        sx={{ width: '100%' }}
                                        id="brand"
                                        defaultValue={brandList.find(b => b._id === brand)}
                                        getOptionLabel={(option: Brand) => option.brand_name}
                                        noOptionsText=""
                                        size="small"
                                        onChange={async (e, selected) => { setBrand(brandList.find(brand => brand._id === selected._id)) }}
                                        disableClearable={true}
                                        renderInput={(params) => <TextField label="" variant="outlined" {...params} />}
                                        options={brandList}
                                    />
                                </> : null
                            }
                        </Grid>
                        <Grid item xs={12}>
                            <Typography mr={2}>Model Name</Typography>
                            <TextField
                                sx={{ width: '100%', borderRadius: '10px' }}
                                size="small"
                                id="iframe_link"
                                name='iframe_link'
                                label=""
                                value={modelName}
                                onChange={(e) => { setModelName(e.target.value) }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Typography mr={2}>IFrame Link</Typography>
                            <TextField
                                sx={{ width: '100%', borderRadius: '10px' }}
                                size="small"
                                id="iframe_link"
                                name='iframe_link'
                                label=""
                                value={iframeLink}
                                onChange={(e) => { setIframeLink(e.target.value) }}
                            />
                        </Grid>
                        <Grid item xs={9}>
                            <Typography mr={2}>Model Reference Link</Typography>
                            <TextField
                                sx={{ width: '100%', borderRadius: '10px' }}
                                size="small"
                                id="iframe_link"
                                name='iframe_link'
                                value={modelReferenceLink}
                                onChange={(e) => { setModelReferenceLink(e.target.value) }}
                                variant='outlined'
                            />

                        </Grid>
                        <Grid item xs={3} marginTop={3.3} paddingLeft={0}>
                            <Box>
                                <Link href={modelReferenceLink} variant="body2" target='_blank'>
                                    <QReviewBtn style={{ }} type='submit'>
                                        <OpenInNewIcon fontSize='small' />
                                    </QReviewBtn>
                                </Link>
                            </Box>
                        </Grid>
                        <Grid item xs={12} paddingY={1}>
                            <QReviewBtn style={{ width: '100%' }} type='submit' onClick={() => { onModelSaveChanges() }}>{modalSaveButton.text}</QReviewBtn>
                        </Grid>
                    </Grid>
                </>
            )}
        </>
    )
}

export default ModelSettings