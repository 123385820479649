import React from 'react'
import { QReviewBtnProps } from './QReviewBtn.config'
import "./QReviewBtn.scss"

const buttonTypes = {
  'button': 'qreview-btn-error',
  'submit': 'qreview-btn-success',
  'reset': 'qreview-btn-warning',
}

const QReviewBtn = (props: QReviewBtnProps) => {
  return (
    <button ref={props.itemRef} className={`${props.dark ? "qreview-btn-dark" : "qreview-btn"} ${buttonTypes[props.type?props.type:'']}`} {...props}>
      {props.children}
    </button>
  )
}

export default QReviewBtn