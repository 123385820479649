import { Box, Button, Container, Grid, Stack, Typography } from "@mui/material";
import { useAuth } from 'qreal-auth-provider/src';
import { useEffect, useMemo, useState } from 'react';
import { useMixpanel } from 'react-mixpanel-browser';
import { useParams } from "react-router-dom";
import TrashIconBlack from '../../assets/icons/Trash-Icon-Black.svg';
import TrashIconWhite from '../../assets/icons/Trash-Icon-White.svg';
import QReviewActionButton from '../../components/QReviewButton/QReviewActionButton';
import CreateEnvironmentModal from "../../components/modals/CreateEnvironmentModal";
import DeleteConfirmationModal, { statusList } from '../../components/modals/DeleteConfirmationModal';
import QReviewDefaultTable from '../../components/tables/QReviewDefaultTable';
import { authKeys, checkAuth } from '../../enums/authKeys';
import { deleteAxiosWithToken, getAxiosWithToken } from '../../functions/axiosHelper';
import { useThemeContext } from '../../theme/ThemeContextProvider';

interface TableData {
    data: any
    totalRow: number
    page: number
    perPage: number
}

const EnvironmentList = () => {
    const { mode } = useThemeContext();
    const { id } = useParams()
    const { authList } = useAuth();
    const [loading, setLoading] = useState(true);
    const [selectedEnvironment, setSelectedEnvironment] = useState<any>(null);
    const [hdrModal, setHdrModal] = useState(false);
    const [deleteStatus, setDeleteStatus] = useState(statusList.onProgress);
    const [tableData, setTableData] = useState<TableData>({
        data: [],
        totalRow: 0,
        page: 1,
        perPage: 10
    });

    const mixpanel = useMixpanel();
    // mixpanel.track_pageview({"page": "List Environments"});

    const ActionButtons = ({ row }) => (
        <>
            <Stack direction="row" spacing={2}>
                {checkAuth(authList, authKeys.delete_environment) &&
                    <Box onClick={() => {
                        setSelectedEnvironment({
                            id: row._id,
                            name: row.environment_name
                        })
                    }}>
                        <QReviewActionButton icon={mode === "light" ? TrashIconBlack : TrashIconWhite} tooltip='Delete Environment' />
                    </Box>
                }
            </Stack>

        </>
    );

    const columns = [
        {
            name: 'Environment Name',
            sortable: true,
            grow: 2,
            style: { paddingLeft: '10px' },
            selector: row => row.environment_name,
        },
        {
            name: 'Actions',
            grow: 1,
            style: { minWidth: '180px' },
            cell: row => <ActionButtons row={row} />,
        }
    ];

    const deleteEnvironment = async (id: string) => {
        setDeleteStatus(statusList.onLoading);
        await deleteAxiosWithToken({
            url: `/environments/${id}`,
        });
        setDeleteStatus(statusList.onProgress);
        fetchEnvironments(tableData.page, tableData.perPage);
    }

    const handlePerRowsChange = (newPerPage) => {
        setTableData({ ...tableData, perPage: newPerPage })
        fetchEnvironments(tableData.page, newPerPage);
    };

    const handlePageChange = (page) => {
        setTableData({ ...tableData, page: page })
        fetchEnvironments(page, tableData.perPage);
    };

    const fetchEnvironments = async (page, perPage) => {
        setLoading(true);

        const response = await getAxiosWithToken({
            url: `/environments?page=${page}&per_page=${perPage}`
        })

        setTableData({
            ...tableData,
            data: response.data.data,
            totalRow: response.data.total
        });

        setLoading(false);
        setSelectedEnvironment(null);
    }

    const fetchEnvironmentsAndCloseModal = () => {
        fetchEnvironments(tableData.page, tableData.perPage);
        setHdrModal(false)
    }

    useEffect(() => {
        fetchEnvironments(tableData.page, tableData.perPage);
    }, []);
    return useMemo(() => (
        <>
            {selectedEnvironment && <DeleteConfirmationModal
                onClickFunction={() => deleteEnvironment(selectedEnvironment.id)}
                onCloseModal={() => setSelectedEnvironment(null)}
                type="environment"
                isOpen={selectedEnvironment ? true : false}
                status={deleteStatus}
                name={selectedEnvironment.name}
            />}
            <Container maxWidth="xl">
                <Grid container mt={4} mb={2}>
                    <Grid item container xs={12} md={5} alignItems={"center"}>
                        <Typography variant="qrealSubtitle">ENVIRONMENT LIST</Typography>
                    </Grid>
                    <Grid item xs={12} md={7} container justifyContent={"flex-end"}>
                        <Button variant='contained' onClick={() => { setHdrModal(true) }}>Create New HDR</Button>
                    </Grid>
                </Grid>
                <CreateEnvironmentModal
                    isOpen={hdrModal}
                    onCloseModal={() => { setHdrModal(false) }}
                    onEnd={() => { fetchEnvironmentsAndCloseModal() }}
                />
                <QReviewDefaultTable
                    columns={columns}
                    data={tableData.data}
                    pagination={true}
                    progressPending={loading}
                />

            </Container>
        </>
    ), [tableData, loading, selectedEnvironment, hdrModal])
}

export default EnvironmentList;
