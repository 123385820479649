import React, { useContext, useState } from "react"
import { createReferrer } from "../functions/createReferrer"

export type CreateReferrerContextType = {
    createReferrerAsyncMethod: (modelId: string, organizationId: string, externalId: string) => any

}

export const CreateReferrerContext = React.createContext<CreateReferrerContextType | null>(null)
export const useCreateReferrer = () => useContext(CreateReferrerContext) as CreateReferrerContextType

type Props = {
    children: JSX.Element
}

const CreateReferrerProvider: React.FC<Props> = ({ children }) => {

    const createReferrerAsyncMethod = async (modelId, organizationId, externalId) => {
        const response = await createReferrer({
            modelId: modelId,
            organizationId: organizationId,
            externalId: externalId
        })

        return response
    }

    const valueObject = {
        createReferrerAsyncMethod,
    }

    return <CreateReferrerContext.Provider value={valueObject}>{children}</CreateReferrerContext.Provider>
}

export default CreateReferrerProvider