import { Button, Container, Grid } from "@mui/material"
import Navbar from "../../components/shared/Navbar"
import { useEffect, useState } from "react"
import { Subscription } from "../../types/user"
import { deleteAxiosWithToken, getAxiosWithToken } from "../../functions/axiosHelper"
import StatusBadge from "../../components/shared/table-items/StatusBadge"
import QReviewDefaultTable from "../../components/tables/QReviewDefaultTable"
import { Organization } from "../../types/organization"
import UpdateSubscriptionForm from "../../components/forms/updateSubscriptionForm"
import CreateSubscriptionForm from "../../components/forms/createSubscriptionForm"
import { useMixpanel } from 'react-mixpanel-browser';

// Icons
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteForeverOutlined';
import AddIcon from '@mui/icons-material/Add';
import DeleteConfirmationModal from "../../components/modals/DeleteConfirmationModal"

const Subscriptions = () => {
    const [userSubscriptions, setUserSubscriptions] = useState<Subscription[]>([])
    const [loading, setLoading] = useState<boolean>(true)
    const [organizations, setOrganizations] = useState<Organization[]>([])
    const [selectedSubscription, setSelectedSubscription] = useState<Subscription | null>(null)
    const [cardShow, setCardShow] = useState<{
        update: boolean,
        create: boolean,
        delete: boolean
    }>({
        update: false,
        create: false,
        delete: false
    })
    const mixpanel = useMixpanel();

    useEffect(() => {
        getUserSubscriptions();
        getAxiosWithToken({
            url: '/organizations?list_all=true',
            authRequest: true
        }).then((response) => {
            setOrganizations(response.data.data);
        });
    }, [])

    const getUserSubscriptions = () => {
        setLoading(true)
        getAxiosWithToken({
            url: '/report-subscriptions?is_debug=true&list_all=true'
        }).then((res) => {
            setUserSubscriptions(res.data.data)
            setLoading(false)
        }).catch((err) => {
            console.error(err)
            setLoading(false)
        })
    }

    const ActionButtons = ({ row }) => (
        <>
            <Grid container alignItems={'center'} spacing={2}>
                <Grid item xs={6}>
                    <Button size="small" variant="contained" color="warning" onClick={() => {
                        setSelectedSubscription(row);
                        setCardShow({
                            update: true,
                            create: false,
                            delete: false
                        })
                    }}><EditIcon /></Button>
                </Grid>
                <Grid item xs={6}>
                    <Button size="small" variant="contained" color="error" onClick={() => {
                        setSelectedSubscription(row);
                        setCardShow({
                            update: false,
                            create: false,
                            delete: true
                        })
                    }}><DeleteIcon /></Button>
                </Grid>
            </Grid>
        </>
    );

    const closeCards = () => {
        setSelectedSubscription(null);
        setCardShow({
            update: false,
            create: false,
            delete: false
        })
    }

    const getColumns = (organizations: Organization[]) => {
        return [
            {
                name: 'Title',
                sortable: true,
                grow: 2,
                //cell: row => <Link to={row._id} >{row.model_name}</Link>,
                selector: row => row.title
            },
            {
                name: 'Mail Freq.',
                sortable: true,
                selector: row => row.mail_frequency,
            },
            {
                name: 'Data Freq.',
                sortable: true,
                selector: row => row.data_frequency,
            },
            {
                name: 'Organizations',
                sortable: true,
                cell: row => {
                    return row.organizations.map((organizationId: string) => {
                        const organization = organizations.find((org) => org._id === organizationId);
                        return organization ? organization.organization_name : '';
                    }).join(', ');
                }
            },
            {
                name: 'Status',
                sortable: true,
                cell: row => <StatusBadge status={row.status} />
            },
            {
                name: 'Actions',
                style: { minWidth: '180px' },
                cell: row => <ActionButtons row={row} />,
            }
        ];
    }

    return <div className="black-bg">
        <Container maxWidth="lg" sx={{ minHeight: '100%', height: '100%' }}>
            <Grid container spacing={2} pt={3}>
                <Grid mb={2} item container xs={12} sm={12} md={12} lg={12} xl={12} justifyContent={'space-between'}>
                    <h3 className="white-text">Report Subscriptions</h3>
                    <Button size="small" variant="contained" color="success" onClick={() => {
                        setCardShow({
                            update: false,
                            create: true,
                            delete: false
                        })
                    }}><AddIcon /></Button>
                </Grid>

                <QReviewDefaultTable
                    columns={getColumns(organizations)}
                    data={userSubscriptions}
                    pagination={false}
                    progressPending={loading || organizations.length === 0}
                />

                {(!loading && userSubscriptions.length === 0) &&
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <p className="white-text">You are not subscribed to any emails.</p>
                    </Grid>
                }
            </Grid>

            {(selectedSubscription && cardShow.update) &&
                <UpdateSubscriptionForm
                    organizations={organizations}
                    subscription={selectedSubscription}
                    callBack={() => {
                        closeCards();
                        getUserSubscriptions();
                    }}
                />}

            {cardShow.create &&
                <CreateSubscriptionForm
                    organizations={organizations}
                    callBack={() => {
                        closeCards();
                        getUserSubscriptions();
                    }}
                />}

            {cardShow.delete &&
                <DeleteConfirmationModal
                    type="Subscription"
                    name={selectedSubscription ? selectedSubscription.title : ''}
                    isOpen={true}
                    onCloseModal={() => {
                        closeCards();
                    }}
                    onClickFunction={() => {
                        deleteAxiosWithToken({
                            url: `/report-subscriptions/${selectedSubscription ? selectedSubscription._id : ''}`
                        }).then((res) => {
                            closeCards();
                            getUserSubscriptions();
                        })
                    }}
                    status="onProgress"
                />}
        </Container>
    </div>
}

export default Subscriptions