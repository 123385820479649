import { postAxiosWithToken } from "./axiosHelper"

export const createModelComment = async (id: string, comment: string) => {
    type body = {
        comment: string
    }

    const requestBody:body = {
        "comment": comment
    }

    try {
        const response = await postAxiosWithToken({
            url: `/models/${id}/comments`,
            body: requestBody,
        })
        if (response) {
            if ("data" in response) {
                const {data} = response
                if (data) {
                    return {
                        error: null,
                        data: data,
                    }
                }
            }
        }
        return {
            error: "Something went wrong",
            data: null,
        }
    } catch (error) {
        return {
            error: "Something went wrong",
            data: null,
        }
    }
}
