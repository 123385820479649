import { switchToken } from "./IdentityService"
import {
    getAxiosWithTokenAndBaseUrl,
    postAxiosWithTokenAndBaseUrl,
    putAxiosWithTokenAndBaseUrl
} from "../functions/axiosHelper"

import axios from "axios"

const QMM_SERVICE_API = process.env.REACT_APP_QMM_SERVICE_API

export async function getByQReviewId(id: string) {
    return switchToken().then((token: string | null) => {
        if (!token) {
            return []
        }

        return getAxiosWithTokenAndBaseUrl({
            baseUrl: QMM_SERVICE_API as string,
            path: "/variants/by-qreview-id/" + id,
            optionalToken: token
        }).then((res: any) => {
            if (res.error) {
                return null
            }
            return res.data
        }).catch((err: any) => {
            return []
        })
    })
}

export async function listBaseModels() {
    return switchToken().then((token: string | null) => {
        if (!token) {
            return []
        }

        return getAxiosWithTokenAndBaseUrl({
            baseUrl: QMM_SERVICE_API as string,
            path: "/models?list_all=true",
            optionalToken: token
        }).then((res: any) => {

            if (res.error) {
                return null
            }

            return res.data
        }).catch((err: any) => {
            return []
        })
    })
}

export async function listBrands() {
    return switchToken().then((token: string | null) => {
        if (!token) {
            return []
        }

        return getAxiosWithTokenAndBaseUrl({
            baseUrl: QMM_SERVICE_API as string,
            path: "/brands?list_all=true",
            optionalToken: token
        }).then((res: any) => {

            if (res.error) {
                return null
            }

            return res.data
        }).catch((err: any) => {
            return []
        })
    })
}

export async function listEnvironments() {
    return switchToken().then((token: string | null) => {
        if (!token) {
            return []
        }

        return getAxiosWithTokenAndBaseUrl({
            baseUrl: QMM_SERVICE_API as string,
            path: "/environments?list_all=true",
            optionalToken: token
        }).then((res: any) => {

            if (res.error) {
                return null
            }

            return res.data
        }).catch((err: any) => {
            return []
        })
    })
}

export async function createVariant(variantData: Object) {
    return switchToken().then((token: string | null) => {
        if (!token) {
            return []
        }

        return postAxiosWithTokenAndBaseUrl({
            baseUrl: QMM_SERVICE_API as string,
            path: "/variants?is_debug=true",
            body: {
                ...variantData, ...{
                    "thumbnail_file": {
                        "name": "my thumbnail",
                        "type": "image/webp",
                        "size": "1212"
                    },
                    "glb_file": {
                        "name": "my glb file",
                        "type": "model/gltf-binary",
                        "size": "141241"
                    },
                    "usdz_file": {
                        "name": "file_name",
                        "type": "model/vnd.usdz+zip",
                        "size": "41241"
                    },
                    "model_viewer_config": {

                    }
                }
            },
            optionalToken: token
        }).then((res: any) => {

            if (res.error) {
                return null
            }

            return res.data
        }).catch((err: any) => {
            return []
        })
    })
}

export async function updateVariant(QMMId, updateData: Object) {
    return switchToken().then((token: string | null) => {
        if (!token) {
            return []
        }

        return putAxiosWithTokenAndBaseUrl({
            baseUrl: QMM_SERVICE_API as string,
            path: "/variants/" + QMMId,
            body: updateData,
            optionalToken: token
        }).then((res: any) => {

            if (res.error) {
                return null
            }

            return res.data
        }).catch((err: any) => {
            return []
        })
    })
}

export async function copyFile(source: string, target: string) {
    return switchToken().then((token: string | null) => {
        if (!token) {
            return []
        }

        return postAxiosWithTokenAndBaseUrl({
            baseUrl: QMM_SERVICE_API as string,
            path: "/variants/copy",
            body: {
                "copy_source": source,
                "target_source": target
            },
            optionalToken: token
        }).then((res: any) => {
            if (res.error) {
                return null
            }

            return res.data
        }).catch((err: any) => {
            return []
        })
    })
}

export async function createBaseModel(baseModelData: Object) {
    return switchToken().then((token: string | null) => {
        if (!token) {
            return []
        }

        return postAxiosWithTokenAndBaseUrl({
            baseUrl: QMM_SERVICE_API as string,
            path: "/models?is_debug=true",
            body: baseModelData,
            optionalToken: token
        }).then((res: any) => {

            if (res.error) {
                return null
            }

            return res.data
        }).catch((err: any) => {
            return []
        })
    })
}

export async function createEnvironment(environmentData: Object) {
    return switchToken().then((token: string | null) => {
        if (!token) {
            return []
        }

        return postAxiosWithTokenAndBaseUrl({
            baseUrl: QMM_SERVICE_API as string,
            path: "/environments?is_debug=true",
            body: environmentData,
            optionalToken: token
        }).then((res: any) => {
            if (res.error) {
                return null
            }
            return res.data
        }).catch((err: any) => {
            return []
        })
    })
}

export async function uploadFile(file: File, url: string, type: string) {
    return axios({
        method: "put",
        url: url,
        data: file,
        headers: {
            "Content-Type": type
        }
    })
}
