import { SSType } from "../enums/constants";

export const rafPasses = (): Promise<void> =>
    new Promise(resolve => requestAnimationFrame(() => resolve()));

export const generatePoster = async (mvRef: React.MutableRefObject<any>, type: SSType, modelName: string, isCreate: boolean) => {
    if (mvRef.current) {
        const oldShadowIntensity = mvRef.current.shadowIntensity
        //set minimumRenderScale to 1 to improve quality temporarily
        const oldMinScale = mvRef.current.minimumRenderScale;
        mvRef.current.minimumRenderScale = 1;

        // handle models with built-in shadows from blender
        let shadowMaterial, shadowMaterialAlpha
        if(mvRef.current.model.materials.find(mat => mat.name.toLowerCase() === "shadow")){
            shadowMaterial = mvRef.current.model.materials.find(mat => mat.name.toLowerCase() === "shadow")
            shadowMaterialAlpha = shadowMaterial.pbrMetallicRoughness.baseColorFactor
            shadowMaterial.pbrMetallicRoughness.setBaseColorFactor([0,0,0,0])
        }

        if(type === SSType.Poster){
            mvRef.current.style.width = `${1024 / window.devicePixelRatio}px`;
            mvRef.current.style.height = `${1024 / window.devicePixelRatio}px`;
        }
        
        //turning shadow-intensity off to generate poster without shadow
        mvRef.current.shadowIntensity = 0

        // Wait for model-viewer to resize and render.
        await rafPasses()
        await rafPasses()
        const posterBlob = await mvRef.current.toBlob({ mimeType: "image/webp", qualityArgument: 1, idealAspect: true })
        
        if (type === SSType.Poster) {
            var link = document.createElement("a")
            document.body.appendChild(link)
            link.setAttribute("type", "hidden")
            link.href = URL.createObjectURL(posterBlob)
            link.download = modelName + "-" + type.toString() + ".webp"
            link.click();
            document.body.removeChild(link);
        }
        
        // Reset to original state
        mvRef.current.shadowIntensity = oldShadowIntensity
        if(mvRef.current.model.materials.find(mat => mat.name.toLowerCase() === "shadow")){
            shadowMaterial.pbrMetallicRoughness.setBaseColorFactor(shadowMaterialAlpha)
        }
        if (isCreate) {
            mvRef.current.style.width = '500px';
            mvRef.current.style.height = '500px';
        }else{
            mvRef.current.style.width = '100%';
            mvRef.current.style.height = '100%';
        }
        mvRef.current.minimumRenderScale = oldMinScale;

        return new File([posterBlob], modelName)
    }
}