import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, TextField } from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { postAxiosWithToken } from "../../functions/axiosHelper";
import CustomDropzone from "../CustomDropzone";
import InfoBox from "../InfoBox";
import QReviewBtn from "../QReviewButton/QReviewBtn";

type Props = {
    isOpen: boolean
    onEnd: () => any
    onCloseModal: () => any
}
interface Brand {
    name: string;
    image: File | null;
}

const CreateBrandModal: React.FC<Props> = ({ onEnd, isOpen, onCloseModal }) => {
    const [brandImage, setBrandImage] = useState<File | null>(null);
    const [message, setMessage] = useState<string>('');
    const [processStatus, setProcessStatus] = useState<'info' | 'success'>('info');
    const [brandData, setBrandData] = useState<Brand>({
        name: '',
        image: null
    });


    useEffect(() => {
        setBrandData({
            ...brandData,
            image: brandImage
        });
    }, [brandImage]);

    const onClickFunction = async () => {
        setProcessStatus('info');
        setMessage('Brand Image loading...');

        if (!brandData.image) {
            setMessage('Please select image');
            return;
        } else if (!brandData.name) {
            setMessage('Please enter brand name');
        } else {
            setMessage('Brand loading...');
        }

        const response = await postAxiosWithToken({
            url: '/brands',
            body: { brand_name: brandData.name }
        });

        setMessage('Brand Image Uploading...');
        const uploadUrl = response.data.data[0].thumbnail_file[0].pre_signed_url;

        if (uploadUrl) {
            const uploadResponse = await axios({
                method: "put",
                url: uploadUrl,
                headers: {
                    "Content-Type": "image/png",
                },
                data: brandData.image[0],
            }).catch((err) => {
                setMessage('Something went wrong');
            })

            if (uploadResponse) {
                setMessage('brand created');
                setProcessStatus('success');
                onEnd();
            }
        }
    }

    useEffect(() => {
        if (!isOpen) {
            setMessage('');
        }
    }, [isOpen])


    return (
        <Dialog
            open={isOpen}
            onClose={onCloseModal}
            fullWidth={true}
            maxWidth={'sm'}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
                {"Create Brand"}
            </DialogTitle>
            <DialogContent>
                <Grid container py={2}>
                    <TextField fullWidth onChange={(e) => {
                        setBrandData({
                            ...brandData,
                            name: e.target.value
                        });
                    }} placeholder="Brand Name" />
                </Grid>
                <CustomDropzone
                    setFile={setBrandImage}
                    fileName={"Brand Image"}
                    isMultiple={false}
                    accept={{
                        'image/jpeg': []
                    }}
                    maxFiles={1}
                />
            </DialogContent>
            <DialogActions sx={{margin:'16px'}}>
                <QReviewBtn type="submit" onClick={onClickFunction}>
                    Create
                </QReviewBtn>
                <QReviewBtn type="button" onClick={onCloseModal}>
                    Close
                </QReviewBtn>
            </DialogActions>
            {message &&
                <div className="container pb-3">
                    <InfoBox status={processStatus}>
                        {message}
                    </InfoBox>
                </div>
            }
        </Dialog>
    )
}

export default CreateBrandModal

