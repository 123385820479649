import { useEffect, useState } from "react";
import { postAxiosWithToken } from "../functions/axiosHelper";
import { Organization } from "../types/organization";
import { Autocomplete, Box, Button, Container, Divider, Grid, InputLabel, Stack, Switch, TextField, Typography, selectClasses } from "@mui/material";

const CreateReferrer = ({ modelId, callBack, organizations }: { modelId: string, callBack: () => void, organizations: Organization[] }) => {
    const [formData, setFormData] = useState({
        external_id: '',
        referrer_status: '',
        organization_id: ''
    });

    const status = [
        {label:'Active', value:'ACTIVE'},
        {label:'Passive', value:'PASSIVE'} 
    ]

    const createReferrer = async () => {
        const { data } = await postAxiosWithToken({
            url: '/referrers',
            body: {
                external_id: formData.external_id,
                referrer_status: formData.referrer_status,
                organization_id: formData.organization_id,
                model_id: modelId
            }
        });

        if (data.data && data.is_complete) {
            callBack();
        }
    };

    return (
        <>
            <Container maxWidth="xl">
                <Stack spacing={1}>
                    <Stack spacing={1}>
                        <Typography variant="h6">
                            Create
                        </Typography>
                        <Divider variant="inset" />
                    </Stack>

                    {organizations.length > 0 ?
                        <Stack spacing={1}>
                            <Stack>
                                <Grid container p={1} spacing={2}>
                                    <Grid item xs={12} md={4}>
                                        <InputLabel required>External Id</InputLabel>
                                        <TextField required fullWidth size='small' value={formData.external_id} onChange={(e) => setFormData({ ...formData, external_id: e.target.value })}></TextField>
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <InputLabel required>Referrer Status</InputLabel>
                                        {
                                            <Autocomplete
                                                id="organization"
                                                getOptionLabel={(option) => option.label.toString()}
                                                noOptionsText=""
                                                size="small"
                                                onChange={(e,selected) => {setFormData({ ...formData, referrer_status: selected.value })}}
                                                disableClearable={true}
                                                renderInput={(params) => <TextField label="" variant="outlined" {...params} />}
                                                options={status}
                                            />
                                        }

                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <InputLabel required>Organization</InputLabel>

                                        {
                                            <Autocomplete
                                                id="organization"
                                                getOptionLabel={(option) => option.organization_name.toString()}
                                                noOptionsText=""
                                                size="small"
                                                onChange={(e,selected) => {setFormData({ ...formData, organization_id:selected._id }) }}
                                                disableClearable={true}
                                                renderInput={(params) => <TextField label="" variant="outlined" {...params} />}
                                                options={organizations}
                                            />
                                        }
                                    </Grid>
                                </Grid>
                            </Stack>
                            <Stack direction={"row"} justifyContent={'flex-end'} spacing={2}>
                                <Button type="submit" variant='contained'  onClick={() => { createReferrer() }}>Submit</Button>
                            </Stack>
                        </Stack>
                        :
                        <Typography variant="h6">
                            All available referrer created for organizations
                        </Typography>
                    }
                </Stack>

            </Container>

        </>
    );
};

export default CreateReferrer;