import { Autocomplete, Box, Button, FormControl, FormHelperText, Grid, List, ListItem, Slider, Stack, TextField, Typography } from '@mui/material';
import { useFormik } from 'formik';
import { useEffect, useRef, useState } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { array, object, string } from 'yup';
import { SSType } from '../../enums/constants';
import { generatePoster } from '../../functions/generatePoster';
import { getHdrList } from '../../functions/getEnvironments';
import { useCreateModel } from '../../providers/CreateModelProvider';
import { Hdr } from '../../types/hdr';
import { Option } from '../../types/option';
import CustomDropzone from '../CustomDropzone';
import CreateEnvironmentModal from '../modals/CreateEnvironmentModal';

const validationSchema = object({
  glb_file: string().required('GLB File is required'),
  reference_file: array().required('Reference File is required'),
  environment: string().required('Environment is required'),
});

interface ModelFileUploadStepProps {
  handleBack: () => void
  handleNext: () => void
  environments: Option[]
}

const ModelFileUploadStep = ({ handleBack, handleNext, environments }: ModelFileUploadStepProps) => {
  const [hdrModal, setHdrModal] = useState(false);
  const [hdrURL, setHdrURL] = useState<string>();
  const [hdrList, setHdrList] = useState<Array<Hdr>>([]);
  const [glbBlobURL, setGlbBlobURL] = useState<any>();
  const mvRef = useRef<any>(null);

  const {
    GLBFile,
    setGLBFile,
    USDZFile,
    setUSDZFile,
    HDRFile,
    environmentId,
    setEnvironmentId,
    ReferenceFile,
    setReferenceFile,
    exposure,
    shadowIntensity,
    shadowSoftness,
    setExposure,
    setShadowIntensity,
    setShadowSoftness,
    cameraOrbit,
    modelName,
    thumbnailFile,
    setThumbnailFile,
    posterFile,
    setPosterFile,
    createModelAsyncMethod,
    setModelReferenceFileCount,
  } = useCreateModel()

  const formik = useFormik({
    initialValues: {
      glb_file: GLBFile,
      reference_file: ReferenceFile,
      environment: environmentId,
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      await onCreateModelClick();
    },
  });

  useEffect(() => {
    if (GLBFile) {
      const url = URL.createObjectURL(GLBFile[0])
      setGlbBlobURL(url)
    }
  }, [GLBFile])

  useEffect(() => {
    if (hdrList.find(hdr => hdr._id === environmentId)?.hdr_file[0].url) {
      setHdrURL(hdrList.find(hdr => hdr._id === environmentId)?.hdr_file[0].url)
    }

  }, [environmentId, hdrList])

  useEffect(() => {
    getHdrList().then((response) => {
      setHdrList(response.data.data);
    })
  }, [])

  const onCreateModelClick = async () => {
    if (mvRef.current) {

      await generatePoster(mvRef, SSType.Poster, modelName, true).then((res) => {
        setPosterFile(res)
      })
      await generatePoster(mvRef, SSType.Thumbnail, modelName, true).then((res) => {
        setThumbnailFile(res)
      })

    }
  }

  useEffect(() => {

    if (posterFile && thumbnailFile) {
      createModelAsyncMethod(modelName, GLBFile, USDZFile, HDRFile, posterFile, thumbnailFile)
      handleNext()
    }
  }, [posterFile, thumbnailFile])

  const [files, setFiles] = useState<any>([]);
  const handleOnDragEnd = (result) => {
    if (!result.destination) return;

    const items = Array.from(files);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    setFiles(items);
    setReferenceFile(items);
  }

  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <Grid container spacing={2} >
          <Grid item xs={3}>
            <Typography mr={2}> GLB File </Typography>
          </Grid>
          <Grid item xs={9}>
            <FormControl error={formik.touched.glb_file && Boolean(formik.errors.glb_file)} fullWidth>
              <CustomDropzone
                setFile={(file) => {
                  setGLBFile(file);
                  formik.setFieldValue("glb_file", file[0]);
                }}
                fileName={"GLB"}
                isMultiple={false}
                accept={{ 'model/gltf-binary': ['.glb'] }}
                minSize={100000}
                maxFiles={1}
              />
              {(formik.touched.glb_file && formik.errors.glb_file) && <FormHelperText>GLB File is required</FormHelperText>}
            </FormControl>
          </Grid>
          <Grid item xs={3}>
            <Typography mr={2}> USDZ File </Typography>
          </Grid>
          <Grid item xs={9}>
            <CustomDropzone
              setFile={setUSDZFile}
              fileName={"USDZ"}
              isMultiple={false}
              accept={{ 'model/vnd.usdz+zip': ['.usdz'] }}
              maxFiles={1}
            />

          </Grid>
          <Grid item xs={3}>
            <Typography mr={2}> Reference File </Typography>
          </Grid>
          <Grid item xs={9}>
            <FormControl error={formik.touched.reference_file && Boolean(formik.errors.reference_file)} fullWidth>
              <CustomDropzone
                setFile={(file) => {
                  setModelReferenceFileCount(file.length);
                  setReferenceFile(file);
                  setFiles(file);
                  formik.setFieldValue("reference_file", file);
                }}
                fileName={"REFERENCE as jpeg"}
                isMultiple={true}
                accept={{ 'image/jpeg': ['.jpeg', '.jpg'] }}
                maxFiles={10}
              />
              {(formik.touched.reference_file && formik.errors.reference_file) && <FormHelperText>Reference File is required</FormHelperText>}
              {files &&
                <DragDropContext onDragEnd={handleOnDragEnd}>
                  <Droppable droppableId="referenceImages" direction="horizontal" spacing={2}>
                    {(provided) => (
                      <Grid container className="referenceImages" {...provided.droppableProps} ref={provided.innerRef} direction={'row'} spacing={1} sx={{ marginTop: '12px' }}>
                        {files.map((file, index) => {
                          return (
                            <Draggable key={file.name} draggableId={file.name} index={index}>
                              {(provided) => (
                                <Grid item ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>

                                  <img style={{
                                    float: 'left',
                                    width: '60px',
                                    height: '60px',
                                    objectFit:'cover'}} src={URL.createObjectURL(file)} alt={file.name} />

                                </Grid>
                              )}
                            </Draggable>
                          );
                        })}
                        {provided.placeholder}
                      </Grid>
                    )}
                  </Droppable>
                </DragDropContext>
              }
            </FormControl>

          </Grid>
          <Grid item xs={3}>
            <Typography mr={2}> HDR </Typography>
          </Grid>
          <Grid item xs={6}>
            <FormControl error={formik.touched.environment && Boolean(formik.errors.environment)} fullWidth>
              <Autocomplete
                loading={environments.length > 0}
                size='small'
                id='environment'
                options={environments}
                disableClearable
                value={environments.find((environment) => environment.value === formik.values.environment)}
                onChange={(e, selected) => { formik.setFieldValue("environment", selected.value); setEnvironmentId(selected.value) }}
                onBlur={formik.handleBlur}
                renderInput={(params) => <TextField {...params} label="Environment" error={formik.touched.environment && Boolean(formik.errors.environment)} />}
              />
              {(formik.touched.environment && formik.errors.environment) && <FormHelperText>{formik.errors.environment}</FormHelperText>}
            </FormControl>
          </Grid>
          <Grid item xs={3}>
            <Button onClick={() => { setHdrModal(true) }}>Create New HDR</Button>
          </Grid>
          <Grid item xs={3}>
            <Typography mr={2}> Model Viewer Settings </Typography>
          </Grid>
          <Grid item xs={4}>
            <Grid item xs={12}>
              <Typography mr={2}> Exposure: {exposure}</Typography>
              <Slider
                id="exposure"
                size="small"
                aria-label="Volume"
                valueLabelDisplay="auto"
                color="secondary"
                max={2}
                min={0}
                step={0.1}
                value={exposure}
                onChange={(e, val) => { setExposure(val) }}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography mr={2}> Shadow Intensity: {shadowIntensity} </Typography>
              <Slider
                size="small"
                aria-label="Default"
                valueLabelDisplay="auto"
                color="secondary"
                max={2}
                min={0}
                step={0.1}
                value={shadowIntensity}
                onChange={(e, val) => { setShadowIntensity(val) }}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography mr={2}> Shadow Softness: {shadowSoftness} </Typography>
              <Slider
                size="small"
                aria-label="Default"
                valueLabelDisplay="auto"
                color="secondary"
                max={2}
                min={0}
                step={0.1}
                value={shadowSoftness}
                onChange={(e, val) => { setShadowSoftness(val) }}
              />
            </Grid>

          </Grid>
          <Grid item xs={5}>
            <model-viewer tone-mapping="commerce" fullWidth ref={mvRef} interaction-prompt="none" src={glbBlobURL} shadow-intensity={shadowIntensity} shadow-softness={shadowSoftness} exposure={exposure} environment-image={hdrURL} camera-orbit={cameraOrbit}></model-viewer>
          </Grid>

          <Grid item xs={12}>
            <Stack direction={'row'} spacing={2} justifyContent={"flex-end"}>
              <Box display={"flex"}>
                <Button onClick={handleBack}>Back</Button>
              </Box>
              <Box display={"flex"}>
                <Button type="submit">Next</Button>
              </Box>
            </Stack>
          </Grid>
          <CreateEnvironmentModal
            isOpen={hdrModal}
            onCloseModal={() => { setHdrModal(false) }}
            onEnd={() => { getHdrList() }}
          />
        </Grid>
      </form>
    </>
  )
}

export default ModelFileUploadStep

