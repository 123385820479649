
export const BlockPageLeave = (message) => {
    const confirmExit = () => {
        return message || "You have attempted to leave this page. Are you sure?";
    }
    window.onbeforeunload = confirmExit;
}

export const UnBlockPageLeave = () => {
    window.onbeforeunload = null;
}
