import { Button } from "@mui/material"
import CloudUploadIcon from '@mui/icons-material/CloudUpload';

const UploadImageButton = ({
    onLoadCallback,
    style
}) => {

    return <Button component="label" style={style} variant="contained">
        <CloudUploadIcon /> 
        <input
            type="file"
            hidden
            onChange={(e: any) => {
                onLoadCallback(e.target.files[0])
            }}
        />
    </Button>

}

export default UploadImageButton;