import { useEffect, useState } from "react";
import { getAxiosWithToken } from "../../functions/axiosHelper";
import { Box, Button, FormControl, MenuItem, Popover, PopoverProps, Select, Stack, Typography, styled } from "@mui/material";
import { ModelCategories, ModelStatusList } from "../../enums/modelCategories";
import { Organization } from "../../types/organization";
import ModelFilterCalendar from "../calendars/filterModelCalendar";
import { Brand } from "../../providers/AppProvider";
import { useSearchParams } from "react-router-dom";
import { searchModels } from "../../services/model";
import { addARLink, downloadModelsAsExcel, removeKeys } from "../ExportModels";
import QReviewIconButton from "../QReviewButton/QReviewIconButton";
import DownIcon from "../../assets/icons/Down-Icon.svg"
import DataIcon from "../../assets/icons/Data-Icon.svg"
import CloseIconWhite from "../../assets/icons/Close-Icon-White.svg"
import CloseIconBlack from "../../assets/icons/Close-Icon-Black.svg"
import FilterIcon from "../../assets/icons/Filter-Icon.svg"
import { useThemeContext } from "../../theme/ThemeContextProvider";
import { ArrowDownIconBlack, ArrowDownIconWhite } from "../../assets/icons";
import { useAuth } from "qreal-auth-provider/src";
import { authKeys, checkAuth } from "../../enums/authKeys";
import LoadingIcon from "../../assets/icons/loading.svg";

interface Models {
    _id: string;
    model_name: string;
    glb_file: string;
    qa_status: string;
}

const StyledPopover = styled(Popover)<PopoverProps>(({ theme }) => ({
    ".MuiPopover-paper": {
        boxShadow: "0px 0px 6px 6px rgba(0, 0, 0, 0.10), 0px 0px 16px 0px rgba(0, 0, 0, 0.25)",
        background: theme.palette.background.default,
        padding: 24,
        borderRadius: 10,
        width: 375,
    }
}))

const FilterCard = ({ open, id, anchorEl, onClose, organizations, brands, callUpdates }) => {
    const [loading, setLoading] = useState(false);
    const { mode } = useThemeContext();
    const { authList } = useAuth();
    const [settings, setSettings] = useState({
        status: '',
        organization: '',
        brand: '',
        category: '',
        startDate: null,
        endDate: null
    })
    const [isFiltered, setIsFiltered] = useState(false);

    let [searchParams, setSearchParams] = useSearchParams();

    const filterModels = () => {
        if (settings.status !== '') {
            searchParams.set('qa_status', settings.status);
        }

        if (settings.organization !== '') {
            searchParams.set('organization_id', settings.organization);
        }

        if (settings.brand !== '') {
            searchParams.set('brand_id', settings.brand);
        }

        if (settings.category !== '') {
            searchParams.set('category', settings.category);
        }

        if (settings.startDate !== null) {
            searchParams.set('start_date', settings.startDate);
        }

        if (settings.endDate !== null) {
            searchParams.set('end_date', settings.endDate);
        }
        if (searchParams.get('search_query') == null) {
            searchParams.set('search_query', '');
        }

        searchParams.set('filtered', 'true')

        setSearchParams(searchParams);

        searchModels(searchParams.toString()).then((response) => {
            if (response.status) {
                //setOpen(false);
                callUpdates(response.data);

                searchParams.set('page', '1');
                setSearchParams(searchParams);
            }
        })
    }

    const clearFilterParams = () => {
        searchParams.delete('qa_status');
        searchParams.delete('organization_id');
        searchParams.delete('brand_id');
        searchParams.delete('category');
        searchParams.delete('start_date');
        searchParams.delete('end_date');
        searchParams.delete('filtered');

        setSearchParams(searchParams);

        // Reset the table data
        searchModels(searchParams.toString()).then((response) => {
            if (response.status) {
                callUpdates(response.data);
                searchParams.set('page', '1');
            }
        })
    }

    useEffect(() => {
        if (!searchParams.get('filtered')) {
            setIsFiltered(false);
            setSettings({
                status: '',
                organization: '',
                brand: '',
                category: '',
                startDate: null,
                endDate: null
            })
        }
    }, [searchParams])

    const handleFilterClick = () => {
        if (isFiltered) {
            setIsFiltered(false);
            clearFilterParams();
            return
        }
        setIsFiltered(true)
        filterModels();
    }

    const handleExportClick = async () => {
        setLoading(true);
        const response = await getAxiosWithToken({
            url: `/models/search?${searchParams.toString()}&list_all=true`
        })

        if (response.data && response.data.is_complete === true) {
            const datum = removeKeys(response.data.data, ['glb_file', 'usdz_file', 'updated_at', 'model_status', '_id', 'brand_id', 'model_slug']);
            addARLink(datum, 'referrers');
            downloadModelsAsExcel(datum);
            setLoading(false);
            // downloadModelsAsExcel(removeKeys(response.data.data.map((model) => {
            //     return {...model, brand: model.brand[0].brand_name}
            // }), 
            //     ['glb_file', 'usdz_file',  '_id']));
        }
    }


    return (
        <StyledPopover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={onClose}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'left',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
            }}>
            <Stack spacing={3}>
                <Box display={"flex"} alignItems={"center"} justifyContent={"space-between"}>
                    <Typography variant="qrealTitle">Filter Settings</Typography>
                    <Stack direction={"row"} spacing={1}>
                        {loading ? (
                            <Stack>

                                <QReviewIconButton className="spinner-border spinner-border-sm" alt="export" svg={LoadingIcon}>

                                </QReviewIconButton>
                            </Stack>
                        ) : (
                            <Button variant="contained" onClick={handleExportClick}>
                                <Stack direction={"row"}>
                                    <img src={DataIcon} alt="dataIcon" />
                                    <img src={DownIcon} alt="arrowDownIcon" />
                                </Stack>
                            </Button>
                        )}
                        {/* <Button variant="contained" onClick={handleExportClick}>
                            <Stack direction={"row"}>
                                <img src={DataIcon} alt="dataIcon" />
                                <img src={DownIcon} alt="arrowDownIcon" />
                            </Stack>
                        </Button> */}
                        <QReviewIconButton onClick={onClose} secondary svg={mode === "light" ? CloseIconBlack : CloseIconWhite} alt="closeIcon" />
                    </Stack>
                </Box>
                <Stack spacing={2}>
                    <FormControl variant="standard">
                        <Select
                            IconComponent={mode === "light" ? ArrowDownIconBlack : ArrowDownIconWhite}
                            onChange={async (e) => {
                                setIsFiltered(false)
                                setSettings(
                                    {
                                        ...settings,
                                        status: e.target.value as string
                                    }
                                )
                            }}
                            value={settings.status}
                            displayEmpty
                        >
                            <MenuItem value="" disabled>Status</MenuItem>
                            {Object.entries(ModelStatusList).map((status, index) => (
                                <MenuItem key={'status-' + index} value={status[0]}>{status[1]}</MenuItem>
                            ))
                            }
                        </Select>
                    </FormControl>
                    {
                        checkAuth(authList, authKeys.create_model) &&
                        <>
                            <FormControl variant="standard">
                                <Select
                                    IconComponent={mode === "light" ? ArrowDownIconBlack : ArrowDownIconWhite}
                                    onChange={async (e) => {
                                        setIsFiltered(false)
                                        setSettings(
                                            {
                                                ...settings,
                                                category: e.target.value as string
                                            }
                                        )
                                    }}
                                    value={settings.category}
                                    displayEmpty
                                >
                                    <MenuItem value="" disabled>Category</MenuItem>
                                    {Object.entries(ModelCategories).map((category, index) => (
                                        <MenuItem key={'category-' + index} value={category[1]}>{category[0]}</MenuItem>
                                    ))
                                    }
                                </Select>
                            </FormControl>
                            <FormControl variant="standard">
                                <Select
                                    IconComponent={mode === "light" ? ArrowDownIconBlack : ArrowDownIconWhite}
                                    onChange={async (e) => {
                                        setIsFiltered(false)
                                        setSettings(
                                            {
                                                ...settings,
                                                organization: e.target.value as string
                                            }
                                        )
                                    }}
                                    value={settings.organization}
                                    displayEmpty
                                >
                                    <MenuItem value="" disabled>Organization</MenuItem>
                                    {organizations.map((organization, index) => (
                                        <MenuItem key={'organization-' + index} value={organization._id} selected={settings.organization == organization._id}>{organization.organization_name}</MenuItem>
                                    ))
                                    }
                                </Select>
                            </FormControl>
                            <FormControl variant="standard">
                                <Select
                                    IconComponent={mode === "light" ? ArrowDownIconBlack : ArrowDownIconWhite}
                                    onChange={async (e) => {
                                        setIsFiltered(false)
                                        setSettings(
                                            {
                                                ...settings,
                                                brand: e.target.value as string
                                            }
                                        )
                                    }}
                                    value={settings.brand}
                                    displayEmpty
                                >
                                    <MenuItem value="" disabled>Brand</MenuItem>
                                    {brands.map((brand, index) => (
                                        <MenuItem key={'brand-' + index} value={brand._id}>{brand.brand_name}</MenuItem>
                                    ))
                                    }
                                </Select>
                            </FormControl>
                        </>
                    }
                </Stack>
                <Stack spacing={2}>
                    <ModelFilterCalendar />
                    <Button variant="contained" fullWidth onClick={handleFilterClick}>Filter Models</Button>
                </Stack>
            </Stack>
        </StyledPopover>
    )

}


const FilterModels = ({ setPerPage, setPage, setTotalRows, setData }) => {
    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'filter-popover' : undefined;
    const [loading, setLoading] = useState(false);
    //const [open, setOpen] = useState(false);
    const [organizations, setOrganizations] = useState<Organization[]>([]);
    const [brands, setBrands] = useState<Brand[]>([]);
    const { authList } = useAuth();

    useEffect(() => {
        if (checkAuth(authList, authKeys.create_model)) {
            if (organizations.length === 0) {
                getAxiosWithToken({
                    url: '/organizations?list_all=true',
                    authRequest: true
                }).then((response) => {
                    setLoading(false);
                    setOrganizations(response.data.data);
                });
            }

            if (brands.length === 0) {
                getAxiosWithToken({
                    url: '/brands?list_all=true'
                }).then((response) => {
                    setLoading(false);
                    setBrands(response.data.data);
                })
            }
        }
    }, []);

    const searchCall = async (response) => {
        setPerPage(response.per_page);
        setPage(response.page);
        setData(response.data);
        setTotalRows(response.total);
    }

    return (
        <>
            <QReviewIconButton svg={FilterIcon} alt="filter" aria-describedby={id} onClick={handleClick}>
                {/* {(brands.length == 0 || organizations.length == 0) ? <CircularProgress size={"small"} color="secondary"/> : <FilterIcon />} */}
            </QReviewIconButton>
            <FilterCard callUpdates={searchCall} open={open} id={id} anchorEl={anchorEl} onClose={handleClose} organizations={organizations} brands={brands} />
        </>
    );
};

export default FilterModels;