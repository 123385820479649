import ReactDOM from "react-dom/client"
import { BrowserRouter } from "react-router-dom"
import App from "./App"
import "./index.css"
import reportWebVitals from "./reportWebVitals"
import "bootstrap-icons/font/bootstrap-icons.css"
import { ThemeContextProvider } from "./theme/ThemeContextProvider"

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement)
root.render(
    // <React.StrictMode>
    <ThemeContextProvider>
        <BrowserRouter>
            <App />
        </BrowserRouter>
    </ThemeContextProvider>
    // </React.StrictMode>
)

reportWebVitals()
