

const StatusBadge = ({ status }) => {
  
    return (
        status === "ACTIVE" ? <span className="text-success">active</span> :
            <p className="text-warning">passive</p>
    );
}

export default StatusBadge;