import { defaultTRYOCameraOrbit } from "../enums/constants";
import { ModelCategories } from "../enums/modelCategories";
import { postAxiosWithToken } from "./axiosHelper";

export const createModel = async (args: { modelName: string; modelReferenceLink: string; modelReferenceFileCount: string, category: string; iframeLink: string, modelEnvironment, brandId: string, exposure: string, shadowIntensity: string, shadowSoftness: string}) => {
    type body = {
        model_name: string
        model_status: string
        reference_link: string
        reference_file_count: string
        environment_id: string
        iframe_link: string
        brand_id: string
        model_viewer_config: {
            "auto-rotate": boolean
            "auto-rotate-delay": string
            "direct-ar": boolean
            "exposure": string
            "shadow-softness": string
            "shadow-intensity": string
            "camera-orbit": string
            "interaction-prompt": boolean
            "interaction-prompt-threshold": string
            "disable-pan": boolean
        }
        category: string
    }
    let cameraOrbit;
    if (args.category === ModelCategories.EYEWEAR || args.category === ModelCategories.HATS || args.category === ModelCategories.SHOES) {
        cameraOrbit = defaultTRYOCameraOrbit
    } else {
        cameraOrbit = "auto auto auto"
    }
    const requestBody: body = {
        model_name: args.modelName,
        model_status: "ACTIVE",
        reference_link: args.modelReferenceLink,
        reference_file_count: args.modelReferenceFileCount,
        environment_id: args.modelEnvironment,
        iframe_link: args.iframeLink,
        brand_id: args.brandId,
        model_viewer_config: {
            "auto-rotate": false,
            "direct-ar": false,
            "exposure": args.exposure,
            "shadow-softness": args.shadowSoftness,
            "shadow-intensity": args.shadowIntensity,
            "camera-orbit": cameraOrbit,
            "auto-rotate-delay": "3000",
            "interaction-prompt": true,
            "interaction-prompt-threshold": "3000",
            "disable-pan": false,
        },
        category: args.category,
    }
    try {
        const response = await postAxiosWithToken({
            url: "/models",
            body: requestBody,
        });
        if (response && response.data) {
            if ("data" in response) {
                const { data } = response
                if (data) {
                    return {
                        error: null,
                        data: data,
                    }
                }
            }
        }
        return {
            error: response.errorMessages,
            data: null,
        }
    } catch (error) {
        return {
            error: "Something went wrong",
            data: null,
        }
    }
}
