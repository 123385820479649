import { useRef, useState } from "react"
import { Box, Button, ButtonGroup } from "@mui/material"
import ZoomInIcon from "@mui/icons-material/ZoomIn"
import ZoomOutIcon from "@mui/icons-material/ZoomOut"
import ZoomOutMapIcon from "@mui/icons-material/ZoomOutMap"
import { Zoom, Navigation, Pagination } from "swiper/modules"
import { Swiper, SwiperSlide } from "swiper/react"
import { Swiper as SwiperClass } from "swiper/types"
import "swiper/css"
import "swiper/css/zoom"
import "swiper/css/navigation"
import "swiper/css/pagination"
import "./index.css"

const CustomImageSlider = ({ imgArray }) => {
    const [zoomLevel, setZoomLevel] = useState(1)
    const swiperRef = useRef<any>(null)

    const handleZoomIn = () => {
        if (!swiperRef.current) return
        setZoomLevel((zoomLevel + 1))
        swiperRef.current.zoom.in(zoomLevel)
    }

    const handleZoomOut = () => {
        if (!swiperRef.current || zoomLevel === 1) return
        setZoomLevel((zoomLevel - 1))
        swiperRef.current.zoom.in(zoomLevel)
    }

    const handleZoomReset = () => {
        if (!swiperRef.current) return
        swiperRef.current.zoom.out()
        setZoomLevel(1)
    }

    const handleSwiper = (swiper: SwiperClass) => {
        swiperRef.current = swiper
    }

    return (
        <Box sx={{ height: "100%", background: "#f5f5f5" }}>
            <Swiper
                onSwiper={handleSwiper}
                navigation={true}
                pagination={{
                    clickable: true
                }}
                modules={[Zoom, Navigation, Pagination]}
                style={{ height: "100%" }}
                loop={true}
                zoom={true}
                onSlideChange={() => setZoomLevel(1)}
                init={true}
            >
                {imgArray.map((item, index) => (
                    <SwiperSlide key={index}>
                        <div className="swiper-zoom-container">
                            <img
                                src={item}
                                alt="carousel-item"
                            />
                        </div>
                    </SwiperSlide>
                ))}
                <ButtonGroup
                    variant="contained"
                    aria-label="outlined primary button group"
                    style={{ position: "absolute", bottom: "10px", right: "10px", zIndex: 1000 }}
                >
                    <Button
                        onClick={handleZoomIn}
                    >
                        <ZoomInIcon />
                    </Button>
                    <Button
                        onClick={handleZoomOut}
                    >
                        <ZoomOutIcon />
                    </Button>
                    <Button
                        onClick={handleZoomReset}
                    >
                        <ZoomOutMapIcon />
                    </Button>
                </ButtonGroup>
            </Swiper>
        </Box>
    )
}

export default CustomImageSlider