
export const responseMessage = (response) => {
    if(response.data && response.data.is_complete) {
        return {
            status: true,
            data: response.data
        };
    } else {
        return {
            status: false,
            message: 'Something went wrong. Please try again later.'
        }
    }
}