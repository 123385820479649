import { Tooltip, Typography } from '@mui/material'
import React from 'react'

interface ModelNameDisplayerProps{
    modelName: string
}

const ModelNameDisplayer = ({modelName}: ModelNameDisplayerProps) => {
  return (
    <Tooltip title={modelName} placement='top-end'>
        <Typography variant='qrealBodyMedium' noWrap>{modelName}</Typography>
    </Tooltip>
  )
}

export default ModelNameDisplayer