import "@google/model-viewer/dist/model-viewer"
import { useEffect, useRef, useState } from "react"
import "react-step-progress-bar/styles.css"
import { SSType, defaultTRYOCameraOrbit } from "../../enums/constants"
import { ModelCategories } from "../../enums/modelCategories"
import { getAxiosWithToken } from "../../functions/axiosHelper"
import { generatePoster } from "../../functions/generatePoster"
import { Model } from "../../providers/AppProvider"
import { useCreateModel } from "../../providers/CreateModelProvider"
import { Hdr } from "../../types/hdr"

import { useMixpanel } from 'react-mixpanel-browser'
import ModelCreationContainer from "../../components/modeCreationSteps/ModelCreationContainer"
import { brandSort } from "../../functions/brandSort"

declare global {
    namespace JSX {
        interface IntrinsicElements {
            "model-viewer": any;
        }
    }
}

const steps = [
    {
        status: "Model Name",
    },
    {
        status: "Select Files",
    },
    {
        status: "Upload",
    },
    {
        status: "Done",
    },
]

const CreateModel = () => {
    const {
        modelName,
        setModelName,
        modelReferenceLink,
        setModelReferenceLink,
        modelReferenceFileCount,
        setModelReferenceFileCount,
        GLBFile,
        setGLBFile,
        USDZFile,
        setUSDZFile,
        HDRFile,
        environmentId,
        setEnvironmentId,
        ReferenceFile,
        setReferenceFile,
        createModelPending,
        isModelCreatedSuccessfully,
        setIsModelCreatedSuccessfully,
        createModelAsyncMethod,
        createProgress,
        setCreateProgress,
        category,
        setCategory,
        setBrand,
        exposure,
        shadowIntensity,
        shadowSoftness,
        setExposure,
        setShadowIntensity,
        setShadowSoftness,
        setModelIframeLink,
        modelIframeLink,
        posterFile,
        setPosterFile,
        thumbnailFile,
        setThumbnailFile
    } = useCreateModel()

    const [currentStep, setCurrentStep] = useState<number>(1);
    const [hdrList, setHdrList] = useState<Array<Hdr>>([]);
    const [hdrModal, setHdrModal] = useState(false);
    const [model, setModel] = useState<Model>({} as Model);
    const [brandList, setBrandList] = useState<Array<any>>([]);
    const [glbBlobURL, setGlbBlobURL] = useState<any>();
    const [hdrURL, setHdrURL] = useState<string>();
    const [cameraOrbit, setCameraOrbit] = useState<string>("");

    const mvRef = useRef<any>(null)

    const mixpanel = useMixpanel();

    useEffect(() => {
        if (hdrList.find(hdr => hdr._id === environmentId)?.hdr_file[0].url) {
            setHdrURL(hdrList.find(hdr => hdr._id === environmentId)?.hdr_file[0].url)
        }

    }, [environmentId, hdrList])


    useEffect(() => {
        if (isModelCreatedSuccessfully) setCurrentStep(4)
    }, [isModelCreatedSuccessfully])

    const getHdrList = async () => {
        const response = await getAxiosWithToken({
            url: "/environments?list_all=true",
        })
        setHdrList(response.data.data);
        setEnvironmentId(response.data.data[0]._id)
    }

    useEffect(() => {
        if (currentStep == 2) {
            getHdrList();
        }
    }, [currentStep])

    const getBrands = async () => {
        if (brandList.length == 0) {
            const response = await getAxiosWithToken({
                url: '/brands?list_all=true',
            });
            setBrandList(response.data.data.sort(brandSort));
        }
    }

    useEffect(() => {
        getBrands();
    }, [])

    useEffect(() => {
        if (GLBFile) {
            const url = URL.createObjectURL(GLBFile[0])
            setGlbBlobURL(url)
        }
        if (category === ModelCategories.EYEWEAR || category === ModelCategories.HATS || category === ModelCategories.SHOES) {
            setCameraOrbit(defaultTRYOCameraOrbit)
        } else {
            setCameraOrbit("auto auto auto")
        }
    }, [GLBFile, category])

    const onCreateModelClick = async () => {
        if (mvRef.current) {
            const generatedThumbnail = await generatePoster(mvRef, SSType.Thumbnail, modelName, true)
            setThumbnailFile(generatedThumbnail)
            const generatedPoster = await generatePoster(mvRef, SSType.Poster, modelName, true)
            setPosterFile(generatedPoster)
        }
    }

    return (
        <>
            <ModelCreationContainer/>
        </>
    )
}

export default CreateModel
