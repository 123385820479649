export const defaultTRYOCameraOrbit: string = "43.62deg 71.51deg auto"

export enum SSType {
    Poster = "POSTER",
    Thumbnail = "THUMBNAIL",
}

export const s3Origin: string = "mopu-bucket.s3.us-east-1.amazonaws.com"
export const cdnOrigin: string = "d1sl4ptrrp4zl4.cloudfront.net"
export const cdnFullURL: string = "https://d1sl4ptrrp4zl4.cloudfront.net"
export const s3BucketName: string = "mopu-bucket"