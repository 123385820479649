import { AxiosResponse } from "axios"
import { putAxiosWithToken } from "./axiosHelper"

export const increaseModelViewCount = async (currentCount, id: String) => {
    try {
        const response = await putAxiosWithToken({
            url: `/models/${id}`,
            body: { "view_count": (currentCount + 1) }
        })

    } catch (error) {
        console.error(error)
    }
}

export const updateModel = async (data, id: String) => {
    try {
        const response = await putAxiosWithToken({
            url: `/models/${id}`,
            body: data
        }) as AxiosResponse<any, any>

        if (response.status === 200) {
            return {
                error: null,
                data: response.data,
            }
        } else {
            return {
                error: "Something went wrong",
                data: null,
            }
        }
    } catch (error) {
        return {
            error: "Something went wrong",
            data: null,
        }
    }
}
