import { deleteAxiosWithToken } from "./axiosHelper"

export const deleteModel = async (id: String) => {
    try {
        const response = await deleteAxiosWithToken({
            url: `/models/${id}`
        })
        if (response) {
            if ("data" in response) {
                const { data } = response
                if (data) {
                    return {
                        error: null,
                        data: data,
                    }
                }
            }
        }
        return {
            error: "Something went wrong",
            data: null,
        }
    } catch (error) {
        return {
            error: "Something went wrong",
            data: null,
        }
    }
}
