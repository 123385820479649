import { Box, Button, Container, Grid, Stack, Typography } from "@mui/material";
import { useAuth } from 'qreal-auth-provider/src';
import { useEffect, useMemo, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import CreateReferrer from '../../components/CreateReferrer';
import DebouncedInput from '../../components/elements/DebouncedInput';
import DeleteReferrerConfirmationModal from '../../components/modals/DeleteReferrerConfirmation.Modal';
import StatusBadge from '../../components/shared/table-items/StatusBadge';
import QReviewDefaultTable from '../../components/tables/QReviewDefaultTable';
import { authKeys, checkAuth } from '../../enums/authKeys';
import { getAxiosWithToken, putAxiosWithToken } from '../../functions/axiosHelper';
import { getModel } from '../../functions/getModel';
import { Model } from '../../providers/AppProvider';
import { Organization } from '../../types/organization';
import { UserRoles } from '../../types/user';

import { useMixpanel } from 'react-mixpanel-browser';
import EyeIconBlack from '../../assets/icons/Eye-Icon-Black.svg';
import EyeIconWhite from '../../assets/icons/Eye-Icon-White.svg';
import ShareIconBlack from '../../assets/icons/Share-Icon-Black.svg';
import ShareIconWhite from '../../assets/icons/Share-Icon-White.svg';
import TrashIconBlack from '../../assets/icons/Trash-Icon-Black.svg';
import TrashIconWhite from '../../assets/icons/Trash-Icon-White.svg';
import QReviewActionButton from '../../components/QReviewButton/QReviewActionButton';

import { useThemeContext } from '../../theme/ThemeContextProvider';

interface Referrer {
    _id: string
    referrer_name: string
    external_id: string
    referrer_status: string
    referrer_view_count: number
    organization_id: string
}

const ModelReferrer = () => {
    const { mode } = useThemeContext();
    const { authList, user } = useAuth();
    const [loading, setLoading] = useState(false);
    const [selectedReferrer, setSelectedReferrer] = useState<Referrer | null>(null);
    const [open, setOpen] = useState(false);

    const params = useParams()
    const [tableData, setTableData] = useState<Referrer[]>([]);
    const [organizations, setOrganizations] = useState<Organization[]>([]);
    const { id } = useParams()
    const [currentModel, setCurrentModel] = useState<Model>({} as Model)

    const mixpanel = useMixpanel();

    const ActionButtons = ({ row }) => (
        <>
            <Stack direction="row" spacing={2}>

                {checkAuth(authList, authKeys.delete_referrer) &&
                    <Box onClick={() => {
                        setSelectedReferrer(row);
                    }}>
                        <QReviewActionButton icon={mode === "light" ? TrashIconBlack : TrashIconWhite} tooltip='Delete Referrer' />
                    </Box>
                }

                <Box component={Link} to={"/share/" + row._id} target={"_blank"}>
                    <QReviewActionButton icon={mode === "light" ? EyeIconBlack : EyeIconWhite} tooltip='Show Referrer' />
                </Box>


                <Box component={Link} to={"/ar/" + row._id} target={"_blank"}>
                    <QReviewActionButton icon={mode === "light" ? ShareIconBlack : ShareIconWhite} tooltip='View in AR' />
                </Box>
            </Stack>

        </>
    );

    const getAdditionalColumn = () => {
        if (checkAuth(authList, authKeys.update_referrer)) {
            return [
                {
                    name: 'Organization',
                    sortable: true,
                    grow: 1,
                    cell: row => <span id={row._id} children={<span className='white-text'>{organizations.find(organizaiton => organizaiton._id == row.organization_id)?.organization_name}</span>}></span>,
                    selector: row => organizations.find(organizaiton => organizaiton._id == row.organization_id)?.organization_name,
                },
                {
                    name: 'Referrer Status',
                    sortable: true,
                    grow: 1,
                    cell: row => <span id={row._id} children={<select
                        className="form-select"
                        aria-label="Default select example"
                        onChange={e => setStatus(row._id, e.target.value)}
                    >
                        <option value="ACTIVE" selected={row.referrer_status == 'ACTIVE'}>Active</option>
                        <option value="PASSIVE" selected={row.referrer_status == 'PASSIVE'}>Passive</option>
                    </select>}></span>,
                    selector: row => row.referrer_status,
                },
            ]
        } else {
            return [
                {
                    name: 'Referrer Status',
                    sortable: true,
                    cell: (row: Referrer) => <StatusBadge status={row.referrer_status} />,
                },
            ]
        }
    }

    const columns = [
        {
            name: 'Referrer Id',
            sortable: true,
            grow: 2,
            style: { paddingLeft: '10px' },
            cell: row => <span id={row._id} children={row._id}></span>,
            selector: row => row._id,
            // selector: (row: Referrer) => <div className='white-text' style={{ cursor: 'pointer' }} onClick={() => navigator.clipboard.writeText(row._id)}>{row._id}</div> as any,
        },
        {
            name: 'External Id',
            sortable: true,
            grow: 1,
            cell: row => <span id={row.external_id} children={<DebouncedInput
                data={row.external_id}
                handleInputChange={(value: string) => {
                    updateExternalId(row._id, value);
                }}
            />}></span>,
            selector: row => row.external_id,
        },
        {
            name: 'View',
            sortable: true,
            grow: 1,
            cell: row => <span id={row.referrer_view_count} children={row.referrer_view_count}></span>,
            selector: row => row.referrer_view_count,
        },

        ...getAdditionalColumn(),
        {
            name: 'Actions',
            grow: 1,
            // style: { minWidth: '180px' },
            cell: (row: Referrer) => <ActionButtons row={row} />,
        },
    ];



    const getReferrers = async () => {
        setLoading(true);
        await getAxiosWithToken({
            url: `/referrers/by-model-id/${id}`
        }).then(res => {
            setLoading(false);
            setTableData(res.data.data);
        }).catch(err => err.response);
    }

    const setStatus = async (id: string, status: string) => {
        setLoading(true);
        await putAxiosWithToken({
            url: `/referrers/${id}`,
            body: {
                referrer_status: status
            }
        }).then(res => {
            setLoading(false);
            getReferrers();
        }).catch(err => err.response);
    }

    const updateExternalId = async (id: string, externalId: string) => {
        setLoading(true);
        await putAxiosWithToken({
            url: `/referrers/${id}`,
            body: {
                external_id: externalId
            }
        }).then(res => {
            setLoading(false);
            getReferrers();
        }).catch(err => err.response);
    }


    useEffect(() => {
        if (organizations.length === 0) {
            getAxiosWithToken({
                url: '/organizations?list_all=true',
                authRequest: true
            }).then((response) => {
                setOrganizations(response.data.data);
            });
        }
        if (id) {
            getReferrers();
        }
        getModel(params.id as string).then((res) => {
            setCurrentModel(res.data.data[0])
        })

    }, []);

    const getOrganizationOptions = () => {
        if (user.role === UserRoles.CUSTOMER) {
            if (tableData.some(tableRow => tableRow.organization_id == user.organization._id)) {
                return []
            } else {
                return organizations.filter(organization => organization._id == user.organization._id)
            }
        }
        return organizations.filter(organization => !tableData.some(tableRow => tableRow.organization_id == organization._id))
    }

    return useMemo(() => (
        <>

            <Container maxWidth="xl">

                <Grid container mt={4} mb={2} spacing={4}>
                    <Grid item container xs={12} md={12} alignItems={"center"}>
                        <Box>
                            <Typography variant="h4">Referrer List</Typography>
                        </Box>
                    </Grid>
                    <Grid item container xs={12} md={5} alignItems={"center"}>
                        {currentModel.model_name ?
                            <Typography variant="qrealSubtitle">{currentModel.brand[0] ? currentModel.brand[0].brand_name : null} - {currentModel.model_name} </Typography> : null}
                    </Grid>

                    <Grid item xs={12} md={7} container justifyContent={"flex-end"}>

                        <Stack direction={"row"} spacing={1.5}>
                            {checkAuth(authList, authKeys.create_referrer) &&
                                <Button variant='contained' onClick={() => setOpen(!open)}>Create</Button>
                            }
                        </Stack>
                    </Grid>
                </Grid>

                <QReviewDefaultTable
                    columns={columns}
                    data={tableData}
                    pagination={true}
                    progressPending={loading}
                />
                {(open && checkAuth(authList, authKeys.create_referrer)) && <CreateReferrer organizations={getOrganizationOptions()} modelId={id as string} callBack={getReferrers} />}

            </Container>

            <DeleteReferrerConfirmationModal
                selectedModelId={selectedReferrer?._id as string}
                closeModal={() => {
                    getReferrers();
                    setSelectedReferrer(null);
                }}
                isOpen={selectedReferrer != null}
            />

        </>
    ), [tableData, loading, open, authList, selectedReferrer, organizations])
}

export default ModelReferrer;
