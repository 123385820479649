import { getAxiosWithToken } from "../functions/axiosHelper"
import { responseMessage } from "./helper";

export const searchModels = async (queryParams: string) => {
    const response = await getAxiosWithToken({
        url: `/models/search?${queryParams}`
    })

    return responseMessage(response);
    
}
