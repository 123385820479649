import React, { useEffect } from "react"
import { useEditModel } from "../../providers/EditModelProvider"
import CustomDropzone from "../CustomDropzone";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material"
import QReviewBtn from "../QReviewButton/QReviewBtn";

type Props = {
    isOpen: boolean
    closeModal: () => any
    currentModel: any
    editType: string
}
const EditModelConfirmationModal: React.FC<Props> = ({
    isOpen,
    closeModal,
    currentModel,
    editType,
}) => {
    const {
        setModelId,
        setModelName,
        setModelStatus,
        setModelReferenceLink,
        setModelReferenceFileCount,
        setGLBFile,
        setUSDZFile,
        setHDRFile,
        setReferenceFile,
        setPosterFile,
        editGLBModelAsyncMethod,
        editUSDZModelAsyncMethod,
        editHDRModelAsyncMethod,
        editReferenceModelAsyncMethod,
        editPosterAsyncMethod,
        editModelPending,
        isModelEditedSuccessfully,
    } = useEditModel()

    setModelId(currentModel._id)
    setModelName(currentModel.model_name)
    setModelStatus(currentModel.model_status)
    setModelReferenceLink(currentModel.reference_link)

    const handleReferenceFile = (file) => {
        setModelReferenceFileCount(file.length)
        setReferenceFile(file)
    }

    return (
        <Dialog
            open={isOpen}
            onClose={closeModal}
            fullWidth={true}
            maxWidth={'xs'}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
                {"Edit Model File Confirmation"}
            </DialogTitle>
            <DialogContent>
                {editType === "GLB" ? (
                    <CustomDropzone
                        setFile={setGLBFile}
                        fileName={"GLB"}
                        isMultiple={false}
                        accept={{ 'model/gltf-binary': ['.glb'] }}
                        maxFiles={1}
                    />
                ) : null}
                {editType === "USDZ" ? (
                    <CustomDropzone
                        setFile={setUSDZFile}
                        fileName={"USDZ"}
                        isMultiple={false}
                        accept={{ 'model/vnd.usdz+zip': ['.usdz'] }}
                        maxFiles={1}
                    />
                ) : null}
                {editType === "HDR" ? (
                    <CustomDropzone
                        setFile={setHDRFile}
                        fileName={"HDR"}
                        isMultiple={false}
                        accept={{ 'application/octet-stream': ['.hdr'] }}
                        maxFiles={1}
                    />
                ) : null}
                {editType === "Reference" ? (
                    <CustomDropzone
                        // setFile={setReferenceFile}
                        setFile={(file) => {
                            handleReferenceFile(file)
                        }}
                        fileName={"REFERENCE"}
                        isMultiple={true}
                        accept={{ 'image/jpeg': ['.jpeg', '.jpg'] }}
                        maxFiles={10}
                    />
                ) : null}
                {editType === "Poster" ? (
                    <CustomDropzone
                        setFile={setPosterFile}
                        fileName={"POSTER"}
                        isMultiple={false}
                        accept={{ 'image/webp': ['.webp'] }}
                        maxFiles={1}
                    />
                ) : null}
            </DialogContent>
            <DialogActions sx={{ margin: '16px' }}>
                <QReviewBtn type="submit" onClick={isModelEditedSuccessfully
                    ? () => { }
                    : () => {
                        if (editType === "GLB") {
                            editGLBModelAsyncMethod()
                        } else if (editType === "USDZ") {
                            editUSDZModelAsyncMethod()
                        } else if (editType === "HDR") {
                            editHDRModelAsyncMethod()
                        } else if (editType === "Reference") {
                            editReferenceModelAsyncMethod()
                        } else if (editType === "Poster") {
                            editPosterAsyncMethod()
                        }
                    }}>
                    {isModelEditedSuccessfully ? (
                        <div>
                            <i className="bi-check2-square"></i>
                            <span className="ps-1">Edited</span>
                        </div>
                    ) : editModelPending ? (
                        <div>
                            <span className="spinner-border spinner-border-sm"></span>
                            <span className="ps-1">Edit</span>
                        </div>
                    ) : (
                        "Edit"
                    )}
                </QReviewBtn>
                <QReviewBtn type="button" onClick={closeModal}>
                    Close
                </QReviewBtn>
            </DialogActions>
        </Dialog>
    )
}

export default EditModelConfirmationModal
