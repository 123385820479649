import { Autocomplete, TextField } from "@mui/material";


const SingleAutoCompleteInput = ({
    options, setSelectedInput, optionLabelKey, inputLabel
}: {
    setSelectedInput: (input: any) => void,
    options: any,
    optionLabelKey: string,
    inputLabel: string
}) => {

    return (<Autocomplete
        onChange={(event: any, newValue: any) => {
            setSelectedInput(newValue);
        }}
        size="small"
        fullWidth
        getOptionLabel={(option) => option[optionLabelKey]}
        options={options}
        renderInput={(params) => <TextField {...params} label={inputLabel} />}
    />)
}

export default SingleAutoCompleteInput