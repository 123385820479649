import React, { useEffect } from "react";
import Navbar from "../components/shared/Navbar";
import { Outlet } from "react-router-dom";

export const Layout = (children) => {

    return (
        <>
            <Navbar/>
            <main>
                <Outlet/>
            </main>
        </>
    );
};