import { Card, CardHeader, CardContent, Grid } from "@mui/material"
import QMMCard from "./qmm/qmmCard"
import { Model } from "../../../providers/AppProvider"

type ServiceSharingCardProps = {
    currentModel: Model
}

const ServiceSharing = (props: ServiceSharingCardProps) => {

    return (
        <>
            <Grid container spacing={2} pt={3}>
                <Grid item xs={12} md={12}>
                    <Card>
                        <CardHeader color={"info"} title="QReal Magic Mirror" />
                        <CardContent>
                            <QMMCard
                                currentModel={props.currentModel}
                            />
                        </CardContent>
                    </Card>
                </Grid>
                {
                    /*
                    <Grid item xs={12} md={12}>
                        <Card>
                            <CardHeader title="TRYO" />
                            <CardContent>
                                <TryoCard />
                            </CardContent>
                        </Card>
                    </Grid>
                     */
                }
            </Grid>
        </>
    )
}

export default ServiceSharing