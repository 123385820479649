import { useAuth } from 'qreal-auth-provider/src'
import { useEffect, useRef, useState } from "react"
import { useParams } from "react-router-dom"
import Split from 'react-split'
import EditIcon from '../assets/icons/Edit-Icon.svg'
import { authKeys, checkAuth } from "../enums/authKeys"
import { getAxiosWithToken, getFileSize } from "../functions/axiosHelper"
import { cdnToS3URLChanger } from "../functions/cdnToS3URLChanger"
import { formatBytes } from "../functions/formatNumber"
import { getHdrList } from "../functions/getEnvironments"
import { getModel } from "../functions/getModel"
import { getModelComments } from "../functions/getModelComments"
import { Model } from "../providers/AppProvider"
import { Hdr } from "../types/hdr"
import CustomModelViewer from "./CustomModelViewer"
import IframeContainer from "./IframeContainer"
import ModelSettings from "./editModelSettings/ModelSettings"
import CustomImageSlider from "./imageSlider/CustomImageSlider"
import ModelBottomArea from "./splitScreen/bottomArea"
import Loading from "./template/Loading"

import { Button, Card, CardHeader, Container, FormControlLabel, Grid, Popper, Stack, Switch } from "@mui/material"
import { useMixpanel } from 'react-mixpanel-browser'
import { brandSort } from "../functions/brandSort"
import QReviewIconButton from "./QReviewButton/QReviewIconButton"
import ModelStatusModule from "./splitScreen/modelStatus"
import DownloadFilesButton from './DownloadFilesButton'

const SplitScreen = () => {

    // States and Hooks
    const { id } = useParams()
    const [currentModel, setCurrentModel] = useState<Model>({} as Model)
    const [selectedImage, setSelectedImage] = useState('')
    const [showIframe, setShowIframe] = useState(false)
    const [modelName, setModelName] = useState("")
    const [modelReferenceLink, setModelReferenceLink] = useState("")
    const [modelReferenceFileCount, setModelReferenceFileCount] = useState("")
    const [isLoading, setIsLoading] = useState(true)
    const [comments, setComments] = useState([])
    const [modelId, setModelId] = useState("")

    const { authList } = useAuth();

    //modelviewer settings
    const mvRef = useRef<any>()
    const mvContentRef = useRef<any>()
    const [iframeLink, setIframeLink] = useState("")
    const [cameraOrbit, setCameraOrbit] = useState("")
    const [minCameraOrbit, setMinCameraOrbit] = useState("")
    const [minFOV, setMinFOV] = useState("")
    const [environment, setEnvironment] = useState<Hdr>()
    const [exposure, setExposure] = useState(0)
    const [shadowIntensity, setShadowIntensity] = useState(0)
    const [shadowSoftness, setShadowSoftness] = useState(0)
    const [directAR, setDirectAR] = useState(false)
    const [autoRotate, setAutoRotate] = useState(false)
    const [autoRotateDelay, setAutoRotateDelay] = useState(3000)
    const [interactionPrompt, setInteractionPrompt] = useState(true)
    const [interactionPromptThreshold, setInteractionPromptThreshold] = useState(3000)
    const [disablePan, setDisablePan] = useState(false)

    const modelEvent = 'Model Load';
    const mixpanel = useMixpanel();
    // mixpanel.track_pageview({"page": "Split Screen"});
    mixpanel.time_event(modelEvent);

    //hdr select
    const [hdrList, setHdrList] = useState<Array<Hdr>>([]);
    useEffect(() => {
        if (checkAuth(authList, authKeys.edit_model)) {
            getHdrList().then((res) => {
                setHdrList(res.data.data)
            })
        }
    }, [])

    //brand select
    const [brand, setBrand] = useState<any>()
    const [brandList, setBrandList] = useState<any>([]);
    const getBrands = async () => {
        if (checkAuth(authList, authKeys.edit_model)) {
            if (brandList.length == 0) {
                const response = await getAxiosWithToken({
                    url: '/brands?list_all=true',
                });
                setBrandList(response.data.data.sort(brandSort));
            }
        }
    }

    const getModelRequest = (id) => {
        const modelResponse = getModel(id).then((res: any) => {
            let currentModel = res.data.data[0]
            setEnvironment(currentModel.environment[0])
            setIframeLink(currentModel.iframe_link)
            setShowIframe(currentModel.iframe_link ? true : false)
            setBrand(currentModel.brand[0] ? currentModel.brand[0]?._id : "")
            setExposure(Number(currentModel.model_viewer_config["exposure"]))
            setShadowIntensity(Number(currentModel.model_viewer_config["shadow-intensity"]))
            setShadowSoftness(Number(currentModel.model_viewer_config["shadow-softness"]))
            setAutoRotate(currentModel.model_viewer_config["auto-rotate"])
            setAutoRotateDelay(currentModel.model_viewer_config["auto-rotate-delay"] ? Number(currentModel.model_viewer_config["auto-rotate-delay"]) : 3000)
            setDirectAR(currentModel.model_viewer_config["direct-ar"])
            setCameraOrbit(currentModel.model_viewer_config["camera-orbit"])
            setMinCameraOrbit(currentModel.model_viewer_config["min-camera-orbit"])
            setMinFOV(currentModel.model_viewer_config["min-field-of-view"])
            setCurrentModel(currentModel)
            setModelReferenceLink(currentModel.reference_link + "")
            setModelName(currentModel.model_name + "")
            setModelId(currentModel._id + "")
            setInteractionPrompt(currentModel.model_viewer_config["interaction-prompt"])
            setInteractionPromptThreshold(currentModel.model_viewer_config["interaction-prompt-threshold"] ? Number(currentModel.model_viewer_config["interaction-prompt-threshold"]) : 3000)
            setDisablePan(currentModel.model_viewer_config["disable-pan"])

            setSelectedImage(currentModel.reference_file[0]);
            setIsLoading(false)

            getFileSize(currentModel.environment.length > 0 ? cdnToS3URLChanger(currentModel.environment[0].hdr_file[0].url) : cdnToS3URLChanger(currentModel.hdr_file[0])).then(res => {
                setCurrentModel(prev => {
                    return { ...prev, hdr_size: formatBytes(res as string) }
                })
            })
            getFileSize(cdnToS3URLChanger(currentModel.glb_file[0])).then(res => {
                setCurrentModel(prev => {
                    return { ...prev, glb_size: formatBytes(res as string) }
                })
            })
            getFileSize(cdnToS3URLChanger(currentModel.usdz_file[0])).then((res: string | number) => {
                setCurrentModel(prev => {
                    return { ...prev, usdz_size: formatBytes(res) }
                })
            })

            if(currentModel.thumbnail_file){
                getFileSize(cdnToS3URLChanger(currentModel.thumbnail_file[0])).then((res: string | number) => {
                    setCurrentModel(prev => {
                        return {...prev, thumbnail_size: formatBytes(res)}
                    })
                })
            }
        })
    }


    useEffect(() => {
        getBrands();
    }, [])

    useEffect(() => {
        if (isLoading) {
            if (id) {
                getModelRequest(id);

                const commentsResponse = getModelComments(id).then(res => {
                    setComments(res.data.data)
                })
            }
        }

        if (!isLoading) {

            if (!mixpanel) {
                return;
            }
            if (!currentModel.model_name) {
                return;
            }
            mixpanel.track(modelEvent, {
                'Model Name': currentModel.model_name,
                'Brand': brand,
            });
        }
    }, [isLoading, mixpanel])

    const [anchorEl, setAnchorEl] = useState(null);

    const handleClick = (event) => {
        setAnchorEl(anchorEl ? null : event.currentTarget);
    };

    const open = Boolean(anchorEl);

    return isLoading || !currentModel ? (
        <div className="h-100vh d-flex align-items-center justify-content-center flex-column">
            <Loading dark={true} />
        </div>
    ) : (

        <Container maxWidth={false} sx={{ padding: '0px !important', margin: '0px' }}>
            <Stack direction={'column'}>
                <Split
                    className="split"
                    style={{ height: 'calc(80vh - 4rem)', padding: 0 }}
                >
                    <Stack style={{ position: "relative" }}>
                        <Grid container style={{ position: "absolute", display: 'flex', top: 10, right: 10, zIndex: 10 }} direction={'column'} justifyContent={'flex-end'}>
                            {currentModel.iframe_link &&
                                <>
                                    <Grid item xs={6} style={{ display: 'flex' }} justifyContent={'flex-end'}>
                                        <FormControlLabel
                                            labelPlacement="start"
                                            control={
                                                <Switch checked={showIframe} onChange={() => setShowIframe(prev => !prev)} />
                                            }
                                            id="custom-switch"
                                            label={"Iframe Mode"}
                                            color='info'
                                        />
                                    </Grid>
                                </>
                            }

                            {checkAuth(authList, authKeys.edit_model) &&
                                <>
                                    <Grid item xs={6} style={{ display: 'flex' }} justifyContent={'flex-end'}>
                                        <QReviewIconButton svg={EditIcon} alt="edit model" onClick={handleClick} />
                                        <Popper className="qreview-bg" open={open} anchorEl={anchorEl} style={{ minWidth: "300px", zIndex: 15, width: '320px', borderRadius: '18px' }}>
                                            <Card className="qreview-bg" style={{ borderRadius: '18px' }}>
                                                <CardHeader className="qreview-bg white-text" title='Edit Model Settings' titleTypographyProps={{variant:'h6' }} sx={{maxHeight:'50px'}}/>
                                                {
                                                    isLoading || !currentModel ? null :
                                                        <ModelSettings
                                                            currentModel={currentModel}
                                                            mvRef={mvRef}
                                                            mvContentRef={mvContentRef}
                                                            cameraOrbit={cameraOrbit}
                                                            minCameraOrbit={minCameraOrbit}
                                                            minFOV={minFOV}
                                                            shadowIntensity={shadowIntensity}
                                                            shadowSoftness={shadowSoftness}
                                                            exposure={exposure}
                                                            directAR={directAR}
                                                            iframeLink={iframeLink}
                                                            setIframeLink={setIframeLink}
                                                            autoRotate={autoRotate}
                                                            setCameraOrbit={setCameraOrbit}
                                                            setMinCameraOrbit={setMinCameraOrbit}
                                                            setMinFOV={setMinFOV}
                                                            setShadowIntensity={setShadowIntensity}
                                                            setShadowSoftness={setShadowSoftness}
                                                            setExposure={setExposure}
                                                            setAutoRotate={setAutoRotate}
                                                            setDirectAR={setDirectAR}
                                                            environment={environment}
                                                            setEnvironment={setEnvironment}
                                                            hdrList={hdrList}
                                                            glbUrl={currentModel?.glb_file[0]}
                                                            usdzUrl={currentModel?.usdz_file[0]}
                                                            hdrUrl={currentModel.environment.length > 0 ? currentModel.environment[0].hdr_file[0].url : currentModel.hdr_file[0]}
                                                            hdrSize={currentModel?.hdr_size}
                                                            glbSize={currentModel?.glb_size}
                                                            usdzSize={currentModel?.usdz_size}
                                                            referenceFile={currentModel.reference_file[0]}
                                                            brandList={brandList}
                                                            brand={brand}
                                                            setBrand={setBrand}
                                                            modelName={modelName}
                                                            setModelName={setModelName}
                                                            modelReferenceLink={modelReferenceLink}
                                                            setModelReferenceLink={setModelReferenceLink}
                                                            modelReferenceFileCount={modelReferenceFileCount}
                                                            setModelReferenceFileCount={setModelReferenceFileCount}
                                                            autoRotateDelay={autoRotateDelay}
                                                            interactionPrompt={interactionPrompt}
                                                            interactionPromptThreshold={interactionPromptThreshold}
                                                            setAutoRotateDelay={setAutoRotateDelay}
                                                            setInteractionPrompt={setInteractionPrompt}
                                                            setInteractionPromptThreshold={setInteractionPromptThreshold}
                                                            disablePan={disablePan}
                                                            setDisablePan={setDisablePan}
                                                        />
                                                }
                                            </Card>
                                        </Popper>
                                    </Grid>
                                </>
                            }
                        </Grid>

                        {currentModel.iframe_link && showIframe ?
                            <IframeContainer
                                iframeLink={currentModel?.iframe_link} /> :
                            <CustomModelViewer
                                glbUrl={currentModel?.glb_file[0] + "?t=" + currentModel.updated_at}
                                usdzUrl={currentModel?.usdz_file[0] + "?t=" + currentModel.updated_at}
                                hdrUrl={environment ? environment!.hdr_file[0].url : currentModel.hdr_file[0]}
                                posterUrl={currentModel?.poster_file ? currentModel?.poster_file[0] + "?t=" + currentModel.updated_at : undefined}
                                shadowIntensity={shadowIntensity}
                                shadowSoftness={shadowSoftness}
                                exposure={exposure}
                                autoRotate={autoRotate}
                                autoRotateDelay={autoRotateDelay}
                                directAR={directAR}
                                mvRef={mvRef}
                                cameraOrbit={cameraOrbit}
                                minCameraOrbit={minCameraOrbit}
                                minFOV={minFOV}
                                mvContentRef={mvContentRef}
                                interactionPrompt={interactionPrompt}
                                interactionPromptThreshold={interactionPromptThreshold}
                                disablePan={disablePan} />

                        }

                        <Stack style={{ position: "absolute", bottom: 10, zIndex: 10 }} direction={"row"} justifyContent={checkAuth(authList, authKeys.download_files) ? "space-between" : "flex-end"} width={"100%"} px={2}>
                            
                            {checkAuth(authList, authKeys.download_files) && <DownloadFilesButton 
                            glbUrl={currentModel?.glb_file[0]}
                            usdzUrl={currentModel?.usdz_file[0]}
                            hdrUrl={currentModel.environment.length > 0 ? currentModel.environment[0].hdr_file[0].url : currentModel.hdr_file[0]}
                            hdrSize={currentModel?.hdr_size}
                            glbSize={currentModel?.glb_size}
                            usdzSize={currentModel?.usdz_size}
                            thumbnailUrl={currentModel?.thumbnail_file ? currentModel?.thumbnail_file[0]: ""}
                            thumbnailSize={currentModel?.thumbnail_file ? currentModel?.thumbnail_size : "0 Bytes"}
                            />}
                            {checkAuth(authList, authKeys.update_qa_status) && modelId && <ModelStatusModule
                                comments={comments}
                                modelStatus={currentModel.qa_status}
                                modelId={modelId} />
                            }
                            
                        </Stack>

                    </Stack>
                    <Stack>
                        <CustomImageSlider imgArray={currentModel.reference_file} />
                    </Stack>

                </Split>

                <Stack style={{ padding: '16px' }}>
                    <ModelBottomArea
                        authorizedOrganizations={currentModel.authorized_organizations}
                        comments={comments}
                        modelId={currentModel._id}
                        refetchCallback={() => getModelRequest(id)}
                        qaStatus={currentModel.qa_status}
                        currentModel={currentModel}
                    />
                </Stack>
            </Stack >

        </Container >
    )
}

export default SplitScreen
