import { useState } from "react";
import { useEffect } from "react";
import { authKeys, checkAuth } from "../../enums/authKeys";
import { useAuth } from 'qreal-auth-provider/src';
import Divider from "@mui/material/Divider";
import { AppBar, Box, BoxProps, Stack, Toolbar, Typography, styled, useTheme } from "@mui/material";
import ProfileButton from "../profileButton/ProfileButton";
import { UserRoles } from "../../types/user"
import QReviewActionButton from "../QReviewButton/QReviewActionButton";
import QreviewLogo from "../../assets/icons/QReview-Logo.svg"
import QReviewLogoBlack from "../../assets/icons/QReview-Logo-Black.svg"
import { QReviewUnstyledLink } from "./QReviewUnstyledLink";
import { QReviewNavItem } from "./QReviewNavItem";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useThemeContext } from "../../theme/ThemeContextProvider";
import HomeIconBlack from "../../assets/icons/Home-Icon-Black.svg"
import HomeIconWhite from "../../assets/icons/Home-Icon-White.svg"
import RightIconBlack from "../../assets/icons/Right-Arrow-Black.svg"
import RightIconWhite from "../../assets/icons/Right-Arrow-White.svg"
import { getModel } from "../../functions/getModel";
import { getBrand } from "../../functions/getBrand";


const StyledToolbar = styled(Toolbar)(({theme}) => ({
    background: theme.palette.background.paper,
    height: 80
}))

const StyledAppBar = styled(AppBar)(({theme}) => ({
    boxShadow: "none"
}))

const StyledDivider = styled(Divider)(({theme}) => ({
    opacity: 1,
}))

const NavItem = styled(Box)<BoxProps>(({theme}) => ({
    cursor: "pointer",
    marginLeft: 16,
    marginRight: 16,
    borderRadius: 100,
    paddingLeft: 16,
    paddingRight: 16,
    height: 38,
    display: "flex",
    alignItems: "center"
}))

interface Row {
    type: string, 
    name: string, 
    link: string, 
    permission?: string
}

const navs: Array<Row> = [
    {
        type: 'link',
        name: 'Models',
        link: '/',
    },
    {
        type: 'link',
        name: 'Brands',
        link: '/brand/list',
        permission: authKeys.list_brand
    },
    {
        type: 'link',
        name: 'Environments',
        link: '/environment/list',
        permission: authKeys.list_environment
    },
]

function Navbar() {
    const theme = useTheme()
    const { mode } = useThemeContext();
    let location = useLocation();
    let navigate = useNavigate()
    let { id } = useParams()
    const [modelName, setModelName] = useState("")

    const getModelName = (id: string) => {
        if(location.pathname.includes('brand')){
            getBrand(id).then(res => {
                setModelName(res.data.data[0].brand_name)
            })
        }else{
            getModel(id).then(res => {
                setModelName(res.data.data[0].model_name)
            })
        }
       
    }

    useEffect(() => {
      if(id){
        getModelName(id)
      }
    }, [id])

    const onBackClick = () => {
        // navigate(-2)
        navigate('/')
    }
    
    const { onLogout, authList, user } = useAuth();

    const renderNavItem = (nav: Row) => {
        if (nav.permission) {
            if (checkAuth(authList, nav.permission)) {
                return <Box component={QReviewNavItem} key={nav.name} to={nav.link!} bgcolor={location.pathname === nav.link ? theme.palette.primary.main : "transparent"}>
                    <Typography color={location.pathname === nav.link ? "black" : "inherit"} variant="qrealBody" noWrap>{nav.name}</Typography>
                </Box>
            }
        } else {
            return <Box component={QReviewNavItem} key={nav.name} to={nav.link!} bgcolor={location.pathname === nav.link ? theme.palette.primary.main : "transparent"}>
                <Typography color={location.pathname === nav.link ? "black" : "inherit"} variant="qrealBody" noWrap>{nav.name}</Typography>
            </Box>
        }
    }

    return (
        <StyledAppBar position="sticky">
            <StyledToolbar>
                <Box component={QReviewUnstyledLink} to={"/"}>
                    <QReviewActionButton icon={mode === "light" ? QReviewLogoBlack : QreviewLogo}/>
                </Box>
                <Box sx={{flexGrow:1, display:"flex"}} mx={6}>
                    {id ?
                     (
                        <Stack direction="row" spacing={2}>
                            <Box onClick={onBackClick} sx={{cursor:"pointer", display:"flex", alignItems:"center"}}>
                                <img src={mode === "light" ? HomeIconBlack : HomeIconWhite} alt="home"></img>
                            </Box>
                            <img src={mode === "light" ? RightIconBlack : RightIconWhite} alt="right"></img>
                            <Box py={1} px={2} bgcolor={theme.palette.primary.main} borderRadius={100} color={"black"}>
                                <Typography variant="qrealBodyCondRegular">{modelName}</Typography>
                            </Box>
                        </Stack>
                     )
                     :
                     ((user.role !== UserRoles.CUSTOMER) && navs.map((nav,index) => (
                        <>
                        {nav.type === 'link' && renderNavItem(nav)}
                        {(index < navs.length - 1 || navs.length === 1) ? <StyledDivider orientation="vertical" variant="middle" flexItem/> : null}
                        </>
                    )))}
                </Box>
                <Box>
                    <ProfileButton user={user} onLogout={onLogout} />
                </Box>
            </StyledToolbar>
        </StyledAppBar >
    )
}

export default Navbar;