import { Dialog, DialogActions, DialogContent, DialogTitle, Grid, MenuItem, Select, TextField } from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { getAxiosWithToken, putAxiosWithToken } from "../../functions/axiosHelper";
import CustomDropzone from "../CustomDropzone";
import InfoBox from "../InfoBox";
import QReviewBtn from "../QReviewButton/QReviewBtn";

type Props = {
    isOpen: boolean
    onEnd: () => any
    onCloseModal: () => any
    name: string,
    id: string,
}

interface Brand {
    name: string;
    status: 'ACTIVE' | 'PASSIVE';
    image_url: string;
    authorized_users: string[];
}

const EditBrandModal: React.FC<Props> = ({ onEnd, isOpen, onCloseModal, name, id }) => {
    const [brandImage, setBrandImage] = useState<File | null>(null);
    const [message, setMessage] = useState<string>('');
    const [processStatus, setProcessStatus] = useState<'info' | 'success'>('info');
    const [brandData, setBrandData] = useState<Brand>({} as Brand);
    const [isDataLoaded, setIsDataLoaded] = useState<boolean>(false);

    useEffect(() => {
        getBrand();
    }, []);

    const getBrand = async () => {
        const response = await getAxiosWithToken({
            url: `/brands/${id}`
        })

        if (response.data) {
            setBrandData({
                name: response.data.data[0].brand_name,
                status: response.data.data[0].brand_status,
                image_url: response.data.data[0].thumbnail_file[0].url,
                authorized_users: response.data.data[0].authorized_users
            })
        }
    }

    const onClickFunction = async () => {
        setProcessStatus('info');

        if (!brandData.name) {
            setMessage('Please enter brand name');
        } else {
            setMessage('Brand loading...');
        }

        const response = await putAxiosWithToken({
            url: `/brands/${id}`,
            body: {
                brand_name: brandData.name,
                brand_status: brandData.status,
                authorized_users: brandData.authorized_users,
                thumbnail_file: brandImage ? brandImage[0].name : null
            }
        });

        if (!response.data.data[0].thumbnail_file) {
            setMessage('Brand updated successfully');
            setProcessStatus('info');
            onEnd();
            return;
        }

        const uploadUrl = response.data.data[0].thumbnail_file.pre_signed_url;

        if (uploadUrl && brandImage) {
            const uploadResponse = await axios({
                method: "put",
                url: uploadUrl,
                headers: {
                    "Content-Type": "image/jpeg",
                },
                data: brandImage[0],
            })
            setMessage('Brand uploaded, image is uploading...');


            if (uploadResponse.status === 200) {
                setMessage('Image and brand uploaded successfully');
                setProcessStatus('success');
                setBrandData({} as Brand);
            } else {
                setMessage('Image couldn\'t be uploaded');
            }


        } else {
            setMessage('Brand updated successfully');
            setProcessStatus('success');
            onEnd();
        }
        onEnd();
    }

    useEffect(() => {
        if (!isOpen) {
            setMessage('');
        }
    }, [isOpen])

    useEffect(() => {
        if (Object.keys(brandData).length !== 0) {
            setIsDataLoaded(true)
        }
    }, [brandData])

    return (
        <Dialog
            open={isOpen && isDataLoaded}
            onClose={onCloseModal}
            fullWidth={true}
            maxWidth={'sm'}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
                {"Edit Brand \"" + name + "\""}
            </DialogTitle>
            <DialogContent>
                <Grid container py={2} spacing={3}>
                    <Grid item xs={12}>
                        <p>Current Brand Image:</p>
                    </Grid>
                    <Grid item xs={12}>
                        <img src={brandData.image_url} alt="preview_brand" style={{ maxHeight: '100px' }} height={100} />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField fullWidth onChange={(e) => {
                            setBrandData({
                                ...brandData,
                                name: e.target.value
                            });
                        }}
                            placeholder="Brand Name"
                            value={brandData.name}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <Select
                            fullWidth
                            labelId="brand-label"
                            id="brand-simple-select"
                            value={brandData.status || ""}
                            label="Status"
                            onChange={(e) => {
                                setBrandData({
                                    ...brandData,
                                    status: e.target.value as 'ACTIVE' | 'PASSIVE'
                                })
                            }}
                        >
                            <MenuItem value={'ACTIVE'}>Active</MenuItem>
                            <MenuItem value={'PASSIVE'}>Passive</MenuItem>
                        </Select>
                    </Grid>
                    <Grid item xs={12}>
                        <CustomDropzone
                            setFile={setBrandImage}
                            fileName={"Brand Image"}
                            isMultiple={false}
                            accept={{
                                'image/jpeg': []
                            }}
                            maxFiles={1}
                        />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions sx={{ margin: '16px' }}>
                <QReviewBtn type="submit" onClick={onClickFunction}>
                    Update
                </QReviewBtn>
                <QReviewBtn type="button" onClick={onCloseModal}>
                    Close
                </QReviewBtn>
            </DialogActions>
            {message &&
                <div className="container pb-3">
                    <InfoBox status={processStatus}>
                        {message}
                    </InfoBox>
                </div>
            }
        </Dialog>
    )
}

export default EditBrandModal

