import Home from "./pages/Home"
import { Route, Routes } from "react-router-dom"

// Components
import SplitScreen from "./components/SplitScreen"

//Providers
import AppProvider from "./providers/AppProvider"
import CreateModelProvider from "./providers/CreateModelProvider"
import CreateReferrerProvider from "./providers/CreateReferrerProvider"
import EditModelProvider from "./providers/EditModelProvider"

// Pages
import CreateModel from "./pages/model/Create"
import ShareModel from "./pages/ShareModel";
import Login from "./pages/Login";
import BrandList from "./pages/brand/List"
import EnvironmentList from "./pages/environment/List"
import DirectAR from "./pages/DirectAR"

// Authentication imports
import { AuthProvider } from 'qreal-auth-provider/src';
import { ProtectedRoute } from "./hooks/protectedRoute"
import { authKeys } from "./enums/authKeys"
import ModelReferrer from "./pages/model/Referrers"
import Subscriptions from "./pages/user/Subscriptions"
import { CssBaseline, ThemeProvider } from "@mui/material";
import { useThemeContext } from "./theme/ThemeContextProvider";

import { MixpanelProvider } from 'react-mixpanel-browser';
import { Layout } from "./pages/Layout"
const MIXPANEL_TOKEN = process.env.REACT_APP_MIXPANEL_TOKEN;
const MIXPANEL_CONFIG = {
    track_pageview: true,
    debug: false,
    persistence: 'localStorage',
};

function App() {
    const { theme } = useThemeContext();
    return (
        <MixpanelProvider config={MIXPANEL_CONFIG} token={MIXPANEL_TOKEN}>
            <AppProvider>
                <ThemeProvider theme={theme}>
                    <CssBaseline />
                    <CreateReferrerProvider>
                        <CreateModelProvider>
                            <EditModelProvider>
                                <AuthProvider>
                                    <Routes>
                                        <Route path="/sign-in" element={<Login />} />
                                        <Route path="/" element={<ProtectedRoute><Layout /></ProtectedRoute>}>
                                            <Route path="/" element={<ProtectedRoute><Home /></ProtectedRoute>} />
                                            <Route path="/:id" element={<ProtectedRoute pagePermissionKey={authKeys.list_model}><SplitScreen /></ProtectedRoute>} />
                                            <Route path="/model/create" element={<ProtectedRoute pagePermissionKey={authKeys.create_model}><CreateModel /></ProtectedRoute>} />
                                            <Route path="/brand/list" element={<ProtectedRoute pagePermissionKey={authKeys.list_brand}><BrandList /></ProtectedRoute>} />
                                            <Route path="/environment/list" element={<ProtectedRoute pagePermissionKey={authKeys.list_environment}><EnvironmentList /></ProtectedRoute>} />
                                            <Route path="/model/referrers/:id" element={<ProtectedRoute pagePermissionKey={authKeys.list_referrer}><ModelReferrer /></ProtectedRoute>} />
                                            <Route path="/subscriptions" element={<Subscriptions />} />
                                        </Route>
                                        <Route path="/share/:id" element={<ShareModel />} />
                                        <Route path="/ar/:id" element={<DirectAR />} />

                                    </Routes>
                                </AuthProvider>
                            </EditModelProvider>
                        </CreateModelProvider>
                    </CreateReferrerProvider>
                </ThemeProvider>
            </AppProvider>
        </MixpanelProvider>
    )
}

export default App;
