import { Tooltip } from '@mui/material'

interface QReviewActionButtonProps {
    icon: any
    tooltip?: string
}

const QReviewActionButton = ({icon, tooltip}: QReviewActionButtonProps) => {
  return (
    <Tooltip title={tooltip} placement='top'>
        <img src={icon} alt={tooltip}></img>
    </Tooltip>
  )
}

export default QReviewActionButton