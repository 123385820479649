import { Box, Button, ButtonProps, CircularProgress, Link, Popover, Stack, styled, Tooltip, Typography, useTheme } from '@mui/material'
import React, { useState } from 'react'
import CloseIcon from '@mui/icons-material/Close';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import Divider from '@mui/material/Divider';
import DoneAllIcon from '@mui/icons-material/DoneAll';

export const IconButton = styled(Button)<ButtonProps>(({theme}) => ({
  width: 40,
  height: 40,
  maxWidth: 40,
  maxHeight: 40,
  minWidth: 40,
  padding: 0,
  display: 'flex',
  alignItems: "center",
  justifyContent: "center",
  borderRadius: "50%",
  color: theme.palette.primary.main,
  border: `2px solid ${theme.palette.primary.main}`,
  backgroundColor: "transparent",
    "&:hover": {
        backgroundColor: theme.palette.background.paper,
    },
    "&:focus, &:hover, &:visited, &:link, &:active": {
      backgroundColor: theme.palette.background.paper
  },
}))

const DownloadFilesButton = ({
  glbSize,
  glbUrl,
  usdzSize,
  usdzUrl,
  hdrSize,
  hdrUrl,
  thumbnailUrl,
  thumbnailSize}) => {
  const theme = useTheme()
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [linkCopied, setLinkCopied] = useState(false);
  const [disableButton, setDisableButton] = useState(false);
  
  const files = [
    {label: "GLB", size: glbSize, url: glbUrl},
    {label: "USDZ", size: usdzSize, url: usdzUrl},
    {label: "HDR", size: hdrSize, url: hdrUrl},
    {label: "POSTER", size: thumbnailSize, url: thumbnailUrl}
  ]
  const [selectedFile, setSelectedFile] = useState(files[0])

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const changeSelectedFile = (file: {
    label: string;
    size: any;
    url: any;}) => {

      if (file.size === "0 Bytes") {
        setDisableButton(true)
      }else{
        setDisableButton(false)
      }
      setSelectedFile(file)
      
  }

  const handleCopyLink = () => {
    setLinkCopied(true);
    navigator.clipboard.writeText(selectedFile.url)
    setTimeout(() => {
      setLinkCopied(false)
    }, 3000);
  }

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

    return (
    <Box>
        <Button aria-describedby={id} variant="contained" onClick={handleClick}>Download</Button>
        <Popover 
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
        }}
        transformOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
        }}
        >
        <Stack>
            <Stack direction={"row"} px={1.5} py={1.25} alignItems={"center"} spacing={2} justifyContent={"space-between"} bgcolor={theme.palette.background.default}>
            <Typography variant='qrealH3'>Select Formats</Typography>
            <IconButton onClick={handleClose}>
              <CloseIcon/>
            </IconButton>
            </Stack>
            <Stack p={1.5} direction={"row"} justifyContent={"center"} alignItems={"center"}>
              {files.map((file, index) => (
                <Stack alignItems={"center"} onClick={() => changeSelectedFile(file)} px={1.25} sx={index !== files.length - 1 ? {cursor:"pointer", borderRight:"1px solid white"} : {cursor:"pointer"}}>
                  <Typography color={selectedFile.label === file.label ? "primary" : ""}  fontWeight={"bold"}>{file.label}</Typography>
                  {file.size ? 
                  <>
                  <Typography color={selectedFile.label === file.label ? "primary" : ""}>{file.size && file.size.split(" ")[0]}</Typography>
                  <Typography color={selectedFile.label === file.label ? "primary" : ""}>{file.size && file.size.split(" ")[1]}</Typography>
                  </>
                  :
                  <CircularProgress/>
                  }
                </Stack>
              ))}
            </Stack>
            <Stack direction={"row"} px={1.5} py={1.25} spacing={2} bgcolor={theme.palette.background.default}>
              <Link sx={{width:"100%"}} href={selectedFile.url} target={"_blank"}>
              <Button fullWidth disabled={disableButton}>Download</Button>
              </Link>
              <Tooltip placement='top' title={linkCopied ? "Link Copied" : "Copy Link"}>
              <IconButton onClick={handleCopyLink}>
                {linkCopied ? <DoneAllIcon/> : <ContentCopyIcon/>}
              </IconButton>
              </Tooltip>
            </Stack>
        </Stack>
        </Popover>
    </Box>
    )
}

export default DownloadFilesButton