import { Button, ButtonProps, styled } from "@mui/material";

export const QReviewStyledIconButton = styled(Button)<ButtonProps>(({ theme }) => ({
    width: 42,
    height: 42,
    padding: 0,
    minWidth: 42,
}))

export const QReviewStyledSecondaryIconButton = styled(Button)<ButtonProps>(({ theme }) => ({
    width: 42,
    height: 42,
    padding: 0,
    minWidth: 42,
    backgroundColor: theme.palette.background.paper,
    "&:hover": {
        backgroundColor: theme.palette.background.paper,
    }
}))

interface QReviewIconButtonProps {
    svg: string,
    alt: string,
    secondary?: boolean
}

const QReviewIconButton = (props: QReviewIconButtonProps & ButtonProps) => {
  return (
    <>
    {
    props.secondary ? 
        <QReviewStyledSecondaryIconButton {...props}>
            <img src={props.svg} alt={props.alt}/>
        </QReviewStyledSecondaryIconButton>
        :
        <QReviewStyledIconButton {...props}>
            <img src={props.svg} alt={props.alt}/>
        </QReviewStyledIconButton>
    }
    </>
  )
}

export default QReviewIconButton