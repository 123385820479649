import { putAxiosWithToken } from "./axiosHelper"

export const editModelViewerConfig = async (args: {
    modelId: string,
    exposure: string,
    shadowIntensity: string,
    shadowSoftness: string,
    autoRotate: boolean,
    autoRotateDelay: string,
    directAR: boolean,
    cameraOrbit: string,
    minCameraOrbit: string,
    minFOV: string,
    environmentId: string,
    interactionPrompt: boolean,
    interactionPromptThreshold: string,
    disablePan: boolean
}) => {
    try {
        const response = await putAxiosWithToken({
            url: `/models/${args.modelId}`,
            body: {
                model_viewer_config: {
                    exposure: args.exposure,
                    "shadow-intensity": args.shadowIntensity,
                    "shadow-softness": args.shadowSoftness,
                    "auto-rotate": args.autoRotate,
                    "auto-rotate-delay": args.autoRotateDelay,
                    "direct-ar": args.directAR,
                    "camera-orbit": args.cameraOrbit,
                    "min-camera-orbit": args.minCameraOrbit,
                    "min-field-of-view": args.minFOV,
                    "interaction-prompt": args.interactionPrompt,
                    "interaction-prompt-threshold": args.interactionPromptThreshold,
                    "disable-pan": args.disablePan,
                },
                environment_id: args.environmentId
            }
        });
        if (response) {
            if ("data" in response) {
                const { data } = response
                if (data) {
                    return {
                        error: null,
                        data: data,
                    }
                }
            }
        }
        return {
            error: "Something went wrong",
            data: null,
        }
    } catch (error) {
        return {
            error: "Something went wrong",
            data: null,
        }
    }
}

