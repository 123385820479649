import React, { useState } from "react";
import Dropzone, { Accept } from "react-dropzone";

type Props = {
    setFile: Function
    fileName: string
    isMultiple: boolean
    accept: Accept
    maxFiles: number,
    minSize?: number,
}

const convertFromBytesToMB = (bytes: number) => {
    const mb = bytes / 1048576;
    return mb.toFixed(3);
}

const RefImage: React.FC<Props> = ({ setFile, fileName, isMultiple, accept, maxFiles, minSize = 0 }) => {
    const [dropzoneText, setDropzoneText] = useState<string>(`Drop or Select ${fileName} File${isMultiple ? "(s)" : ""}`)

    return (
        <Dropzone
            onDrop={(acceptedFiles) => {
                setFile(acceptedFiles)
                let text = ""
                acceptedFiles.forEach((file) => {
                    text += file.name + " - " + convertFromBytesToMB(file.size) + " MB <br/>"
                })
                setDropzoneText(text)
            }}
            multiple={isMultiple}
            onDropRejected={(file) => {
                if (file.length > 0 && file[0].file.size < 100000) {
                    setDropzoneText("File Size must be larger than 100 Kilobytes")
                } else if (file.length > maxFiles) {
                    setDropzoneText("File count must be less than " + maxFiles)
                }
            }}
            accept={accept}
            maxFiles={maxFiles}
            minSize={minSize}
        >
            {({ getRootProps, getInputProps }) => (
                <div {...getRootProps({ className: 'dropzone' })}>
                    <input {...getInputProps()} />
                    <span style={{ fontSize: ".8rem" }} dangerouslySetInnerHTML={{ __html: dropzoneText }} />
                </div>

            )}
        </Dropzone>
    )
}

export default RefImage
