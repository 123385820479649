import { Alert, Grid } from "@mui/material"

const InfoBox = ({ status, children } : {
    status: 'info' | 'success' | 'warning' | 'error',
    children: React.ReactNode
}) => {
    return (
        <Grid item xs={12}>
            <Alert severity={status}>{children}</Alert>
        </Grid>
    )
}

export default InfoBox;