import { Card, CardContent, CardHeader, Grid } from "@mui/material"
import { LoadingButton } from "@mui/lab"
import SyncIcon from "@mui/icons-material/Sync"
import React, { useState } from "react"
import { FileLoadingStats } from "../../../../types/QMMTypes"
import { copyFile } from "../../../../services/QMMService"
import { cdnToS3BucketNameChanger } from "../../../../functions/cdnToS3URLChanger"

type SyncFilesCardProps = {
    thumbnail_file_source: string
    thumbnail_file_target: string
    glb_file_source: string
    glb_file_target: string
    usdz_file_source: string
    usdz_file_target: string
}

const SyncFilesCard = (props: SyncFilesCardProps) => {
    const [filesLoadingStats, setFilesLoadingStats] = useState<FileLoadingStats>({
        glb_file: false,
        usdz_file: false,
        thumbnail_file: false
    })
    const [filesDisabled, setFilesDisabled] = useState<boolean>(false)

    const handleSyncFiles = (source: string, target: string, key: string) => {
        setFilesLoadingStats({ ...filesLoadingStats, [key]: true })
        setFilesDisabled(true)
        copyFile(cdnToS3BucketNameChanger(source), target).then((response) => {
            setFilesLoadingStats({ ...filesLoadingStats, [key]: false })
            setFilesDisabled(false)
        })
    }

    return (
        <Card>
            <CardHeader title={"Sync Files"} />
            <CardContent>
                <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="flex-start"
                    spacing={1}
                >
                    <Grid item>
                        <LoadingButton
                            onClick={() => {
                                handleSyncFiles(props.thumbnail_file_source, props.thumbnail_file_target, "thumbnail_file")
                            }}
                            variant="outlined"
                            loading={filesLoadingStats.thumbnail_file}
                            disabled={filesDisabled}
                        >
                            <SyncIcon

                            /> Thumbnail File
                        </LoadingButton>
                    </Grid>
                    <Grid item>
                        <LoadingButton
                            onClick={() => {
                                handleSyncFiles(props.glb_file_source, props.glb_file_target, "glb_file")
                            }}
                            variant="outlined"
                            loading={filesLoadingStats.glb_file}
                            disabled={filesDisabled}
                        >
                            <SyncIcon /> GLB File
                        </LoadingButton>
                    </Grid>
                    <Grid item>
                        <LoadingButton
                            onClick={() => {
                                handleSyncFiles(props.usdz_file_source, props.usdz_file_target, "usdz_file")
                            }}
                            variant="outlined"
                            loading={filesLoadingStats.usdz_file}
                            disabled={filesDisabled}
                        >
                            <SyncIcon /> USDZ File
                        </LoadingButton>
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    )
}

export default SyncFilesCard