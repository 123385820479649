import { styled } from "@mui/material";
import { Link } from "react-router-dom";

export const QReviewNavItem = styled(Link)(({theme}) => ({
    textDecoration: "none",
    marginLeft: 16,
    marginRight: 16,
    borderRadius: 100,
    paddingLeft: 16,
    paddingRight: 16,
    height: 38,
    display: "flex",
    alignItems: "center",
    "&:focus, &:hover, &:visited, &:link, &:active": {
      textDecoration: "none",
      color: theme.palette.text.primary,
    }
}))