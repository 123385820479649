
import { Chip, ChipProps, styled } from "@mui/material";

interface Props {
    status: "PENDING" | "REVIEW_REQUESTED" | "REJECTED" | "APPROVED",
}

const types = {
    PENDING: "Waiting",
    REVIEW_REQUESTED: "Review Requested",
    REJECTED: "Rejected",
    APPROVED: "Approved"
}

const QReviewChip = styled(Chip)<ChipProps>(({theme}) => ({
    fontSize: "12px",
    fontFamily: "Crystal",
}))

const QAStatusBadge = ({ status }: Props) => {

    const type = types[status];

    const getStatusColor = () => {
        switch (status) {
            case "PENDING":
                return "warning";
            case "REVIEW_REQUESTED":
                return "info";
            case "REJECTED":
                return "error";
            case "APPROVED":
                return "success";
            default:
                return "warning";
        }
    }

    return (
        <QReviewChip label={type} color={getStatusColor()}/>
    );
}

export default QAStatusBadge;