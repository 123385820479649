import React from "react"
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material"
import QReviewBtn from "../QReviewButton/QReviewBtn"

type Props = {
    isOpen: boolean
    onClickFunction: () => any
    onCloseModal: () => any
    status: string,
    type: string,
    name: string,
}

export const statusList = {
    onProgress: "Delete",
    onLoading: "Deleting",
    success: "Deleted",
    error: "Error"
}

const DeleteConfirmationModal: React.FC<Props> = ({ onClickFunction, status, type, name, isOpen, onCloseModal }) => {

    return (
        <Dialog
            open={isOpen}
            onClose={onCloseModal}
            fullWidth={true}
            maxWidth={'sm'}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
                {'Delete ' + type.charAt(0).toUpperCase() + type.slice(1) + ' Confirmation'}
            </DialogTitle>
            <DialogContent>
                Are you sure you want to delete <span className="fw-bold">{name}</span>{" "}
                {type}?
            </DialogContent>
            <DialogActions sx={{ margin: '16px' }}>
                <QReviewBtn type="submit" onClick={onClickFunction}>
                    Delete
                </QReviewBtn>
                <QReviewBtn type="button" onClick={onCloseModal}>
                    Close
                </QReviewBtn>
            </DialogActions>

        </Dialog>
    )
}

export default DeleteConfirmationModal

