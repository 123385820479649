import React from "react"

type Props = {
    dark: boolean
}

const Loading: React.FC<Props> = ({ dark }) => {
    return (
        <div className="w-100 d-flex align-items-center justify-content-center">
            <div className={`loading ${dark ? "loading-dark" : "loading-light"}`}>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
            </div>
        </div>
    )
}

export default Loading
