import { postAxiosWithToken } from "./axiosHelper";



export const createReferrer = async (args: { modelId: string; organizationId: string; externalId?: string }) => {
    type Referrer = {
        model_id: string,
        organization_id: string,
        external_id?: string
    }
    const requestBody: Referrer = {
        model_id: args.modelId,
        organization_id: args.organizationId,
        external_id: args.externalId
    }

    try {
        const response = await postAxiosWithToken({
            url: "/referrers",
            body: requestBody,
        });
        if (response && response.data) {
            if ("data" in response) {
                const { data } = response
                if (data) {
                    return {
                        error: null,
                        data: data,
                    }
                }
            }
        }
        return {
            error: response.errorMessages,
            data: null,
        }
    } catch (error) {
        return {
            error: "Something went wrong",
            data: null,
        }
    }
}