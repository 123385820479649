import { MenuItem, Select } from "@mui/material";
import { useEffect, useState } from "react";
import { ArrowDownIconBlack } from "../../assets/icons";
import { ModelStatusList } from "../../enums/modelCategories";
import { StatusBGColor } from "../../enums/bgColors";
import { putAxiosWithToken } from "../../functions/axiosHelper";
import { useThemeContext } from "../../theme/ThemeContextProvider";
import Loading from "../template/Loading";
import { useAuth } from "qreal-auth-provider/src";

interface Props {
    modelStatus: "PENDING" | "REVIEW_REQUESTED" | "REJECTED" | "APPROVED",
    modelId: string,
    comments?: Array<any>
}

const ModelStatusModule = ({ modelStatus, modelId, comments }: Props) => {
    const { mode } = useThemeContext();
    const [status, setStatus] = useState(modelStatus)
    const [loading, setLoading] = useState(false);
    const [bgColor, setBgColor] = useState('')
    const { user } = useAuth()

    useEffect(() => {
        setBgColor(StatusBGColor[status])
    }, [status])



    const handleStatusUpdate = (e) => {
        if (comments !== undefined) {
            if ((!comments.some(comment => comment.user_name === (user.first_name + ' ' + user.last_name))) && e.target.value === 'REVIEW_REQUESTED') {
                alert('You cannot reject the model without commenting')
                return
            }
        }

        setStatus(e.target.value)
        setLoading(true)
        putAxiosWithToken({
            url: `/models/${modelId}/qa-status`,
            body: {
                qa_status: e.target.value
            }
        }).then(res => {
            setLoading(false)
        }).catch(err => {
            setLoading(false)
        })
    }


    return (
        <>
            {loading ? <Loading dark={false} /> :
                <Select
                    size="small"
                    IconComponent={ArrowDownIconBlack}
                    onChange={handleStatusUpdate}
                    value={status}
                    // displayEmpty
                    style={{ color: 'black', backgroundColor: bgColor }}
                >
                    <MenuItem value="" disabled>Status</MenuItem>
                    {Object.entries(ModelStatusList).map((status, index) => (
                        <MenuItem key={'status-' + index} value={status[0]} >{status[1]}</MenuItem>
                    ))
                    }
                </Select>
            }
        </>
    )
}

export default ModelStatusModule
