import axios from "axios"
import FormData from "form-data"

export const uploadFile = async (file: File, url: string) => {
    const formData = new FormData()

    formData.append("", file) //TODO: change "file" into ""
    delete axios.defaults.headers["Content-Type"]

    try {
        const response = await axios.put(url, formData, {
            headers: {
                "Content-Type": "application/json",
            },
        })
        if (response) {
            if ("data" in response) {
                const { data } = response
                if (data) {
                    return {
                        error: null,
                        data: data,
                    }
                }
            }
        }
        return {
            error: "Something went wrong",
            data: null,
        }
    } catch (error) {
        return {
            error: "Something went wrong",
            data: null,
        }
    }
}

export const uploadGLBFile = async (file: File, url: string) => {
    try {
        const response = await axios({
            method: "put",
            url: url,
            headers: {
                "Content-Type": "model/gltf-binary",
            },
            data: file,
        })

        if (response) {
            if (response.status === 200) {
                return {
                    error: null,
                    data: "success",
                }
            }
        }
        return {
            error: "Something went wrong",
            data: null,
        }
    } catch (error) {
        return {
            error: "Something went wrong",
            data: null,
        }
    }
}
export const uploadUSDZFile = async (file: File, url: string) => {
    try {
        const response = await axios({
            method: "put",
            url: url,
            headers: {
                "Content-Type": "model/vnd.usdz+zip",
            },
            data: file,
        })

        if (response) {
            if (response.status === 200) {
                return {
                    error: null,
                    data: "success",
                }
            }
        }
        return {
            error: "Something went wrong",
            data: null,
        }
    } catch (error) {
        return {
            error: "Something went wrong",
            data: null,
        }
    }
}
export const uploadHDRFile = async (file: File, url: string) => {
    try {
        const response = await axios({
            method: "put",
            url: url,
            headers: {
                "Content-Type": "application/octet-stream",
            },
            data: file,
        })

        if (response) {
            if (response.status === 200) {
                return {
                    error: null,
                    data: "success",
                }
            }
        }
        return {
            error: "Something went wrong",
            data: null,
        }
    } catch (error) {
        return {
            error: "Something went wrong",
            data: null,
        }
    }
}
export const uploadImageFile = async (file: File, url: string) => {
    try {
        const response = await axios({
            method: "put",
            url: url,
            headers: {
                "Content-Type": "image/jpeg",
            },
            data: file,
        })

        if (response) {
            if (response.status === 200) {
                return {
                    error: null,
                    data: "success",
                }
            }
        }
        return {
            error: "Something went wrong",
            data: null,
        }
    } catch (error) {
        return {
            error: "Something went wrong",
            data: null,
        }
    }
}

export const uploadPosterFile = async (file: File, url: string) => {
    try {
        const response = await axios({
            method: "put",
            url: url,
            headers: {
                "Content-Type": "image/webp",
            },
            data: file,
        })

        if (response) {
            if (response.status === 200) {
                return {
                    error: null,
                    data: "success",
                }
            }
        }
        return {
            error: "Something went wrong",
            data: null,
        }
    } catch (error) {
        return {
            error: "Something went wrong",
            data: null,
        }
    }
}

export const uploadMultipleImageFile = async (files: File[], urls: string[]) => {
    try{
        let stat = true
        for (let i = 0; i < files.length; i++) {
            let response = await axios({
                method: "put",
                url: urls[i],
                headers: {
                    "Content-Type": "image/jpeg",
                },
                data: files[i],
            })
            

            if (response.status !== 200){
                stat = false
            }
        }
        if (stat) {
            return {
                error: null,
                data: "success",
            }
        }else {
            return {
                error: "Something went wrong",
                data: null,
            }
        }
    }catch (error) {
        return {
            error: "Something went wrong",
            data: null,
        }
    }
}
