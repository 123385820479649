import { useEffect, useState } from "react"

import { DayPicker, DateRange } from 'react-day-picker';
import { format } from 'date-fns'
import 'react-day-picker/dist/style.css';
import { Box, Button, Popover, Stack, Typography, useTheme } from "@mui/material";
import { useSearchParams } from "react-router-dom";
import CloseIconWhite from "../../assets/icons/Close-Icon-White.svg"
import CloseIconBlack from "../../assets/icons/Close-Icon-Black.svg"
import { useThemeContext } from "../../theme/ThemeContextProvider";

const defaultFilters = [
    {day: 7, text: "Last Week"},
    {day: 30, text: "Last Month"},
    {day: 90, text: "Last 3 Months"},
    {day: 365, text: "Last Year"},
]

interface DefaultFiltersCardProps {
    setRange: React.Dispatch<React.SetStateAction<DateRange | undefined>>
}

const DefaultFiltersCard = ({setRange}: DefaultFiltersCardProps) => {
    const theme = useTheme()
    const [selectedFilter, setSelectedFilter] = useState("")

    const getDateAgo = (count: number) => {
        var date = new Date() as any;
        var oldDay = date - 1000 * 60 * 60 * 24 * count;
        return oldDay
    }

    const handleFilterClick = (filter: any) => {
        setSelectedFilter(filter.text)
        setRange({to: new Date(),from: new Date(getDateAgo(filter.day))})
    }

    return (
        <Stack spacing={3}>
            {defaultFilters.map((filter, index) => (
                <Box key={filter.text} id={filter.text} onClick={()=>{handleFilterClick(filter)}} bgcolor={selectedFilter === filter.text ? theme.palette.primary.main : "transparent"} color={selectedFilter === filter.text ? "black" : "inherit"} borderRadius={100} px={2} py={1}>
                    <Typography variant="qrealBody" noWrap>{filter.text}</Typography>
                </Box>
            ))
            }
        </Stack>
    )
}

const ModelFilterCalendar = () => {
    const { mode } = useThemeContext();
    const theme = useTheme()
    const css = `
    .my-selected {
        color: #000000;
        background-color: ${theme.palette.primary.main};
    }
    `
    let [searchParams, setSearchParams] = useSearchParams();
    const [selectedDates, setSelectedDates] = useState("Select Dates");
    const [range, setRange] = useState<DateRange | undefined>();

    const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);

    const handleClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'date-picker-popover' : undefined;
    
    const handleDatesClick = () => {
        if (range) {
            if (range.to && range.from) {
                searchParams.delete('filtered')
                searchParams.set('start_date', range.from.toISOString())
                searchParams.set('end_date', range.to.toISOString())
                setSearchParams(searchParams)
                setSelectedDates(format(range?.from, 'MM/dd/yyyy') + " - " + format(range?.to, 'MM/dd/yyyy'))
                handleClose()
            } 
        } else{
            setRange(undefined)
            setSelectedDates("Select Dates")
            searchParams.delete('start_date')
            searchParams.delete('end_date')
            setSearchParams(searchParams)
            handleClose()
        }
    }

    const handleCancelClick = () => {
        setRange(undefined)
        setSelectedDates("Select Dates")
        searchParams.delete('start_date')
        searchParams.delete('end_date')
        setSearchParams(searchParams)
        handleClose()
    }

    return (
        <>
        <style>{css}</style>
        <Box onClick={handleClick}>
            <Stack direction={"row"} spacing={3} py={1} justifyContent="center" alignItems="center" sx={{borderRadius:"100px", backgroundColor: theme.palette.background.paper}}>
                <Typography variant="qrealBody">{selectedDates}</Typography>
                {selectedDates !== "Select Dates" &&
                <Box display={"flex"} alignItems={"center"} zIndex={100} onClick={handleCancelClick}>
                    <img src={mode === "light" ? CloseIconBlack : CloseIconWhite} alt="close"/>
                </Box>
                }
            </Stack>
        </Box>
        <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
            }}
        >
            <Stack direction={"row"} sx={{background: theme.palette.background.default}}>
                <Box borderRight={"1px solid #7D7B7E"} padding={3}>
                    <DefaultFiltersCard setRange={setRange} />
                </Box>
                 <Stack padding={1}>
                     <DayPicker
                         mode="range"
                         className="Range"
                         numberOfMonths={1}
                         selected={range}
                         onSelect={setRange}
                         modifiersClassNames={{
                            selected: 'my-selected',
                          }}
                     />
                     <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"} pb={2} px={2}>
                        <Typography variant="qrealBody" onClick={handleCancelClick} sx={{cursor:"pointer"}}>Clear</Typography>
                        <Button variant="contained" onClick={handleDatesClick}>Done</Button>
                     </Stack>
                 </Stack>
             </Stack>
        </Popover>
        </>
    );
}

export default ModelFilterCalendar