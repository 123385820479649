import React, { useContext, useState } from "react"
import { createModel } from "../functions/createModel"
import { ErrorResponse } from "../functions/formatString"
import {
    uploadGLBFile,
    uploadMultipleImageFile,
    uploadPosterFile,
    uploadUSDZFile
} from "../functions/uploadFile"
import { Model } from "./AppProvider"
import { useCreateReferrer } from "./CreateReferrerProvider"

export type CreateModelContextType = {
    modelName: string
    setModelName: (e: any) => any
    model: Model
    setModel: (e: any) => any
    modelReferenceLink: string
    setModelReferenceLink: (e: any) => any
    modelReferenceFileCount: string
    setModelReferenceFileCount: (e: any) => any
    GLBFile: File
    setGLBFile: (e: any) => any
    USDZFile: File
    setUSDZFile: (e: any) => any
    HDRFile: File
    setHDRFile: (e: any) => any
    environmentId: string
    setEnvironmentId: (e: any) => any
    ReferenceFile: File
    setReferenceFile: (e: any) => any
    createModelPending: boolean
    isModelCreatedSuccessfully: boolean
    setIsModelCreatedSuccessfully: (e: any) => any
    createModelAsyncMethod: (modelName: string, GLBFile: File, USDZFile: File, HDRFile: File, thumbnailFile: File, posterFile: File) => any
    createProgress: {
        message: string
        error: boolean
        errorList: ErrorResponse[]
    },
    setCreateProgress: (e: any) => any,
    category: string,
    setCategory: (e: any) => any,
    brand: string,
    setBrand: (e: any) => any,
    authorizedOrganizations: string,
    setAuthorizedOrganizations: (e: any) => any,
    snapchatLink: string,
    setSnapchatLink: (e: any) => any,
    exposure: number,
    shadowIntensity: number,
    shadowSoftness: number,
    setExposure: (e: any) => any,
    setShadowIntensity: (e: any) => any,
    setShadowSoftness: (e: any) => any,
    setModelIframeLink: (e: any) => any,
    modelIframeLink: string,
    posterFile: File,
    setPosterFile: (e: any) => any,
    thumbnailFile: File,
    setThumbnailFile: (e: any) => any,
    cameraOrbit: string,
    setCameraOrbit: (e: any) => any,
    referrerExternalId: string,
    setReferrerExternalId: (e: any) => any,
}

export const CreateModelContext = React.createContext<CreateModelContextType | null>(null)
export const useCreateModel = () => useContext(CreateModelContext) as CreateModelContextType

type Props = {
    children: JSX.Element
}

const CreateModelProvider: React.FC<Props> = ({ children }) => {
    // States
    const [modelName, setModelName] = useState<string>("")
    const [model, setModel] = useState<Model>({} as Model);
    const [environmentId, setEnvironmentId] = useState<string>("")
    const [modelReferenceLink, setModelReferenceLink] = useState<string>("")
    const [modelReferenceFileCount, setModelReferenceFileCount] = useState<string>("")
    const [modelIframeLink, setModelIframeLink] = useState<string>("")
    const [GLBFile, setGLBFile] = useState<any>()
    const [USDZFile, setUSDZFile] = useState<any>()
    const [HDRFile, setHDRFile] = useState<any>()
    const [ReferenceFile, setReferenceFile] = useState<any>()
    const [createModelPending, setCreateModelPending] = useState<boolean>(false)
    const [isModelCreatedSuccessfully, setIsModelCreatedSuccessfully] = useState<boolean>(false)
    const [createProgress, setCreateProgress] = useState<{
        message: string
        error: boolean
        errorList: ErrorResponse[]
    }>({ message: "", error: false, errorList: [] })
    const [category, setCategory] = useState<string>("")
    const [brand, setBrand] = useState<string>("")
    const [authorizedOrganizations, setAuthorizedOrganizations] = useState<string>("")
    const [snapchatLink, setSnapchatLink] = useState<string>("")
    const [exposure, setExposure] = useState(1)
    const [shadowIntensity, setShadowIntensity] = useState(1)
    const [shadowSoftness, setShadowSoftness] = useState(1)
    const [posterFile, setPosterFile] = useState<any>()
    const [thumbnailFile, setThumbnailFile] = useState<any>()
    const [cameraOrbit, setCameraOrbit] = useState<string>("");
    const [referrerExternalId, setReferrerExternalId] = useState<string>("");

    const showErrorScreen = (errors) => {
        setCreateModelPending(false)
        setCreateProgress({ ...createProgress, errorList: errors, error: true })
    }

    const {
        createReferrerAsyncMethod,
    } = useCreateReferrer()
    // Methods
    const createModelAsyncMethod = async () => {
        setCreateModelPending(true)
        setCreateProgress({ ...createProgress, message: "Creating Model" })
        const fileErrors: ErrorResponse[] = [];
        if (!GLBFile) {
            fileErrors.push({ msg: "GLB File is required", path: "GLBFile", value: "" })
        }
        if (!ReferenceFile) {
            fileErrors.push({ msg: "Reference File is required", path: "ReferenceFile", value: "" })
        }
        if (fileErrors.length > 0) {
            showErrorScreen(fileErrors)
            return;
        }

        const primaryResponse = await createModel({
            modelName: modelName,
            modelReferenceLink: modelReferenceLink,
            modelReferenceFileCount: modelReferenceFileCount,
            brandId: brand,
            iframeLink: modelIframeLink,
            category: category,
            modelEnvironment: environmentId,
            exposure: exposure.toString(),
            shadowIntensity: shadowIntensity.toString(),
            shadowSoftness: shadowSoftness.toString(),
        })

        let secondaryResponse

        if (!primaryResponse.error && primaryResponse.data) {
            setCreateProgress({ ...createProgress, message: "Uploading GLB File" })
            setModel(primaryResponse.data.data[0]);
            const GLBUrl = primaryResponse.data.data[0].glb_file[0]
            if (GLBFile) {
                secondaryResponse = await uploadGLBFile(GLBFile[0], GLBUrl)
            } else {
                secondaryResponse = { error: null, data: "success" }
            }
        } else {
            showErrorScreen(primaryResponse.error)
            return;
        }


        if (!secondaryResponse.error && secondaryResponse.data) {
            setCreateProgress({ ...createProgress, message: "Uploading USDZ File" })
            const USDZUrl = primaryResponse.data.data[0].usdz_file[0]
            if (USDZFile) {
                secondaryResponse = await uploadUSDZFile(USDZFile[0], USDZUrl)
            } else {
                secondaryResponse = { error: null, data: "success" }
            }
        } else {
            setCreateModelPending(false)
            setCreateProgress({ ...createProgress, error: true })
        }

        if (!secondaryResponse.error && secondaryResponse.data) {
            setCreateProgress({ ...createProgress, message: "Uploading Poster File" })
            const posterURL = primaryResponse.data.data[0].poster_file[0]
            if (posterFile) {
                secondaryResponse = await uploadPosterFile(posterFile, posterURL)
            } else {
                secondaryResponse = { error: null, data: "success" }
            }
        } else {
            setCreateModelPending(false)
            setCreateProgress({ ...createProgress, error: true })
        }

        if (!secondaryResponse.error && secondaryResponse.data) {
            setCreateProgress({ ...createProgress, message: "Uploading Thumbnail File" })
            const thumbnailURL = primaryResponse.data.data[0].thumbnail_file[0]
            if (thumbnailFile) {
                secondaryResponse = await uploadPosterFile(thumbnailFile, thumbnailURL)
            } else {
                secondaryResponse = { error: null, data: "success" }
            }
        } else {
            setCreateModelPending(false)
            setCreateProgress({ ...createProgress, error: true })
        }

        if (!secondaryResponse.error && secondaryResponse.data) {
            setCreateProgress({ ...createProgress, message: "Uploading Reference File(s)" })
            secondaryResponse = await uploadMultipleImageFile(ReferenceFile, primaryResponse.data.data[0].reference_file)
        } else {
            setCreateModelPending(false)
            setCreateProgress({ ...createProgress, error: true })
        }
        let tertiaryResponse
        if (!secondaryResponse.error && secondaryResponse.data) {
            setCreateProgress({ ...createProgress, message: "Creating Referrer" })
            if (!primaryResponse.error && primaryResponse.data) {
                tertiaryResponse = await createReferrerAsyncMethod(primaryResponse.data.data[0]._id.toString(), authorizedOrganizations, referrerExternalId)
            } else {
                tertiaryResponse = { error: null, data: "success" }
            }
        } else {
            setCreateModelPending(false)
            setCreateProgress({ ...createProgress, error: true })
        }

        if (!tertiaryResponse.error && tertiaryResponse.data) {
            setCreateProgress({ ...createProgress, message: "Finalizing Model Creation" })
            setModelName("")
            setEnvironmentId("")
            setModelReferenceLink("")
            setAuthorizedOrganizations('')
            setReferrerExternalId('')
            setGLBFile(null)
            setUSDZFile(null)
            setHDRFile(null)
            setReferenceFile(null)
            setIsModelCreatedSuccessfully(true)
            setCreateModelPending(false)
        } else {
            setCreateModelPending(false)
            setCreateProgress({ ...createProgress, error: true })
        }


        return primaryResponse;
    }

    // Binding
    const valueObject = {
        modelName,
        setModelName,
        model,
        setModel,
        environmentId,
        setEnvironmentId,
        modelReferenceLink,
        setModelReferenceLink,
        modelReferenceFileCount,
        setModelReferenceFileCount,
        GLBFile,
        setGLBFile,
        USDZFile,
        setUSDZFile,
        HDRFile,
        setHDRFile,
        ReferenceFile,
        setReferenceFile,
        createModelPending,
        isModelCreatedSuccessfully,
        setIsModelCreatedSuccessfully,
        createModelAsyncMethod,
        createProgress,
        setCreateProgress,
        category,
        setCategory,
        brand,
        setBrand,
        snapchatLink,
        setSnapchatLink,
        exposure,
        shadowIntensity,
        shadowSoftness,
        setExposure,
        setShadowIntensity,
        setShadowSoftness,
        setModelIframeLink,
        modelIframeLink,
        posterFile,
        setPosterFile,
        thumbnailFile,
        setThumbnailFile,
        authorizedOrganizations,
        setAuthorizedOrganizations,
        cameraOrbit,
        setCameraOrbit,
        referrerExternalId,
        setReferrerExternalId,
    }

    // Render
    return <CreateModelContext.Provider value={valueObject}>{children}</CreateModelContext.Provider>
}

export default CreateModelProvider
