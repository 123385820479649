import React from "react"
import { useApp } from "../../providers/AppProvider"
import { useState } from "react"
import { getModel } from "../../functions/getModel";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material"
import QReviewBtn from "../QReviewButton/QReviewBtn";

type Props = {
    isOpen: boolean
    closeModal: () => any
    selectedModelId: string
}
const DeleteModelConfirmationModal: React.FC<Props> = ({ isOpen, closeModal, selectedModelId }) => {
    const { deleteModelAsyncMethod, deleteModelPending, isModelDeletedSuccessfully } =
        useApp()
    const [modelName, setModelName] = useState<string>("")
    const model = getModel(selectedModelId).then(res => {
        setModelName(res.data.data[0].model_name)
    })
    return (
        <Dialog
            open={isOpen}
            onClose={closeModal}
            fullWidth={true}
            maxWidth={'sm'}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
                {'Delete Model Confirmation'}
            </DialogTitle>
            <DialogContent>
                Are you sure you want to delete <span className="fw-bold">{modelName}</span>{" "}
                model?
            </DialogContent>
            <DialogActions sx={{ margin: '16px' }}>
                <QReviewBtn type="submit" onClick={isModelDeletedSuccessfully
                    ? () => { }
                    : () => {
                        deleteModelAsyncMethod(selectedModelId).then(() => {
                            //reload page
                            window.location.reload()
                        })
                    }}>
                    {isModelDeletedSuccessfully ? (
                        <div>
                            <i className="bi-check2-square"></i>
                            <span className="ps-1">Deleted</span>
                        </div>
                    ) : deleteModelPending ? (
                        <div>
                            <span className="spinner-border spinner-border-sm"></span>
                            <span className="ps-1">Delete</span>
                        </div>
                    ) : (
                        "Delete"
                    )}
                </QReviewBtn>

                <QReviewBtn type="button" onClick={closeModal}>
                    Close
                </QReviewBtn>
            </DialogActions>

        </Dialog>
    )
}

export default DeleteModelConfirmationModal
