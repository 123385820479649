export interface User {
    _id: string
    username: string
    first_name: string
    last_name: string
    email: string
}

export enum UserRoles {
    ADMIN = 'ADMIN',
    CUSTOMER = 'CUSTOMER'
}

export interface Subscription {
    _id?: string
    title: string
    organizations: string[]
    mail_frequency: string
    data_frequency: string
    status: 'ACTIVE' | 'PASSIVE'
    created_at?: string
}

export const StatusTypes = {
    ACTIVE: 'ACTIVE',
    PASSIVE: 'PASSIVE'
}