import React, { useContext, useState } from "react"
import {
    uploadGLBFile,
    uploadHDRFile,
    uploadMultipleImageFile,
    uploadPosterFile,
    uploadUSDZFile,
} from "../functions/uploadFile"
import {updateModel} from "../functions/updateModel";

export type EditModelContextType = {
    modelId: string
    setModelId: (e: any) => any
    modelName: string
    setModelName: (e: any) => any
    modelStatus: string
    setModelStatus: (e: any) => any
    modelReferenceLink: string
    setModelReferenceLink: (e: any) => any
    modelReferenceFileCount: string
    setModelReferenceFileCount: (e: any) => any
    GLBFile: File
    setGLBFile: (e: any) => any
    USDZFile: File
    setUSDZFile: (e: any) => any
    HDRFile: File
    setHDRFile: (e: any) => any
    ReferenceFile: File
    setReferenceFile: (e: any) => any
    posterFile: File
    setPosterFile: (e: any) => any
    editModelPending: boolean
    isModelEditedSuccessfully: boolean
    setIsModelEditedSuccessfully: (e: any) => any
    editModelError: boolean
    editGLBModelAsyncMethod: () => any
    editUSDZModelAsyncMethod: () => any
    editHDRModelAsyncMethod: () => any
    editReferenceModelAsyncMethod: () => any
    editPosterAsyncMethod: () => any
    isEditModelConfirmationModalOpen: boolean
    setIsEditModelConfirmationModalOpen: (e: any) => any
}

export const EditModelContext = React.createContext<EditModelContextType | null>(null)
export const useEditModel = () => useContext(EditModelContext) as EditModelContextType

type Props = {
    children: JSX.Element
}

const EditModelProvider: React.FC<Props> = ({ children }) => {
    // States
    const [modelId, setModelId] = useState<string>("")
    const [modelName, setModelName] = useState<string>("")
    const [modelStatus, setModelStatus] = useState<string>("")
    const [modelReferenceLink, setModelReferenceLink] = useState<string>("")
    const [modelReferenceFileCount, setModelReferenceFileCount] = useState<string>("")
    const [GLBFile, setGLBFile] = useState<any>()
    const [USDZFile, setUSDZFile] = useState<any>()
    const [HDRFile, setHDRFile] = useState<any>()
    const [ReferenceFile, setReferenceFile] = useState<any>()
    const [posterFile, setPosterFile] = useState<any>()
    const [editModelPending, setEditModelPending] = useState<boolean>(false)
    const [isModelEditedSuccessfully, setIsModelEditedSuccessfully] = useState<boolean>(false)
    const [editModelError, setEditModelError] = useState<boolean>(false)
    const [isEditModelConfirmationModalOpen, setIsEditModelConfirmationModalOpen] =
        useState<boolean>(false)


    // Methods
    const editGLBModelAsyncMethod = async () => {
        setEditModelPending(true)

        let response = await updateModel( { glb_file: "UPDATE"}, modelId)
        response = await uploadGLBFile(GLBFile[0], response.data.data[0].glb_file)

        if (!response.error && response.data === "success") {
            window.location.reload()
            setIsModelEditedSuccessfully(true)
            setEditModelPending(false)
        } else {
            setEditModelPending(false)
            setEditModelError(true)
        }
    }

    const editUSDZModelAsyncMethod = async () => {
        setEditModelPending(true)
        let response = await updateModel( { usdz_file: "UPDATE"}, modelId)
        response = await uploadUSDZFile(USDZFile[0], response.data.data[0].usdz_file)

        if (!response.error && response.data === "success") {
            window.location.reload()
            setEditModelPending(false)
            setIsModelEditedSuccessfully(true)
        } else {
            setEditModelPending(false)
            setEditModelError(true)
        }
    }

    const editHDRModelAsyncMethod = async () => {
        setEditModelPending(true)
        let response = await updateModel( { hdr_file: "UPDATE"}, modelId)
        response = await uploadHDRFile(HDRFile[0], response.data.data[0].hdr_file)

        if (!response.error && response.data === "success") {
            window.location.reload()
            setEditModelPending(false)
            setIsModelEditedSuccessfully(true)
        } else {
            setEditModelPending(false)
            setEditModelError(true)
        }
    }

    const editReferenceModelAsyncMethod = async () => {
        setEditModelPending(true)
        let response = await updateModel( { reference_file_count: modelReferenceFileCount, reference_file: "UPDATE"}, modelId)
        response = await uploadMultipleImageFile(ReferenceFile, response.data.data[0].reference_file)

        if (!response.error && response.data === "success") {
            setEditModelPending(false)
            setIsModelEditedSuccessfully(true)
            window.location.reload()
        } else {
            setEditModelPending(false)
            setEditModelError(true)
        }
    }

    const editPosterAsyncMethod = async () => {
        setEditModelPending(true)
        let response = await updateModel( { poster_file: "UPDATE"}, modelId)
        response = await uploadPosterFile(posterFile[0], response.data.data[0].poster_file)

        if (!response.error && response.data === "success") {
            setEditModelPending(false)
            setIsModelEditedSuccessfully(true)
            window.location.reload()
        } else {
            setEditModelPending(false)
            setEditModelError(true)
        }
    }

    // Binding
    const valueObject = {
        modelId,
        setModelId,
        modelName,
        setModelName,
        modelStatus,
        setModelStatus,
        modelReferenceLink,
        setModelReferenceLink,
        modelReferenceFileCount,
        setModelReferenceFileCount,
        GLBFile,
        setGLBFile,
        USDZFile,
        setUSDZFile,
        HDRFile,
        setHDRFile,
        ReferenceFile,
        setReferenceFile,
        posterFile,
        setPosterFile,
        editModelPending,
        isModelEditedSuccessfully,
        setIsModelEditedSuccessfully,
        editModelError,
        editGLBModelAsyncMethod,
        editUSDZModelAsyncMethod,
        editHDRModelAsyncMethod,
        editReferenceModelAsyncMethod,
        editPosterAsyncMethod,
        isEditModelConfirmationModalOpen,
        setIsEditModelConfirmationModalOpen,
    }

    // Render
    return <EditModelContext.Provider value={valueObject}>{children}</EditModelContext.Provider>
}

export default EditModelProvider
