import { getAxiosWithToken } from "./axiosHelper"

export const getHdrList = async () => {
    try {
        const response = await getAxiosWithToken({
            url: "/environments?list_all=true",
        })
        if (response) {
            if ("data" in response) {
                const { data } = response
                if (data) {
                    return {
                        error: null,
                        data: data,
                    }
                }
            }
        }
        return {
            error: "Something went wrong",
            data: null,
        }
    } catch (error) {
        return {
            error: "Something went wrong",
            data: null,
        }
    }
}