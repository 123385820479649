import { useState } from "react";
import { getAxiosWithToken } from "../functions/axiosHelper";
import XLSX from "sheetjs-style";
import DownloadIcon from "../assets/icons/Download-Icon.svg";
import QReviewIconButton from "./QReviewButton/QReviewIconButton";
import LoadingIcon from "../assets/icons/loading.svg";
import { Stack } from "@mui/material";

interface Models {
    _id: string;
    model_name: string;
    glb_file: string;
    qa_status: string;
}

export const autoDownload = (data) => {
    var url = window.URL || window.webkitURL;
    const link = url.createObjectURL(data);
    var a = document.createElement("a");
    a.setAttribute("download", `QReview-Models-${new Date().toLocaleDateString()}.xlsx`);
    a.setAttribute("href", link);
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
};

export const downloadModelsAsExcel = async (datum) => {
    const fileType =
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";

    const getColor = (status) => {
        switch (status) {
            case 'PENDING':
                return 'FFA132';
            case 'REVIEW_REQUESTED':
                return '0D6EFD';
            case 'REJECTED':
                return 'FE4A49';
            case 'APPROVED':
                return 'C2E007';
            default:
                return 'FFFFFF';
        }
    }
    const ws = XLSX.utils.json_to_sheet(datum);

    // set the style for target cell
    const range = XLSX.utils.decode_range(ws['!ref'] as any);
    for (let i = range.s.r; i <= range.e.r; i++) {
        const ref = XLSX.utils.encode_cell({ r: i, c: 4 });
        if (ws[ref]) {
            ws[ref].s = {
                fill: {
                    patternType: 'solid',
                    fgColor: { rgb: getColor(ws[ref]?.v) },
                    bgColor: { rgb: getColor(ws[ref]?.v) },
                }
            };
        }
    }

    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });

    autoDownload(data);
};

export const removeKeys = (array, keys) => {
    return array.map((obj) => {
        keys.forEach((k) => delete obj[k]);
        return obj;
    });
}

export const addARLink = (array, key) => {
    return array.map((obj) => {
        if (obj[key].length !== 0) {
            obj[key] = window.location.origin + "/ar/" + obj[key][0]._id
        } else {
            obj[key] = ""
        }
        return obj;
    })
}


const ExportModels = () => {
    const [loading, setLoading] = useState(false);

    const fetchModels = async () => {
        setLoading(true);
        await getAxiosWithToken({
            url: `/models/search?list_all=true&search_query=`
        }).then(res => {
            if (res.data && res.data.is_complete === true) {
                const datum = removeKeys(res.data.data, ['glb_file', 'usdz_file', 'updated_at', 'model_status', '_id', 'brand_id', 'model_slug']);
                addARLink(datum, 'referrers');
                downloadModelsAsExcel(datum);
                setLoading(false);
            }
        })

    };

    return (
        <Stack>

            {loading ? (
                <Stack>

                    <QReviewIconButton className="spinner-border spinner-border-sm" alt="export" svg={LoadingIcon}>

                    </QReviewIconButton>
                </Stack>
            ) : (
                <QReviewIconButton onClick={fetchModels} alt="export" svg={DownloadIcon}>

                </QReviewIconButton>
            )}
        </Stack>
    );
};

export default ExportModels;