import { postAxiosWithTokenAndBaseUrl } from "../functions/axiosHelper"

const AUTH_API = process.env.REACT_APP_AUTH_API
const QMM_SERVICE_KEY = process.env.REACT_APP_QMM_SERVICE_KEY

export async function switchToken() {
    return postAxiosWithTokenAndBaseUrl({
        baseUrl: AUTH_API as string,
        path: "/auth/switch-token",
        body: { service: QMM_SERVICE_KEY }
    }).then((res: any) => {
        if (res.error) {
            return null
        }

        return res.data?.data?.authorization_token
    })
}