import { Button, Grid, MenuItem, OutlinedInput, Select, SelectChangeEvent, TextField, Typography } from "@mui/material"
import { useState } from "react"
import { StatusTypes, Subscription } from "../../types/user"
import SendIcon from '@mui/icons-material/Send';
import { Send } from "@mui/icons-material";
import { postAxiosWithToken, putAxiosWithToken } from "../../functions/axiosHelper";
import Loading from "../template/Loading";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

const UpdateSubscriptionForm = ({
    organizations,
    subscription,
    callBack
}) => {
    const [selectedOrganizations, setSelectedOrganizations] = useState<string[]>(subscription.organizations)
    const [newSubscription, setNewSubscription] = useState<Subscription>(subscription)
    const [loading, setLoading] = useState<boolean>(false)
    const frequencyList = ['DAILY', 'WEEKLY', 'MONTHLY']

    const handleChange = (event: SelectChangeEvent<any>) => {
        const {
            target: { value },
        } = event;
        setSelectedOrganizations(
            // On autofill we get a the stringified value.
            typeof value === 'string' ? value.split(',') : value,
        );
        setNewSubscription({ ...newSubscription, organizations: (typeof value === 'string' ? value.split(',') : value) })
    };


    return (
        <Grid container spacing={2} mt={5} pb={3} className="modal-content">
            {loading ? <Loading dark={false} /> :
                <>
                    <Grid item xs={12} py={1}>
                        <Typography className="text-white" variant="h6">Update Subscription</Typography>
                    </Grid>
                    <Grid item xs={2}>
                        <span className="">Title</span>
                        <TextField
                            className="form-control"
                            sx={{ input: { color: 'white' } }}
                            fullWidth
                            size="small"
                            variant="outlined"
                            value={newSubscription.title}
                            onChange={(e) => { setNewSubscription({ ...newSubscription, title: e.target.value }) }}
                        />
                    </Grid>
                    <Grid item xs={2}>
                        <span className="">Mail Frequency</span>
                        <select
                            className="form-select"
                            onChange={e => setNewSubscription({ ...newSubscription, mail_frequency: e.target.value })}
                        >
                            {frequencyList.map((type, index) => {
                                return (
                                    <option selected={newSubscription.mail_frequency == type} key={type + index} value={type}>
                                        {type}
                                    </option>
                                )
                            })}
                        </select>
                    </Grid>
                    <Grid item xs={2}>
                        <span className="">Data Frequency</span>
                        <select
                            className="form-select"
                            onChange={e => setNewSubscription({ ...newSubscription, data_frequency: e.target.value })}
                        >
                            {frequencyList.map((type, index) => {
                                return (
                                    <option selected={newSubscription.data_frequency == type} key={type + index} value={type}>
                                        {type}
                                    </option>
                                )
                            })}
                        </select>
                    </Grid>
                    <Grid item xs={3}>
                        <span className="">Selected Organizations</span>
                        <Select
                            multiple
                            fullWidth
                            size="small"
                            className="input-bg"
                            value={newSubscription.organizations}
                            onChange={handleChange}
                            input={<OutlinedInput className="text-white" />}
                            MenuProps={MenuProps}
                        >
                            {organizations.map((organization) => (
                                <MenuItem
                                    key={organization.organization_name}
                                    value={organization._id}
                                >
                                    {organization.organization_name}
                                </MenuItem>
                            ))}
                        </Select>
                    </Grid>
                    <Grid item xs={2}>
                        <span className="">Status</span>
                        <select
                            className="form-select"
                            onChange={e => setNewSubscription({ ...newSubscription, status: e.target.value as any })}
                        >
                            <option selected={newSubscription.status == StatusTypes.PASSIVE} value={StatusTypes.PASSIVE}>
                                {StatusTypes.PASSIVE}
                            </option>
                            <option selected={newSubscription.status == StatusTypes.ACTIVE} value={StatusTypes.ACTIVE}>
                                {StatusTypes.ACTIVE}
                            </option>
                        </select>
                    </Grid>
                    <Grid item xs={1} alignSelf={'end'} sx={{ paddingBottom: '4px' }}>
                        <Button variant="contained" color="success" sx={{ cursor: 'pointer' }} onClick={() => {
                            setLoading(true)
                            putAxiosWithToken({
                                url: `/report-subscriptions/${subscription._id}?is_debug=true`,
                                body: newSubscription,
                            }).then((res) => {
                                setLoading(false)
                                callBack()
                            })
                        }}> Save</Button>
                    </Grid>
                </>}
        </Grid>
    )
}

export default UpdateSubscriptionForm