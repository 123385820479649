import { Container, Grid, Stack, Switch, Typography } from "@mui/material";
import { useAuth } from 'qreal-auth-provider/src';
import { useEffect, useState } from "react";
import { useMixpanel } from 'react-mixpanel-browser';
import { useNavigate, useSearchParams } from "react-router-dom";
import AddItemIcon from "../assets/icons/Add-Item-Icon.svg";
import ExportModels from "../components/ExportModels";
import QReviewIconButton from "../components/QReviewButton/QReviewIconButton";
import SearchInput from "../components/SearchInput";
import DeleteModelConfirmationModal from "../components/modals/DeleteModelConfirmationModal";
import FilterModels from "../components/modals/FilterModels";
import DateDisplayer from "../components/shared/DateDisplayer";
import ModelNameDisplayer from "../components/shared/ModelNameDisplayer";
import QAStatusBadge from "../components/shared/table-items/QaBadge";
import QReviewDefaultTable from "../components/tables/QReviewDefaultTable";
import QReviewModelTableActionButtons from "../components/tables/QReviewModelTableActionButtons";
import { QReviewTableCell, QReviewTableFileSizeCell, QReviewTableThumbnailCell } from "../components/tables/QReviewTableCell";
import { authKeys, checkAuth } from "../enums/authKeys";
import { getAxiosWithToken, getFileSize } from "../functions/axiosHelper";
import { cdnToS3URLChanger } from "../functions/cdnToS3URLChanger";
import { formatBytes } from "../functions/formatNumber";
import { Model, useApp } from "../providers/AppProvider";

const Home = () => {
    const {
        isDeleteModelConfirmationModalOpen,
        setIsDeleteModelConfirmationModalOpen,
        selectedModelId,
        setSelectedModelId,
        setIsModelDeletedSuccessfully,
    } = useApp()
    let [searchParams, setSearchParams] = useSearchParams();
    const [data, setData] = useState([]);
    const [GLBsizeList, setGLBSizeList] = useState<any>({});
    const [USDZsizeList, setUSDZSizeList] = useState<any>({});
    const [loading, setLoading] = useState(false);
    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setPerPage] = useState(searchParams.get('per_page') || 10);
    const [page, setPage] = useState(searchParams.get('page') || 1);
    const [searchQuery, setSearchQuery] = useState(searchParams.get('search_query') || "");
    const [rowsPerPageOptions, setRowsPerPageOptions] = useState([10, 25, 50, 75, 100, 1000])
    const { authList } = useAuth();
    const [showFileSize, setShowFileSize] = useState(false);

    let navigate = useNavigate()
    const mixpanel = useMixpanel();

    const [sequence, setSequence] = useState('');
    const [isSule, setIsSule] = useState(false);

    useEffect(() => {
        const handleKeyDown = (event) => {
            const keyPressed = event.key.toLowerCase();

            if ((sequence + keyPressed).includes('şuşu')) {
                setIsSule(!isSule);
                setSequence('');
            } else {
                setSequence((prevSequence) => prevSequence + keyPressed);
            }
        };

        window.addEventListener('keydown', handleKeyDown);

        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, [sequence]);

    const columns = [
        {
            name: '',
            sortable: false,
            width: "110px",
            cell: row => <QReviewTableThumbnailCell row={row} />
        },
        {
            name: 'Model Name',
            sortable: true,
            width: isSule ? null : "220px",
            cell: row => <QReviewTableCell id={row._id} children={<ModelNameDisplayer modelName={row.model_name}/>}/> ,
            selector: row => row.model_name
        },
        {
            name: 'Brand',
            sortable: true,
            cell: row => <QReviewTableCell id={row._id} children={row.brand[0] ? row.brand[0].brand_name : "-"}></QReviewTableCell>,
            selector: row => row.brand[0].brand_name
        },
        {
            name: 'Category',
            sortable: true,
            cell: row => <QReviewTableCell id={row._id} children={row.category ? row.category : "-"}></QReviewTableCell>,
            selector: row => row.category,
        },
        {
            name: 'QA Status',
            sortable: true,
            cell: row => <QReviewTableCell id={row._id} children={<QAStatusBadge status={row.qa_status} />}></QReviewTableCell>,
            selector: row => row.qa_status
            //sortFunction: statusSortFunction
        },
        {
            name: 'Create Time',
            sortable: true,
            cell: row => <QReviewTableCell id={row._id} children={<DateDisplayer date={row.created_at} />}></QReviewTableCell>,
            selector: row => row.created_at,
            //sortFunction: dateSortFunction
        },
        {
            name: 'Actions',
            sortable: false,
            cell: row => <QReviewModelTableActionButtons model={row} />,
        }
    ];


    //Add column for account type 
    if (checkAuth(authList, authKeys.edit_model)) {
        columns.splice(4, 0, {
            name: <><Typography variant="qrealBodyMedium">File Size</Typography> <Switch checked={showFileSize} onChange={() => setShowFileSize(!showFileSize)} /></> as any,
            sortable: false,
            cell: row => <QReviewTableCell id={row._id} children={<QReviewTableFileSizeCell showFileSize={showFileSize} glbSize={GLBsizeList[row._id] || "-"} usdzSize={USDZsizeList[row._id] || "-"} />} />,
            //selector: row => GLBsizeList[row._id]
        })
    } else {
        columns.splice(2, 0, {
            name: 'External ID',
            sortable: false,
            selector: row => row.external_id ? row.external_id : "-",
            cell: row => row.external_id ? row.external_id : "-"
        })
    }


    const fetchModels = async (page, per_page) => {
        setLoading(true);
        let response;
        if (searchParams.get('filtered')) {
            searchParams.set('page', page);
            searchParams.set('per_page', per_page);
            if (!searchParams.get('search_query')) {
                searchParams.set('search_query', '');
            }

            response = await getAxiosWithToken({
                url: `/models/search?${searchParams.toString()}`
            })
        } else {
            response = await getAxiosWithToken({
                url: `/models/search?search_query=${searchQuery}&page=${page}&per_page=${per_page}`
            })
        }
        setData(response.data.data);
        setTotalRows(response.data.total);
        setLoading(false);
    };

    const handlePageChange = (newPage) => {
        setPage(newPage)
        fetchModels(newPage, perPage);
    };

    const handlePerRowsChange = async (newPerPage, newPage?) => {
        setLoading(true);
        setPerPage(newPerPage);
        fetchModels(newPage || page, newPerPage);
    };

    const handleSearch = async (query: string) => {
        searchParams.delete('filtered');
        setSearchQuery(query.toLowerCase().replaceAll(' ', '-'));
    }

    useEffect(() => {
        fetchModels(page, perPage); // fetch page 1 of users
    }, [page, perPage]);

    useEffect(() => {
        if (searchParams.get('filtered')) {
            return;
        }
        setSearchParams(`?page=${page}&per_page=` + perPage + (searchQuery ? '&search_query=' + searchQuery : ''));
    }, [page, perPage, searchQuery]);

    useEffect(() => {
        if (showFileSize) {
            data.forEach((model: Model) => {
                getFileSize(cdnToS3URLChanger(model.glb_file[0])).then((size) => {
                    setGLBSizeList((prevGLBSizeList) => {
                        return {
                            ...prevGLBSizeList,
                            ...{ [model._id]: formatBytes(size) }
                        }
                    })
                })
                getFileSize(cdnToS3URLChanger(model.usdz_file[0])).then((size) => {
                    setUSDZSizeList((prevUSDZSizeList) => {
                        return {
                            ...prevUSDZSizeList,
                            ...{ [model._id]: formatBytes(size) }
                        }
                    })
                })
            })
        }
    }, [showFileSize, data])


    useEffect(() => {
        setLoading(true);
        fetchModels(page, perPage);
    }, [searchQuery]);

    const handleFileSizeSwitch = () => {
        setShowFileSize(!showFileSize)
    }

    return (
        <>
            {isDeleteModelConfirmationModalOpen && (
                <DeleteModelConfirmationModal
                    isOpen={isDeleteModelConfirmationModalOpen}
                    closeModal={() => {
                        setIsModelDeletedSuccessfully(false)
                        setIsDeleteModelConfirmationModalOpen(false)
                    }}
                    selectedModelId={selectedModelId}
                />
            )}

            <Container maxWidth="xl">
                <Grid container mt={4} mb={2}>
                    <Grid item container xs={12} md={5} alignItems={"center"}>
                        <Typography variant="qrealSubtitle">MODEL LIST</Typography>
                    </Grid>
                    <Grid item xs={12} md={7} container justifyContent={"flex-end"}>
                        <Stack direction={"row"} spacing={1.5}>
                            <SearchInput
                                onSubmit={handleSearch}
                                defaultValue={searchQuery}
                            />
                            <ExportModels />

                            <FilterModels setData={setData} setPage={setPage} setTotalRows={setTotalRows} setPerPage={setPerPage} />
                            {
                                checkAuth(authList, authKeys.create_model) &&
                                <QReviewIconButton svg={AddItemIcon} alt="add item" onClick={() => { navigate("/model/create") }} />
                            }
                        </Stack>
                    </Grid>
                </Grid>
                <QReviewDefaultTable
                    columns={isSule ? [columns[0], columns[1]] : columns}
                    data={data}
                    pagination={true}
                    progressPending={loading}
                    isDataFromModel={true}
                    totalRows={totalRows}
                    defaultPerPage={Number(perPage)}
                    defaultPage={Number(page)}
                    rowsPerPageOptions={rowsPerPageOptions}
                    handlePerRowsChange={handlePerRowsChange}
                    handlePageChange={handlePageChange}
                    onRowClicked={(row) => { window.location.href = `/${row._id}` }}
                />
            </Container>
        </>
    )
}

export default Home;
