import axios from "axios";
import { ErrorResponse } from "./formatString";

const AUTH_BASE_URL = process.env.REACT_APP_AUTH_API;
const BASE_URL = process.env.REACT_APP_API;

const errorCodes = {
    ERR_NETWORK: 'ERR_NETWORK',
}

axios.interceptors.response.use(
    (response) => {
        const expireDate = new Date(localStorage.getItem('expireToken') || '');
        // Check is token expire in 3 days
        if ((expireDate.getTime() - new Date().getTime() < 3 * 24 * 60 * 60 * 1000) && !response.data.data.authorization_token) {
            postAxios({
                url: '/auth/refresh-token',
                body: {
                },
                config: {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('authToken')}`,
                    }
                }
            }).then((res: any) => {
                if (res.data && res.data.authorization_token) {
                    localStorage.setItem('authToken', res.data.authorization_token);
                    localStorage.setItem('expireToken', res.data.expire_date);
                }
            });
        }
        return response;
    }
);

const errorResponse = (error: any): { error: boolean, errorMessages: ErrorResponse[], data: null } => {
    return {
        error: true,
        errorMessages: error?.response?.data?.errors || "Something went wrong",
        data: null,
    };
};

export const postAxios = async (args: { url: string; body: any; config?: any; }) => {
    try {
        const response = await axios.post(AUTH_BASE_URL + args.url, args.body, args.config).then((res) => res.data);
        if (response.data) {
            return {
                error: false,
                data: response.data,
                errorMessages: null,
            };
        }
        return {
            error: true,
            errorMessages: 'Something went wrong',
            data: null,
        };
    } catch (error: any) {
        return errorResponse(error)
    }
}

export const getAxios = async (args: { url: string; config?: any; }) => {
    try {
        const response = await axios.get(AUTH_BASE_URL + args.url, args.config).then((res) => res.data);
        if (response.data) {
            return {
                error: false,
                data: response.data,
                errorMessages: null,
            };
        }
        return {
            error: true,
            errorMessages: 'Something went wrong',
            data: null,
        };
    } catch (error: any) {
        return errorResponse(error)
    }
}

export const getServiceToken = async (serviceName = '') => {
    try {
        const response = await axios.post(AUTH_BASE_URL + '/auth/switch-token', {
            service: serviceName,
        }, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('authToken')}`,
            }
        }).then((res) => res.data);
        if (response.data && response.is_complete) {
            return response.data
        }
        return null
    } catch (error: any) {
        return errorResponse(error)
    }
}

export const getAxiosWithToken = async (args: { url: string; config?: any; authRequest?: boolean, optionalToken?: string }) => {
    try {
        return await axios.get((args.authRequest ? AUTH_BASE_URL : BASE_URL) + args.url, {
            headers: {
                Authorization: `Bearer ${args.optionalToken || localStorage.getItem('authToken')}`,
            },
            ...args.config,
        })
    } catch (error: any) {
        return errorResponse(error)
    }
}

export const postAxiosWithToken: {
    (args: { url: string; body: any; config?: any; authRequest?: boolean; optionalToken?: string }): Promise<{ error: boolean; errorMessages: ErrorResponse[]; data: any; }>;
} = async (args: { url: string; body: any; config?: any; optionalToken?: string }) => {
    try {
        return await axios.post(BASE_URL + args.url, args.body, {
            headers: {
                Authorization: `Bearer ${args.optionalToken || localStorage.getItem('authToken')}`,
            },
            ...args.config,
        })
    } catch (error: any) {
        return errorResponse(error)
    }
}

export const deleteAxiosWithToken = async (args: { url: string; config?: any; optionalToken?: string }) => {
    try {
        return await axios.delete(BASE_URL + args.url, {
            headers: {
                Authorization: `Bearer ${args.optionalToken || localStorage.getItem('authToken')}`,
            },
            ...args.config,
        })
    } catch (error: any) {
        return errorResponse(error)
    }
}

export const putAxiosWithToken = async (args: { url: string; body: any; config?: any; optionalToken?: string; }) => {
    try {
        return await axios.put(BASE_URL + args.url, args.body, {
            headers: {
                Authorization: `Bearer ${args.optionalToken || localStorage.getItem('authToken')}`,
            },
            ...args.config,
        })
    } catch (error: any) {
        return errorResponse(error)
    }
}

export const getFileSize = async (url: string) => {
    return await axios.get(url, {
        headers: {
            'Access-Control-Allow-Origin': '*',
        },
        method: 'HEAD',
    }).then((res) => {
        return res.headers['content-length'];
    }).catch((err) => {
        return err.code == errorCodes.ERR_NETWORK ? 1 : 0;
    })
}

export function checkUSDZExistence(url) {
    return new Promise((resolve, reject) => {
        const requestTimeout = setTimeout(() => {
            reject(new Error('Request timed out'));
        }, 2000);

        axios.get(url, {
            headers: {
                'Access-Control-Allow-Origin': '*',
            },
            method: 'HEAD',
        })
            .then(response => {
                clearTimeout(requestTimeout);
                resolve(response.data);
            })
            .catch(error => {
                clearTimeout(requestTimeout);
                reject(error);
            });
    });
}

export const postAxiosWithTokenAndBaseUrl = async (args: { baseUrl:string, path: string; body: any; config?: any; optionalToken?: string }) => {
    try {
        return await axios.post(args.baseUrl + args.path, args.body, {
            headers: {
                Authorization: `Bearer ${args.optionalToken || localStorage.getItem('authToken')}`,
            },
            ...args.config,
        })
    } catch (error: any) {
        return errorResponse(error)
    }
}

export const putAxiosWithTokenAndBaseUrl = async (args: { baseUrl:string, path: string; body: any; config?: any; optionalToken?: string }) => {
    try {
        return await axios.put(args.baseUrl + args.path, args.body, {
            headers: {
                Authorization: `Bearer ${args.optionalToken || localStorage.getItem('authToken')}`,
            },
            ...args.config,
        })
    } catch (error: any) {
        return errorResponse(error)
    }
}

export const getAxiosWithTokenAndBaseUrl = async (args: { baseUrl:string, path: string; config?: any; optionalToken?: string }) => {
    try {
        return await axios.get(args.baseUrl + args.path, {
            headers: {
                Authorization: `Bearer ${args.optionalToken || localStorage.getItem('authToken')}`,
            },
            ...args.config,
        })
    } catch (error: any) {
        return errorResponse(error)
    }
}
